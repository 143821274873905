import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { CXRResult } from './types';

export function useGetCXRResultHistories(
  options?: UseQueryOptions<PaginationResponseType<CXRResult>, Error> & {
    id: CXRResult['uuid'];
  }
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetCXRResultHistories,
  } = useQuery<PaginationResponseType<CXRResult>, Error>(
    [API_QUERIES.CXR_RESULT_HISTORY, { ...params, id: options.id }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        return responseWrapper<PaginationResponseType<CXRResult>>(() =>
          apiClient.getCXRResultHistories(options.id, params)
        );
      },
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateCXRResultHistories = () =>
    queryClient.invalidateQueries([API_QUERIES.CXR_RESULT_HISTORY, { id: options.id }]);

  const { data: cxrResultHistories, totalRecords } = data || {};

  return {
    totalRecords,
    cxrResultHistories,
    error,
    isError,
    isLoading,
    setParams,
    onGetCXRResultHistories,
    handleInvalidateCXRResultHistories,
  };
}
