export enum MonthlyLaboratoryResultKey {
  ID = 'uuid',
  NameOfPatient = 'patientName',
  PatientChartCode = 'patientChartCode',
  AttendingNephrologist = 'attendingNephrologist',
  HemodialysisAccess = 'hemodialysisAccess',
  Diagnosis = 'diagnosis',
  Test = 'testsList',
  TestName = 'testName',
  TestResult = 'testResult',
  DocumentUrls = 'documentUrls',
  UpdatedBy = 'updatedBy',
  DateTime = 'createdAt',
  UpdatedAt = 'updatedAt',
  MonthAndYear = 'monthYear',

  HbsAg = 'hbsAg',
  AntiHbs = 'antiHbs',
  AntiHcv = 'antiHcv',

  Iron = 'iron',
  Ferritin = 'ferritin',
  Tibc = 'tibc',
  Tsat = 'tsat',
}

export enum CXRResultKey {
  ID = 'uuid',
  NameOfPatient = 'patientName',
  PatientChartCode = 'patientChartCode',
  AttendingNephrologist = 'attendingNephrologist',
  HemodialysisAccess = 'hemodialysisAccess',
  Diagnosis = 'diagnosis',
  CoMorbidities = 'coMorbidities',
  XRayReport = 'xRayReport',

  MonthAndYear = 'monthYear',
  DocumentUrls = 'documentUrls',
  UpdatedBy = 'updatedBy',
  DateTime = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export enum HepatitisTestTableKeyValue {
  HBS_AG = 'hbsAg',
  ANTI_HBS = 'antiHbs',
  ANTI_HCV = 'antiHcv',
}

export enum IronStudiesTableKeyValue {
  IRON = 'iron',
  FERRITIN = 'ferritin',
  TIBC = 'tibc',
  TSAT = 'tsat',
}

export enum XRayReportTableKeyValue {
  XRayReportName = 'xRayReportName',
  XRayReportResult = 'xRayReportResult',
}
