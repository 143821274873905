import { PATIENT_CHART_FORM_NAME } from 'src/containers/shared';

export const getRootFormTab = (formCode: PATIENT_CHART_FORM_NAME) => {
  switch (formCode) {
    case PATIENT_CHART_FORM_NAME.GENERAL_INFORMATION:
    case PATIENT_CHART_FORM_NAME.MEDICAL_HISTORY_AND_PHYSICAL_EXAMINATION:
      return 'Patient Data Form';
    case PATIENT_CHART_FORM_NAME.HEMODIALYSIS_TREATMENT:
    case PATIENT_CHART_FORM_NAME.RE_USE_OF_DIALYZERS:
    case PATIENT_CHART_FORM_NAME.ADVANCE_DIRECTIVES:
      return 'Informed  Consent';
    case PATIENT_CHART_FORM_NAME.STANDING_ORDER:
    case PATIENT_CHART_FORM_NAME.PHYSICIAN_ORDER:
    case PATIENT_CHART_FORM_NAME.CLINICAL_ABSTRACT:
      return 'Dialysis Prescription';
    case PATIENT_CHART_FORM_NAME.TREATMENT_SHEET:
    case PATIENT_CHART_FORM_NAME.CONTINUOUS_FLOW_SHEET:
      return 'Hemodialysis Monitoring';
    case PATIENT_CHART_FORM_NAME.MEDICATION_RECORD:
      return 'Medication Record';
    case PATIENT_CHART_FORM_NAME.MONTHLY_LABORATORY_RESULT:
    case PATIENT_CHART_FORM_NAME.CXR_RESULT:
    case PATIENT_CHART_FORM_NAME.HEPATITIS_SCREENING_RESULTS:
    case PATIENT_CHART_FORM_NAME.VACCINATION_RECORD:
      return 'Laboratory Flow';
    case PATIENT_CHART_FORM_NAME.PROBLEM_LIST:
    case PATIENT_CHART_FORM_NAME.NUTRITIONAL_ASSESSMENT:
    case PATIENT_CHART_FORM_NAME.PATIENT_EDUCATION:
      return 'Progress Note';
    default:
      return '';
  }
};
