import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { ProblemList } from './types';

export function useCreateProblemList(
  options?: UseMutationOptions<{ problemListForm: ProblemList }, Error, ProblemList>
) {
  const { mutate: createProblemList, isLoading } = useMutation<
    { problemListForm: ProblemList },
    Error,
    ProblemList
  >({
    mutationFn: (payload: ProblemList) =>
      createResponseWrapper(apiClient.createProblemList, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createProblemList,
    isLoading,
  };
}
