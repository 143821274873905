export enum UserKey {
  Name = 'fullName',
  Alias = 'alias',
  Username = 'username',
  Email = 'email',
  LastLoginDate = 'lastLogin',
  Status = 'status',
  Profile = 'profile',
  Role = 'role',
  FirstName = 'firstName',
  LastName = 'lastName',
}

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface User {
  name: string;
  alias: string;
  username: string;
  email: string;
  lastLogin: string;
  status: UserStatus;
  profile: string;
  role: string;
  id: string;
}

export type DelegateAccess = {
  delegatedUserId: any;
  roleName: string;
  startDate: string;
  endDate: string;
  isAllProjects: boolean;
  projectNumber: string;
  userId?: string;
  roleId?: string;
  delegatedUser?: {
    id: string;
    createdAt: string;
    updatedAt: string;
    firstName: string;
    lastName: string;
    middleName: string;
    fullName: string;
    username: string;
    email: string;
    lastLoginDate: string;
    isDhUser: boolean;
    allowMaintenanceModeLogin: boolean;
    passwordSetDate: string;
    comments: string;
    defaultUserType: string;
    status: UserStatus;
  };
};

export type AddUserPayload = {
  username: UserDetail['username'];
  firstName: UserDetail['firstName'];
  lastName: UserDetail['lastName'];
  middleName: UserDetail['middleName'];
  email: UserDetail['email'];
  comments: UserDetail['comments'];
  defaultUserType: UserDetail['defaultUserType'];
  roles: string[];
  delegateAccess: DelegateAccess[];
};

export type UserRole = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  displayName: string;
  description: string;
  canBeUpdated: boolean;
  canBeDeleted: boolean;
  domain: string;
};

export type UserDetail = {
  id: User['id'];
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: User['name'];
  username: User['username'];
  email: User['email'];
  isDhUser: boolean;
  allowMaintenanceModeLogin: boolean;
  accountDisabled: boolean;
  lastLoginDate: User['lastLogin'];
  passwordSetDate: string;
  comments: string;
  defaultUserType: string;
  status: UserStatus;
  roles: {
    userId: User['id'];
    roleId: UserRole['id'];
    createdAt: string;
    updatedAt: string;
    role: UserRole;
  }[];
  delegateAccesses: DelegateAccess[];
  fisFaInfo: any; //TODO: tin_pham update type
  fisPiInfo: any; //TODO: tin_pham update type
};

export interface SearchUser {
  username: UserDetail['username'];
  email: UserDetail['email'];
  fullName: UserDetail['fullName'];
  id: UserDetail['id'];
  roles: UserRole[];
  defaultUserType: UserDetail['defaultUserType'];
}

export type SearchProject = {
  id: string;
  createdAt: string;
  updatedAt: string;
  referenceId: number;
  referenceTable: string;
  projectNumber: string;
};
