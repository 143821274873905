import { v4 as uuid } from 'uuid';
import { TableAddRowInterface } from './types';

export const toNewData = (data: TableAddRowInterface[]) => {
  return data.map((item) => ({
    id: uuid(),
    name: item.name,
    address: item.address,
  }));
};

export const toSubmitData = (data: TableAddRowInterface[]) => {
  return data.map((item) => ({ name: item.name, address: item.address }));
};
