import { MedicationRecordKey } from './keys';
import { MedicationRecord } from './types';

export const toMedicationRecord = (data): MedicationRecord => {
  const medicationList = [];

  data.medicationList.forEach((item) => {
    let medication = JSON.parse(item);
    medicationList.push(medication);
  });

  return {
    ...data,
    [MedicationRecordKey.MedicationList]: medicationList || [],
    [MedicationRecordKey.DocumentUrls]: data?.[MedicationRecordKey.DocumentUrls]?.map((item) =>
      JSON.parse(item)
    ),
  };
};
