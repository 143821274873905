import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { MonthlyLaboratoryResult } from './types';

export function useGetMonthlyLaboratoryResultHistories(
  options?: UseQueryOptions<PaginationResponseType<MonthlyLaboratoryResult>, Error> & {
    id: MonthlyLaboratoryResult['uuid'];
  }
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetMonthlyLaboratoryResultHistories,
  } = useQuery<PaginationResponseType<MonthlyLaboratoryResult>, Error>(
    [API_QUERIES.MONTHLY_LABORATORY_RESULT_HISTORY, { ...params, id: options.id }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        return responseWrapper<PaginationResponseType<MonthlyLaboratoryResult>>(() =>
          apiClient.getMonthlyLaboratoryResultHistories(options.id, params)
        );
      },
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateMonthlyLaboratoryResultHistories = () =>
    queryClient.invalidateQueries([
      API_QUERIES.MONTHLY_LABORATORY_RESULT_HISTORY,
      { id: options.id },
    ]);

  const { data: monthlyLaboratoryResultHistories, totalRecords } = data || {};

  return {
    monthlyLaboratoryResultHistories,
    totalRecords,
    error,
    isError,
    isLoading,
    setParams,
    onGetMonthlyLaboratoryResultHistories,
    handleInvalidateMonthlyLaboratoryResultHistories,
  };
}
