export enum TreatmentRecordPatients {
  CXR_RESULT_FORM = 'cxrResultForm',
  PROBLEM_LIST_FORM = 'problemListForm',
  STANDING_ORDER_FORM = 'standingOrderForm',
  VACCINATION_RECORD = 'vaccinationRecord',
  PATIENT_GENERAL_INFO = 'patientGeneralInfo',
  PHYSICIAN_ORDER_FORM = 'physicianOrderForm',
  TREATMENT_SHEET_FORM = 'treatmentSheetForm',
  ADVANCE_DIRECTIVE_FORM = 'advanceDirectiveForm',
  CLINICAL_ABSTRACT_FORM = 'clinicalAbstractForm',
  MEDICATION_RECORD_FORM = 'medicationRecordForm',
  PATIENT_EDUCATION_FORM = 'patientEducationForm',
  RE_USE_OF_DIALYZERS_FORM = 'reUseOfDialyzersForm',
  HEMODIALYSIS_TREATMENT = 'hemodialysisTreatment',
  CONTINUOUS_FLOW_SHEET_FORM = 'continuousFlowSheetForm',
  NUTRITIONAL_ASSESSMENT_FORM = 'nutritionalAssessmentForm',
  MONTHLY_LABORATORY_RESULT_FORM = 'monthlyLaboratoryResultForm',
  HEPATITIS_SCREENING_RESULT_FORM = 'hepatitisScreeningResultForm',
  MEDICAL_HISTORY_AND_PHYSICAL_EXAMINATION = 'medicalHistoryAndPhysicalExamination',
}

export enum AddTreatmentPatientsKey {
  ADDITIONAL_PATIENT = 'additionalPatient',
}
