import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../../apiClient';
import { createResponseWrapper } from '../../helpers';
import { ClinicalAbstract } from './types';

export function useCreateClinicalAbstract(
  options?: UseMutationOptions<{ clinicalAbstractForm: ClinicalAbstract }, Error, ClinicalAbstract>
) {
  const { mutate: createClinicalAbstract, isLoading } = useMutation<
    { clinicalAbstractForm: ClinicalAbstract },
    Error,
    ClinicalAbstract
  >({
    mutationFn: (payload: any) =>
      createResponseWrapper(apiClient.createClinicalAbstract, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createClinicalAbstract,
    isLoading,
  };
}
