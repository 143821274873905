import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { HemodialysisTreatment } from './types';

export function useCreateHemodialysisTreatment(
  options?: UseMutationOptions<
    { informedHemodialysisTreatment: HemodialysisTreatment },
    Error,
    HemodialysisTreatment
  >
) {
  const { mutate: createHemodialysisTreatment, isLoading } = useMutation<
    { informedHemodialysisTreatment: HemodialysisTreatment },
    Error,
    HemodialysisTreatment
  >({
    mutationFn: (payload: HemodialysisTreatment) =>
      createResponseWrapper(apiClient.createHemodialysisTreatment, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createHemodialysisTreatment,
    isLoading,
  };
}
