export enum NurseKey {
  Id = 'uuid',
  OriginalNum = 'originalNum',
  Code = 'nurseCode',
  Prefix = 'prefix',
  FirstName = 'firstName',
  LastName = 'lastName',
  MiddleName = 'middleName',
  BirthDate = 'birthDate',
  Sex = 'sex',
  EmergencyContactPhone = 'emergencyContactPhone',
  Address = 'address',
  Email = 'email',
  Phone = 'phone',
  Status = 'status',
  Suffix = 'suffix',
  PrcID = 'prcId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  createdBy = 'createdBy',
}

export enum NurseItemKeyValue {
  UUID = 'uuid',
  ORIGINAL_NUM = 'originalNum',
  NURSE_CODE = 'nurseCode',
  NAME = 'name',
  SUFFIX = 'suffix',
  PHONE = 'phone',
  BIRTH_DATE = 'birthDate',
  ADDRESS = 'address',
  EMAIL = 'email',
  EMERGENCY_CONTACT_PHONE = 'emergencyContactPhone',
  SEX = 'sex',
  STATUS = 'status',
  PRC_ID = 'prcId',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
}
