export enum ReUseDialyzersFormKey {
  Id = 'id',
  UUID = 'uuid',
  PatientChartCode = 'patientChartCode',
  PatientChartId = 'patientChartId',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  SignatureOverPrintedNamePatient = 'signatureAuthorForm',
  PatientSignatureDate = 'signatureAuthorDate',
  Witness = 'witness',
  WitnessSignatureDate = 'witnessDate',
  UploadDocument = 'documentUrls',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
}
