import { Popover } from '@mui/material';
import './styles.scss';

const clsPrefix = `ActionsButtonPopover`;

const ActionsButtonPopover = ({
  container,
  onClose,
  children,
  elevation = 3,
  transformOrigin = null,
  anchorOrigin = null,
  className = '',
  overflowVisible = false,
}) => (
  <Popover
    open={!!container}
    anchorEl={container}
    onClose={onClose}
    elevation={elevation}
    className={className}
    classes={{
      root: `${clsPrefix}__root`,
      paper: `${clsPrefix}__paper ${overflowVisible ? 'cmp-dialog-content-visible' : ''}`,
    }}
    anchorOrigin={
      anchorOrigin
        ? anchorOrigin
        : {
            vertical: 'bottom',
            horizontal: 'left',
          }
    }
    transformOrigin={
      transformOrigin
        ? transformOrigin
        : {
            vertical: 'top',
            horizontal: 'left',
          }
    }
  >
    {children}
  </Popover>
);

export default ActionsButtonPopover;
