export const PATHS = {
  root: '/',
  dev: '/dev',
  welcome: '/welcome',
  signIn: '/login',
  myAccount: '/my-accounts',
  dashboard: '/dashboard',
  logout: '/logout',
  forgotPassword: '/forgot-password',

  // ======== Profile ========
  myProfile: '/me',
  changePassword: '/me/change-password',
  editMyProfile: '/me/edit',
  configureNotification: '/me/notification',

  // ======== User Management ========
  userManagements: '/user-managements',
  addUser: '/miscellaneous/user-managements/add',
  userDetail: '/miscellaneous/user-managements/user-detail',

  // ======== Patient Schedule =======
  patientSchedule: '/patient-schedule',
  patientScheduleDetail: '/patient-schedule-detail',

  // ======== Inventory =======
  inventory: '/inventory',

  // ======= Patient Chart ======
  patientChart: '/patient-chart',
  patientChartDetails: '/patient-chart/:id',
  patientChartForm: '/patient-chart/:id/:formCode',
  patientChartDetailForm: '/patient-chart/:id/:formCode/:uuid',
  patientChartFormHistoryDetails: '/patient-chart/:id/:formCode/:uuid/history/:historyId',

  // ======== Patient =========
  patient: '/patients',
  patientDetails: '/patients/:id',
  // Treatment History
  treatmentHistoryDetail: '/patients/:patientId/treatment-history/:treatmentId',
  treatmentHistoryPatientChart: '/patients/treatment-history/:id/:formCode',
  treatmentHistoryPatientChartDetail: '/patients/treatment-history/:id/:formCode/:uuid',
  treatmentHistoryPatientChartHistoryDetail:
    '/patients/treatment-history/:id/:formCode/:uuid/history/:historyId',

  //======== Nurse =========
  nurses: '/nurses',
  addNewNurse: '/nurses/add',
  editNurse: '/nurses/nurse-detail/:id',

  // ======== Appointments =========
  appointments: '/appointments',
  createAppointment: '/appointments/create',
  appointmentsDetail: '/appointments/:id/:code',

  // ======= Attending Nephrologist ======
  attendingNephrologist: '/attending-nephrologist',
  attendingNephrologistAddNew: '/attending-nephrologist/add-new-nephrologist',
  attendingNephrologistEdit: '/attending-nephrologist/:id',

  // ======== Refund ===========
  refunds: '/refunds',
  refundTabDetail: '/refunds/:refundId/:refundCode',
  refundItemDetail: '/refunds/:refundId/:refundCode/:formName',

  // ======== Templates ===========
  templates: '/templates',
  // ============= Final Manual ======
  finalManual: '/final-manual',
};

export const PATH_HEADERS = {
  [PATHS.myProfile]: 'My Profile',
  [PATHS.editMyProfile]: 'My Profile',
  [PATHS.changePassword]: 'Change Password', // pragma: allowlist secret
};

export const HIDE_NAV_PATHS = [];
