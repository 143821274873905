import { UseMutationOptions, useMutation } from 'react-query';
import { handleShowErrorMsg } from 'src/utils/handlerUtils';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { NursePayload } from './types';

export function useCreateNurse(options?: UseMutationOptions<any, Error, NursePayload>) {
  const {
    mutate: onCreateNurse,
    isLoading: isSubmitting,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, NursePayload>({
    mutationFn: (payload: NursePayload) => responseWrapper(apiClient.createNurse, [payload]),
    onError: (error) => handleShowErrorMsg(error),
    ...options,
  });

  return {
    onCreateNurse,
    isSubmitting,
    isSuccess,
    isError,
    error,
  };
}
