import cn from 'classnames';
import React, { useRef } from 'react';
import shortid from 'shortid';
import { BOOLEAN } from 'src/appConfig/constants';
import { View } from '..';
import Element from '../Element';
import './styles.scss';

const RadioButton: React.FC<RadioProps> = ({
  label,
  labelClassName,
  containerClassName,
  style,
  subLabel,
  ...props
}) => {
  const id = useRef(shortid.generate());
  return (
    <View isRow className={cn('cmp-radio', containerClassName)} style={style}>
      <input id={id.current} className={cn('cmp-radio__input')} type="radio" {...props} />
      <label htmlFor={id.current} className={cn('cmp-radio__label', labelClassName)}>
        {label}
      </label>
      {subLabel && subLabel}
    </View>
  );
};

type RadioProps = React.HTMLProps<HTMLInputElement> & {
  label?: string;
  subLabel?: React.ReactNode;
  labelClassName?: string;
  containerClassName?: string;
};

const castTrueFalseStringToBoolean = (value: string) => {
  return value === BOOLEAN.true ? true : value === BOOLEAN.false ? false : null;
};

const Group: React.FC<RadioGroupProps> = ({
  options,
  value,
  containerClassName,
  onChange = () => {},
  label,
  subLabel,
  errorMessage,
  name,
  onBlur,
  columns = 3,
  required,
  isTrueFalseOptions = false,
  disabled = false,
}) => {
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    onChange(name, isTrueFalseOptions ? castTrueFalseStringToBoolean(value) : value);
  };

  const handleRadioBlur = () => {
    onBlur && onBlur(name, true);
  };

  return (
    <Element
      errorMessage={errorMessage}
      label={label}
      className={containerClassName}
      required={required}
      subLabel={subLabel}
    >
      <View isRow align="center" className="py-8">
        {options?.map((option, index) => {
          return (
            <RadioButton
              disabled={disabled}
              key={`radio-${name}-${index}`}
              name={name}
              value={option.value}
              checked={value === option.value}
              label={option.label}
              onChange={handleValueChange}
              containerClassName={cn(columns && 'cmp-radio-groups__column')}
              style={{ width: `${100 / columns}%` }}
              onBlur={handleRadioBlur}
              subLabel={option.subLabel}
            />
          );
        })}
      </View>
    </Element>
  );
};

export type RadioGroupOptions = { value: any; label: string; subLabel?: React.ReactNode }[];

type RadioGroupProps = {
  label?: string;
  subLabel?: string | React.ReactNode;
  options?: RadioGroupOptions;
  value?: any;
  name?: string;
  onChange?: (name: string, value: any) => void;
  errorMessage?: string;
  containerClassName?: string;
  labelClassName?: string;
  description?: string;
  columns?: number;
  disabled?: boolean;
  onBlur?: (name: string, touched: boolean) => void;
  required?: boolean;
  isTrueFalseOptions?: boolean;
};

export default Group;
