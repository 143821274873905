import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toCXRResult } from './helpers';
import { CXRResult } from './types';

export function useGetCXRResult(
  options?: UseQueryOptions<ApiResponseType<{ cxrResult: CXRResult }>, Error, CXRResult> & {
    id: CXRResult['uuid'];
  }
) {
  const {
    data: cxrResult,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetCXRResult,
  } = useQuery<ApiResponseType<{ cxrResult: CXRResult }>, Error, CXRResult>(
    [API_QUERIES.CXR_RESULT, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ cxrResult: CXRResult }>>(
          apiClient.getCXRResult,
          params
        );
      },
      select: ({ data }) => toCXRResult(data?.cxrResult),
      enabled: !!options.id,
      ...options,
    }
  );

  return {
    cxrResult,
    error,
    isError,
    isLoading,
    onGetCXRResult,
  };
}
