import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { CXRResult } from './types';

export function useEditCXRResult(options?: UseMutationOptions<CXRResult, Error, CXRResult>) {
  const {
    mutate: editCXRResult,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<CXRResult, Error, CXRResult>({
    mutationFn: (payload: CXRResult) => responseWrapper(apiClient.editCXRResult, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editCXRResult,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
