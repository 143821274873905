import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { HemodialysisTreatment } from './types';

export function useEditHemodialysisTreatment(
  options?: UseMutationOptions<HemodialysisTreatment, Error, HemodialysisTreatment>
) {
  const {
    mutate: editHemodialysisTreatment,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<HemodialysisTreatment, Error, HemodialysisTreatment>({
    mutationFn: (payload: HemodialysisTreatment) =>
      responseWrapper(apiClient.editHemodialysisTreatment, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editHemodialysisTreatment,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
