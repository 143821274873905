import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../../apiClient';
import { createResponseWrapper } from '../../helpers';
import { GeneralInformationFormTypeResponse, PatientGeneralInformationFormValues } from './types';

export function useCreateGeneralInformationForm(
  options?: UseMutationOptions<
    GeneralInformationFormTypeResponse,
    Error,
    PatientGeneralInformationFormValues
  >
) {
  const { mutate: createGeneralInformationForm, isLoading } = useMutation<
    GeneralInformationFormTypeResponse,
    Error,
    PatientGeneralInformationFormValues
  >({
    mutationFn: (payload: PatientGeneralInformationFormValues) =>
      createResponseWrapper(apiClient.createGeneralInformationForm, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createGeneralInformationForm,
    isLoading,
  };
}
