import DecodeUrlFilePreview from './decodeUrlPreview';
import DocumentPreview from './documentReview';
import LocalFilePreview from './localFilePreview';
import RemoteDocumentPreview from './remoteDocumentReview';

export default {
  DecodePreview: DecodeUrlFilePreview,
  LocalFilePreview: LocalFilePreview,
  RemoteDocumentPreview: RemoteDocumentPreview,
  DocumentPreview: DocumentPreview,
};
