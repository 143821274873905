export enum PatientFormsKey {
  Id = 'id',
  Prefix = 'prefix',
  FirstName = 'firstName',
  LastName = 'lastName',
  MiddleName = 'middleName',
  Suffix = 'suffix',
  Phone = 'phone',
  Dob = 'birthDate',
  Sex = 'sex',
  Religion = 'religion',
  Email = 'email',
  Address = 'address',
  EmergencyContactName = 'emergencyContactName',
  EmergencyContactPhone = 'emergencyContactPhone',
  PaymentMethod = 'paymentMethod',
  ReferringPosition = 'referringPosition',
  Status = 'status',
  IsEditable = 'isEditable',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  FullText = 'fullText',
  PatientType = 'patientType',
  DocumentUrls = 'documentUrls',
  PatientCode = 'patientCode',
  PaymentOtherNote = 'paymentOtherNote',
}
