import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toStandingOrder } from './helpers';
import { StandingOrder } from './types';

export function useGetStandingOrderHistory(
  options?: UseQueryOptions<
    ApiResponseType<{ standingOrderForm: StandingOrder }>,
    Error,
    StandingOrder
  > & {
    id: StandingOrder['uuid'];
  }
) {
  const {
    data: standingOrderHistory,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetStandingOrderHistory,
  } = useQuery<ApiResponseType<{ standingOrderForm: StandingOrder }>, Error, StandingOrder>(
    [API_QUERIES.STANDING_ORDER_FORMS_HISTORY, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ standingOrderForm: StandingOrder }>>(
          apiClient.getStandingOrderHistory,
          params
        );
      },
      select: ({ data }) => toStandingOrder(data?.standingOrderForm),
      enabled: !!options.id,
      ...options,
    }
  );

  return {
    standingOrderHistory,
    error,
    isError,
    isLoading,
    onGetStandingOrderHistory,
  };
}
