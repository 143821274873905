import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { PatientEducationFormType } from './types';

export function useGetPatientEducationHistories(
  options?: UseQueryOptions<PaginationResponseType<PatientEducationFormType>, Error> & {
    id: PatientEducationFormType['uuid'];
  }
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetPatientEducationHistories,
  } = useQuery<PaginationResponseType<PatientEducationFormType>, Error>(
    [API_QUERIES.PATIENT_EDUCATION_FORM_HISTORIES, { ...params, id: options.id }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        return responseWrapper<PaginationResponseType<PatientEducationFormType>>(
          apiClient.getPatientEducationHistories,
          [params]
        );
      },
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidatePatientEducationHistories = () =>
    queryClient.invalidateQueries([API_QUERIES.PATIENT_EDUCATION_FORM_HISTORIES, { id: options.id }]);

  const { data: patientEducationHistories, hasNext, payloadSize, totalRecords } = data || {};

  return {
    patientEducationHistories,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isLoading,
    setParams,
    onGetPatientEducationHistories,
    handleInvalidatePatientEducationHistories,
  };
}
