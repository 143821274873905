/* eslint-disable security/detect-object-injection */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { LightBoxFileType } from './type';

export interface ILightboxState {
  file: LightBoxFileType;
  printDocId: string;
}

const initialState: ILightboxState = {
  file: null,
  printDocId: '',
};

export const lightboxSlice = createSlice({
  name: 'lightbox',
  initialState,
  reducers: {
    showLightBox: (state, action: PayloadAction<LightBoxFileType>) => {
      state.file = action.payload;
    },
    showLightBoxLoading: (state, action: PayloadAction<string>) => {
      state.printDocId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showLightBox, showLightBoxLoading } = lightboxSlice.actions;

export const lightboxState = lightboxSlice.getInitialState();

export default lightboxSlice.reducer;
