import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { RefundRequestFormValueRes } from './types';

export function useGetRefundRequestDetail(
  options?: UseQueryOptions<
    ApiResponseType<RefundRequestFormValueRes>,
    Error,
    RefundRequestFormValueRes
  > & {
    id: string;
  }
) {
  const {
    data: refundRequestFormDetail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetRefundRecordById,
  } = useQuery<ApiResponseType<RefundRequestFormValueRes>, Error, RefundRequestFormValueRes>(
    [API_QUERIES.REFUND_REQUEST_FORM, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<RefundRequestFormValueRes>>(
          apiClient.getRequestFormDetail,
          params
        );
      },
      select: getResponseData,
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateRefundRequestFormDetail = () =>
    queryClient.invalidateQueries([API_QUERIES.REFUND_REQUEST_FORM, { id: options.id }]);

  return {
    refundRequestFormDetail,
    error,
    isError,
    isLoading,
    onGetRefundRecordById,
    handleInvalidateRefundRequestFormDetail,
  };
}
