import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { PATHS } from 'src/appConfig/paths';
import { Link } from 'src/components/common';
import { PATIENT_CHART_FORM_NAME } from 'src/containers/shared';
import { formatDateTime } from 'src/utils';
import { VERSION_HISTORY_KEYS } from '../../../enum';

export const allColumnVersionHistory = ({
  id,
  formCode,
  uuid,
  isTreatmentHistoryForm,
}: {
  id: string;
  uuid: string;
  formCode: PATIENT_CHART_FORM_NAME;
  isTreatmentHistoryForm: boolean;
}): MUIDataTableColumn[] => [
  {
    name: VERSION_HISTORY_KEYS.CREATED_AT,
    label: 'Date & Time',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: string) => formatDateTime(value) || '--',
    },
  },
  {
    name: VERSION_HISTORY_KEYS.UPDATED_BY,
    label: 'Updated by',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: '',
    label: '',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (
        value: string,
        meta: MUIDataTableMeta | (Omit<MUIDataTableMeta, 'tableData'> & { tableData: any[] })
      ) => {
        const rowData = meta.tableData[meta.rowIndex] as any;

        const href = `${
          isTreatmentHistoryForm
            ? PATHS.treatmentHistoryPatientChartHistoryDetail
            : PATHS.patientChartFormHistoryDetails
        }`
          .replace(':id', id)
          .replace(':formCode', formCode)
          .replace(':uuid', uuid)
          .replace(':historyId', rowData.uuid);
        return (
          <Link href={href} target="_blank">
            View Update
          </Link>
        );
      },
    },
  },
];
