export enum FileUploadType {
  Avatar = 'avatars',
  Photos = 'photos',
  Signatures = 'signatures',
}

export type UploadFilePayload = {
  url: string;
  fileData: File;
};

export type GetPresignedFilePayload = {
  fileName: string;
  contentType: string;
  type: string;
};

export type GetPresignedDownloadFilePayload = {
  filePath: string;
};

export type GetPresignedDownloadFileResponse = {
  url: string;
};

export type updateStatusPayload = {
  id: string;
  status: string;
};

export type FileUploadRes = {
  name: string;
  size: string;
  url: string;
};
