import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { PhysicianOrder } from './types';

export function useEditPhysicianOrder(
  options?: UseMutationOptions<PhysicianOrder, Error, PhysicianOrder>
) {
  const {
    mutate: editPhysicianOrder,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<PhysicianOrder, Error, PhysicianOrder>({
    mutationFn: (payload: PhysicianOrder) =>
      responseWrapper(apiClient.editPhysicianOrder, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editPhysicianOrder,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
