import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../../apiClient';
import { createResponseWrapper } from '../../helpers';
import { AdvanceDirectivesFormTypeResponse, AdvanceDirectivesFormValues } from './types';

export function useCreateAdvanceDirectivesForm(
  options?: UseMutationOptions<
    AdvanceDirectivesFormTypeResponse,
    Error,
    AdvanceDirectivesFormValues
  >
) {
  const {
    mutate: createAdvanceDirectivesForm,
    data,
    isLoading,
    isSuccess,
  } = useMutation<AdvanceDirectivesFormTypeResponse, Error, AdvanceDirectivesFormValues>({
    mutationFn: (payload: AdvanceDirectivesFormValues) =>
      createResponseWrapper(apiClient.createAdvanceDirectivesForm, [payload]),
    ...options,
  });

  return {
    createAdvanceDirectivesForm,
    data,
    isLoading,
    isSuccess,
  };
}
