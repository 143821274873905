export enum ProductsKeys {
  ID = 'id',
  CATEGORY_NAME = 'categoryName',
  NAME = 'name',
  CODE = 'code',
}

export enum PackageKeys {
  ID = 'id',
  NAME = 'name',
  CODE = 'code',
  IS_ACTIVARED = 'isActivated',
  TOTAL_AMOUNT = 'totalAmount',
}

export enum PackedItemKeys {
  AMOUNT = 'amount',
  CATEGORY = 'category',
  COST = 'cost',
  DESCRIPTION = 'description',
  ITEM_NAME = 'itemName',
  QUANTITY = 'quantity',
  UNIT = 'unit',
  UNIT_PRICE = 'unitPrice',
  PACKAGE_ID = 'packageId',
  PRODUCT_ID = 'productId',
  PRODUCT_NAME = 'productName',
}
