import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { MonthlyLaboratoryResult } from './types';

export function useEditMonthlyLaboratoryResult(
  options?: UseMutationOptions<MonthlyLaboratoryResult, Error, MonthlyLaboratoryResult>
) {
  const {
    mutate: editMonthlyLaboratoryResult,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<MonthlyLaboratoryResult, Error, MonthlyLaboratoryResult>({
    mutationFn: (payload: MonthlyLaboratoryResult) =>
      responseWrapper(apiClient.editMonthlyLaboratoryResult, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editMonthlyLaboratoryResult,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
