import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { IMAGES } from 'src/appConfig/images';
import { useComponentDidMount, withRouter } from 'src/hooks';
import { IRootState } from 'src/redux/rootReducer';
import { Image } from '../common';

import { Divider, Stack, Typography } from '@mui/material';
import { upperCase } from 'lodash';
import { FiChevronLeft } from 'react-icons/fi';
import { Menu, MenuItem, Sidebar, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import appConfig from 'src/appConfig';
import { COLOR_CODE } from 'src/appConfig/constants';
import { SidebarMenu } from 'src/appConfig/sidebar';
import { AuthCurrentUserRole } from 'src/queries';
import { useGetApiVersion } from 'src/queries/Contents/useGetApiVersion';
import { hasPagePermission } from 'src/validations/permissions';
import './styles.scss';

const SidebarMenus: React.FC<Props> = ({ location, userProfile }) => {
  const { collapseSidebar, collapsed, toggleSidebar, broken, toggled } = useProSidebar();

  const {
    roles: [firstRole],
  } = userProfile || { roles: [] };

  const userRole = upperCase(firstRole?.role?.name);

  const filterSidebar = useMemo(
    () =>
      SidebarMenu.filter((item) => {
        const { role } = firstRole || {};
        const pageRoles = item?.roles || [];
        return hasPagePermission([role?.name], pageRoles);
      }),
    [firstRole]
  );

  useEffect(() => {
    if (broken) {
      if (collapsed) {
        toggleSidebar(false);
        collapseSidebar(false);
      }
    } else {
      if (toggled) {
        collapseSidebar(false);
      } else {
        toggleSidebar(true);
        collapseSidebar(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broken]);

  const handleToggleSidebar = () => (broken ? toggleSidebar() : collapseSidebar());

  const { apiVersion, getVersion } = useGetApiVersion();

  useComponentDidMount(() => {
    getVersion();
  });

  return (
    <Sidebar className="cmp-sidebar" backgroundColor={COLOR_CODE.PRIMARY_00}>
      <Stack
        className="cmp-sidebar__toggle-icon"
        alignItems="center"
        justifyContent="center"
        onClick={handleToggleSidebar}
        bgcolor={COLOR_CODE.PRIMARY_00}
      >
        <FiChevronLeft size={20} color={COLOR_CODE.PRIMARY} />
      </Stack>
      <Stack
        height={
          collapsed &&
          (userRole === AuthCurrentUserRole.ADMISSION ||
            AuthCurrentUserRole.CLINICAL ||
            AuthCurrentUserRole.FINANCE)
            ? 'unset'
            : '90%'
        }
      >
        {/* header menu */}
        <Stack alignItems={'center'} marginBottom={4}>
          <Image
            src={collapsed ? IMAGES.logoAppCollapsed : IMAGES.logo}
            style={{ maxHeight: 80 }}
          />
        </Stack>
        {/*body menu*/}
        <Menu
          className="cmp-sidebar__container"
          style={{
            overflow: 'auto',
            marginRight: '-16px',
            paddingRight: '16px',
          }}
          color={COLOR_CODE.PRIMARY_00}
        >
          {filterSidebar.map((item) => {
            if (!item.subMenu) {
              return (
                <MenuItem
                  active={location.pathname.includes(item.href)}
                  icon={item.icon}
                  component={<Link to={item.href} />}
                  key={item.href}
                >
                  {item.title}
                </MenuItem>
              );
            }
            return (
              <SubMenu
                label={item.subTitle}
                icon={item.icon}
                key={`sub-menu-${item.subTitle}`}
                active={collapsed && location.pathname.includes(item.href)}
                defaultOpen
              >
                {item.subMenu.map((subItem) => (
                  <MenuItem
                    active={location.pathname.includes(subItem.href)}
                    icon={null}
                    component={<Link to={subItem.href} />}
                    key={subItem.href}
                  >
                    {subItem.title}
                  </MenuItem>
                ))}
              </SubMenu>
            );
          })}
        </Menu>
      </Stack>

      {/* footer menu*/}
      {!collapsed && (
        <Stack position={'fixed'} bottom={0} ml={7} mb={5}>
          <Divider style={{ marginTop: 4 }} />
          <Stack alignItems={'center'} justifyContent={'center'} flexDirection={'row'} mt={1}>
            <Typography fontSize={14}>Powered by </Typography>
            <Image src={IMAGES.datahouseLogo} style={{ maxHeight: 40, marginLeft: 4 }} />
          </Stack>
          {appConfig.APP_ENV === 'development' && (
            <Stack alignItems={'center'} mt={1}>
              <Typography fontSize={12}>
                Version: {appConfig.APP_VERSION} - {apiVersion?.version}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
    </Sidebar>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    location: Location;
  };
const mapStateToProps = (state: IRootState) => ({
  isCollapse: state.common.collapseSidebar,
  showMiniSidebar: state.common.showMiniSidebar,
  showSidebar: state.common.showSidebar,
  userProfile: state.auth.user,
});

const mapDispatchToProps = {};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(SidebarMenus);

export default withRouter(ConnectedComponent);
