import { TreatmentSheetKey } from './keys';
import { TreatmentSheet } from './types';

export const toData = (data): TreatmentSheet => {
  return {
    ...data,
    [TreatmentSheetKey.PreAssessmentInfo]: data?.[TreatmentSheetKey.PreAssessmentInfo].map((item) =>
      JSON.parse(item)
    ),
    [TreatmentSheetKey.DocumentUrls]: data?.[TreatmentSheetKey.DocumentUrls]?.map((item) =>
      JSON.parse(item)
    ),
  };
};
