import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { ApiVersionInfo } from './types';

export function useGetApiVersion(options?: UseQueryOptions<ApiVersionInfo, Error, ApiVersionInfo>) {
  const {
    data: apiVersion,
    error,
    isError,
    isLoading,
    refetch: getVersion,
  } = useQuery<ApiVersionInfo, Error, ApiVersionInfo>([API_QUERIES.API_VERSION], {
    queryFn: () => responseWrapper<ApiVersionInfo>(apiClient.getVersion),
    enabled: false,
    select: (data) => data,
    ...options,
  });

  return {
    apiVersion,
    error,
    isError,
    isLoading,
    getVersion,
  };
}
