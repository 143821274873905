import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toPhysicianOrder } from './helpers';
import { PhysicianOrder } from './types';

export function useGetPhysicianOrder(
  options?: UseQueryOptions<
    ApiResponseType<{ physicianOrder: PhysicianOrder }>,
    Error,
    PhysicianOrder
  > & {
    id: PhysicianOrder['uuid'];
  }
) {
  const {
    data: physicianOrder,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetPhysicianOrder,
  } = useQuery<ApiResponseType<{ physicianOrder: PhysicianOrder }>, Error, PhysicianOrder>(
    [API_QUERIES.PHYSICIAN_ORDER_FORMS, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ physicianOrder: PhysicianOrder }>>(
          apiClient.getPhysicianOrder,
          params
        );
      },
      select: (data) => toPhysicianOrder(data.data.physicianOrder),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidatePhysicianOrder = () =>
    queryClient.invalidateQueries([API_QUERIES.PHYSICIAN_ORDER_FORMS, { id: options.id }]);

  return {
    physicianOrder,
    error,
    isError,
    isLoading,
    onGetPhysicianOrder,
    handleInvalidatePhysicianOrder,
  };
}
