import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { pollByGenerator } from 'src/queries/File';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, ExportValue, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { Callback } from 'src/redux/types';
import { ErrorService } from 'src/services';

export function useGetRefundRequestExport(
  options?: UseQueryOptions<ApiResponseType<ExportValue>, Error, ExportValue> & {
    id?: string;
    onSuccessCallback?: Callback;
    onErrorCallback?: Callback;
  }
) {
  const [params, setParams] = useState<{ refundId: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data, error, isError, isFetching, refetch, isSuccess } = useQuery<
    ApiResponseType<ExportValue>,
    Error,
    ExportValue
  >([API_QUERIES.EXPORT_REFUND_REPORT, { refundId: options.id }, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, __, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<ExportValue>>(apiClient.getRefundExport, params);
    },
    select(data) {
      return data.data;
    },
    enabled: !!options?.id && !isEmpty(params),
    staleTime: 0,
    ...options,
  });

  // poll file
  const defaultOptions = {
    fileName: '',
    fileType: 'application/pdf',
    head: data?.headUrl,
    get: data?.getUrl,
    getMethod: false,
  };
  const { get, head, getMethod } = defaultOptions;

  const fileGenerator = useMemo(
    () =>
      pollByGenerator(head, get, {
        maxAttempts: 180,
        getMethod,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess, data]
  );

  useEffect(() => {
    if (data) {
      setIsLoading(true);
      // setParams(null);
      let interval: NodeJS.Timer;
      // eslint-disable-next-line prefer-const
      interval = setInterval(async () => {
        const { done, value } = await fileGenerator.next();
        if (done === true) {
          if (options?.onSuccessCallback) {
            options.onSuccessCallback(data);
          }
          clearInterval(interval);
          setParams(null);
          if (value) return setIsLoading(false);
          ErrorService.handler({ message: 'Export File Failed!' });
          return setIsLoading(false);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    isSuccess,
    refetch,
    setParams,
  };
}
