import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toData } from './helpers';
import { ReUseDialyzersFormType } from './types';

export function useGetReUseDialyzersHistory(
  options?: UseQueryOptions<
    ApiResponseType<{ reUseOfDialyzersForm: ReUseDialyzersFormType }>,
    Error,
    ReUseDialyzersFormType
  > & {
    id: ReUseDialyzersFormType['uuid'];
  }
) {
  const {
    data: reUseDialyzersHistory,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetReUseDialyzersHistory,
  } = useQuery<
    ApiResponseType<{ reUseOfDialyzersForm: ReUseDialyzersFormType }>,
    Error,
    ReUseDialyzersFormType
  >([API_QUERIES.REUSE_DIALYZERS_FORM_HISTORIES, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ reUseOfDialyzersForm: ReUseDialyzersFormType }>>(
        apiClient.getReUseDialyzersHistory,
        params
      );
    },
    select: (data) => toData(data?.data?.reUseOfDialyzersForm),
    enabled: !!options.id,
    ...options,
  });

  return {
    reUseDialyzersHistory,
    error,
    isError,
    isLoading,
    onGetReUseDialyzersHistory,
  };
}
