import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { StandingOrder } from './types';

export function useGetStandingOrderHistories(
  options?: UseQueryOptions<PaginationResponseType<StandingOrder>, Error> & {
    id: StandingOrder['uuid'];
  }
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetStandingOrderHistories,
  } = useQuery<PaginationResponseType<StandingOrder>, Error>(
    [API_QUERIES.STANDING_ORDER_FORMS_HISTORY, { ...params, id: options.id }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        return responseWrapper<PaginationResponseType<StandingOrder>>(() =>
          apiClient.getStandingOrderHistories(options.id, params)
        );
      },
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateStandingOrderHistories = () =>
    queryClient.invalidateQueries([API_QUERIES.STANDING_ORDER_FORMS_HISTORY, { id: options.id }]);

  const { data: standingOrderHistories, hasNext, payloadSize, totalRecords } = data || {};

  return {
    standingOrderHistories,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isLoading,
    setParams,
    onGetStandingOrderHistories,
    handleInvalidateStandingOrderHistories,
  };
}
