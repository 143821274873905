import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { MedicalAndPhysicalResponse } from './types';

// TODO: Tuyen Tran update type payload
export function useCreateMedicalAndPhysical(
  options?: UseMutationOptions<ApiResponseType<MedicalAndPhysicalResponse>, Error, any>
) {
  const {
    mutate: createMedicalAndPhysical,
    data,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<MedicalAndPhysicalResponse>, Error, any>({
    mutationFn: (payload: any) => responseWrapper(apiClient.createMedicalAndPhysical, [payload]),
    ...options,
  });

  return {
    createMedicalAndPhysical,
    data,
    isLoading,
    isSuccess,
  };
}
