import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toData } from './helpers';
import { PatientEducationFormType } from './types';

export function useGetPatientEducationHistory(
  options?: UseQueryOptions<
    ApiResponseType<{ patientEducationForm: PatientEducationFormType }>,
    Error,
    PatientEducationFormType
  > & {
    id: PatientEducationFormType['uuid'];
  }
) {
  const {
    data: patientEducationHistory,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetPatientEducationHistory,
  } = useQuery<
    ApiResponseType<{ patientEducationForm: PatientEducationFormType }>,
    Error,
    PatientEducationFormType
  >([API_QUERIES.PATIENT_EDUCATION_FORM_HISTORIES, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ patientEducationForm: PatientEducationFormType }>>(
        apiClient.getPatientEducationHistory,
        params
      );
    },
    select: (data) => toData(data?.data?.patientEducationForm),
    enabled: !!options.id,
    ...options,
  });

  return {
    patientEducationHistory,
    error,
    isError,
    isLoading,
    onGetPatientEducationHistory,
  };
}
