import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { authResponseWrapper } from 'src/queries/helpers';
import { NewRefundRequestValue } from './types';

export function usePostNewRefundRequest(
  options?: UseMutationOptions<any, Error, NewRefundRequestValue>
) {
  const {
    data,
    mutate: postNewRefundRequest,
    isSuccess,
    isLoading,
  } = useMutation<any, Error, NewRefundRequestValue>({
    mutationFn: (payload: NewRefundRequestValue) =>
      authResponseWrapper<any>(apiClient.postNewRefundRequest, [payload]),
    ...options,
  });
  return {
    data,
    postNewRefundRequest,
    isSuccess,
    isLoading,
  };
}
