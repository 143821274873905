export enum PatientEducationFormKey {
  Id = 'id',
  UUID = 'uuid',
  PatientChartCode = 'patientChartCode',
  PatientChartId = 'patientChartId',
  Name = 'patientName',
  Age = 'age',
  Gender = 'sex',
  AttendingPhysician = 'attendingPhysician',
  Diagnosis = 'diagnosis',
  Date = 'inputDate',
  PatientEducationList = 'detail',
  ActiveProblems = 'activeProblem',
  Discussion = 'discussion',
  UploadDocument = 'documentUrls',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
}
