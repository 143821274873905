import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';

export function useAddPatients(options?: UseMutationOptions<any, Error, { patientId: string }>) {
  const { mutate: addNewUuId } = useMutation<any, Error, { patientId: string }>({
    mutationFn: (payload: { patientId: string }) =>
      responseWrapper(apiClient.addPatients, [payload]),
    ...options,
  });

  return {
    addNewUuId,
  };
}
