export enum AttendingNephrologistKey {
  Id = 'id',
  UUID = 'uuid',
  NephrologistNumber = 'nephrologistNumber',
  Name = 'name',
  Email = 'email',
  DateOfBirth = 'birthDate',
  PhoneNumber = 'phone',
  PRCID = 'prcId',
  Status = 'status',

  Prefix = 'prefix',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  Suffix = 'suffix',
  EmergencyContactPhone = 'emergencyContactPhone',
  Address = 'address',
  DoctorCode = 'doctorCode',
  Sex = 'sex',
}
