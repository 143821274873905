import cn from 'classnames';
import { isEqual } from 'lodash';
import { useRef } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoCaretDownOutline } from 'react-icons/io5';
import Select, { components } from 'react-select';
import { COLOR_CODE } from 'src/appConfig/constants';
import { getRandomId } from 'src/utils';
import { isEmpty } from 'src/validations';
import Element from '../Element';
import View from '../View';
import './styles.scss';

const MAX_MENU_HEIGHT = 200;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <IoCaretDownOutline size={12} />
    </components.DropdownIndicator>
  );
};

const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    <FiSearch className="ml-8" />
    {children}
  </components.Control>
);

const ControlWithLabel = ({ children, ...props }) => {
  const { labelControl } = props.selectProps;
  return (
    <components.Control {...props}>
      <p className="pl-3 text-color-grey-900">{labelControl}</p>
      <span className="cmp-select__indicator-separator" />
      {children}
    </components.Control>
  );
};

const ControlNoSearchIcon = ({ children, ...props }) => (
  <components.Control {...props}>{children}</components.Control>
);

const IndicatorSeparator = (props) => <components.IndicatorSeparator {...props} />;

const SelectCmp = ({
  options,
  onChange,
  label = '',
  className = '',
  value,
  errorMessage = '',
  placeholder = 'Select',
  containerClassName = '',
  onBlur,
  name = '',
  required = false,
  infoTooltipMessage = '',
  infoTooltipPlacement = 'right',
  infoToolTipWithArrow = true,
  hideSearchIcon = true,
  isDisabled = false,
  isMulti = false,
  hideButtonDelete = false,
  menuPosition = 'fixed',
  labelControl = '',
  isClearable = false,
  onInputChange = (value) => {},
  ...props
}) => {
  const id = useRef(`select-${getRandomId()}`);
  const handleChange = (selectedOption) => {
    if (isMulti) {
      onChange(name, selectedOption ? selectedOption.map((item) => item?.value) : null);
    } else onChange(name, selectedOption?.value || null);
  };

  const handleSelectBlur = (event) => {
    onBlur && onBlur(name, true);
  };
  const hasError = !isEmpty(errorMessage);

  const selectedOption = isMulti
    ? options?.filter((option) => value.includes(option.value)) || null
    : options?.find((option) => isEqual(option.value, value)) || null;
  // For custom select, follow this link:
  // https://react-select.com/styles#using-classnames

  const defaultPlaceholder = isDisabled ? '' : null;

  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={containerClassName}
      required={required}
      infoTooltipMessage={infoTooltipMessage}
      infoTooltipPlacement={infoTooltipPlacement}
      infoToolTipWithArrow={infoToolTipWithArrow}
    >
      <View>
        <Select
          id={id.current}
          maxMenuHeight={MAX_MENU_HEIGHT}
          isClearable={isClearable}
          isDisabled={isDisabled}
          labelControl={labelControl}
          value={selectedOption}
          placeholder={placeholder || defaultPlaceholder}
          onChange={handleChange}
          options={options}
          className={cn('cmp-select', className, {
            'cmp-select--error': hasError,
            'cmp-select--is-disabled': isDisabled,
            'cmp-select__indicator--disabled': hideButtonDelete,
          })}
          isMulti={isMulti}
          classNamePrefix="cmp-select"
          menuPlacement="auto"
          closeMenuOnSelect={!isMulti}
          menuPosition={menuPosition}
          onBlur={handleSelectBlur}
          name={name}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: COLOR_CODE.PRIMARY,
              neutral20: COLOR_CODE.DISABLED,
              primary50: COLOR_CODE.PRIMARY_100,
            },
          })}
          components={{
            DropdownIndicator: isDisabled ? () => null : DropdownIndicator,
            IndicatorSeparator: isDisabled ? () => null : IndicatorSeparator,
            Control: labelControl
              ? ControlWithLabel
              : hideSearchIcon
              ? ControlNoSearchIcon
              : Control,
          }}
          onInputChange={onInputChange}
          {...props}
          menuPortalTarget={document.querySelector('#root')}
          isOptionDisabled={(option) => option.disabled}
        />
      </View>
    </Element>
  );
};

export default SelectCmp;
