import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from '../../apiClient';
import { ApiResponseType, responseWrapper } from '../../helpers';
import { API_QUERIES } from '../../keys';
import { toClinicalAbstract } from './helpers';
import { ClinicalAbstract } from './types';
export function useGetClinicalAbstractHistory(
  options?: UseQueryOptions<
    ApiResponseType<{ clinicalAbstractHistory: ClinicalAbstract }>,
    Error,
    ClinicalAbstract
  > & {
    uuid: ClinicalAbstract['uuid'];
  }
) {
  const {
    data: clinicalAbstractHistory,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetClinicalAbstractHistory,
  } = useQuery<
    ApiResponseType<{ clinicalAbstractHistory: ClinicalAbstract }>,
    Error,
    ClinicalAbstract
  >([API_QUERIES.CLINICAL_ABSTRACT_FORMS_HISTORY, { uuid: options.uuid }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ clinicalAbstractHistory: ClinicalAbstract }>>(
        apiClient.getClinicalAbstractHistory,
        params
      );
    },
    select: ({ data }) => toClinicalAbstract(data?.clinicalAbstractHistory),
    enabled: !!options.uuid,
    ...options,
  });

  return {
    clinicalAbstractHistory,
    error,
    isError,
    isLoading,
    onGetClinicalAbstractHistory,
  };
}
