export enum ADD_NEW_PATIENT_TYPE {
  TAB = 'tab',
}

export enum TAB_NAME_VALUE {
  PATIENT_DATA_FORM = 'patient-data-form',
  INFORMED_CONSENT = 'informed-consent',
  DIALYSIS_PRESCRIPTION = 'dialysis-prescription',
  HEMODIALYSIS_MONITORING = 'hemodialysis-monitoring',
  MEDICATION_RECORD = 'medication-record',
  LABORATORY_FLOW = 'laboratory-flow',
  PROGRESS_NOTE = 'progress-note',
}

export enum ADD_NEW_PATIENT_FORM_PARAMS {
  DOCUMENT_ID = 'documentId',
}

export enum FORM_ACTION_FORM {
  SAVE = 'save',
  EDIT = 'edit',
}

export enum VERSION_HISTORY_KEYS {
  CREATED_AT = 'createdAt',
  UPDATED_BY = 'updatedBy',
}
