import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { HepatitisScreeningResultsResponse } from './types';

export function useGetHepatitisScreeningResultsDetail(
  options?: UseQueryOptions<
    ApiResponseType<HepatitisScreeningResultsResponse>,
    Error,
    HepatitisScreeningResultsResponse
  > & {
    uuid: string;
  }
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data: hepatitisScreeningResultsDetail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetHepatitisScreeningResultsById,
  } = useQuery<
    ApiResponseType<HepatitisScreeningResultsResponse>,
    Error,
    HepatitisScreeningResultsResponse
  >([API_QUERIES.HEPATITIS_SCREENING_RESULTS, { ...params, uuid: options.uuid }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<HepatitisScreeningResultsResponse>>(
        apiClient.getHepatitisScreeningResultsDetail,
        params
      );
    },
    select: getResponseData,
    enabled: false,
    ...options,
  });

  const queryClient = useQueryClient();
  const handleInvalidateContinuousFlowSheetDetail = () =>
    queryClient.invalidateQueries([
      API_QUERIES.HEPATITIS_SCREENING_RESULTS,
      { uuid: options.uuid },
    ]);

  return {
    hepatitisScreeningResultsDetail,
    error,
    isError,
    isLoading,
    setParams,
    onGetHepatitisScreeningResultsById,
    handleInvalidateContinuousFlowSheetDetail,
  };
}
