import { ReUseDialyzersFormKey } from './keys';

export const toData = (data) => {
  return {
    ...data,
    [ReUseDialyzersFormKey.UploadDocument]: data?.[ReUseDialyzersFormKey.UploadDocument].map(
      (item) => JSON.parse(item)
    ),
  };
};
