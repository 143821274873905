import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { PhysicianOrder } from './types';

export function useGetPhysicianOrderHistories(
  options?: UseQueryOptions<PaginationResponseType<PhysicianOrder>, Error> & {
    id: PhysicianOrder['uuid'];
  }
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetPhysicianOrderHistories,
  } = useQuery<PaginationResponseType<PhysicianOrder>, Error>(
    [API_QUERIES.PHYSICIAN_ORDER_FORM_HISTORIES, { ...params, id: options.id }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        return responseWrapper<PaginationResponseType<PhysicianOrder>>(
          apiClient.getPhysicianOrderHistories,
          [params]
        );
      },
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidatePhysicianOrderHistories = () =>
    queryClient.invalidateQueries([API_QUERIES.PHYSICIAN_ORDER_FORM_HISTORIES, { id: options.id }]);

  const { data: physicianOrderHistories, totalRecords } = data || {};

  return {
    physicianOrderHistories,
    error,
    isError,
    totalRecords,
    isLoading,
    setParams,
    onGetPhysicianOrderHistories,
    handleInvalidatePhysicianOrderHistories,
  };
}
