export enum GenderOption {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum PatientChartFormName {
  GENERAL_INFORMATION = 'General Information',
  MEDICAL_HISTORY_AND_PHYSICAL_EXAMINATION = 'Medical History and Physical Examination Form',
  HEMODIALYSIS_TREATMENT = 'Hemodialysis Treatment',
  RE_USE_OF_DIALYZERS = 'Re- Use of Dialyzers',
  ADVANCE_DIRECTIVES = 'Advance Directives (Released of Responsibility)',
  STANDING_ORDER = 'Standing Order',
  PHYSICIAN_ORDER = 'Physician Order',
  CLINICAL_ABSTRACT = 'Clinical Abstract',
  TREATMENT_SHEET = 'Treatment Sheet',
  CONTINUOUS_FLOW_SHEET = 'Continuous Flow Sheet',
  MONTHLY_LABORATORY_RESULT = 'Monthly Laboratory Result',
  CXR_RESULT = 'CXR Result',
  HEPATITIS_SCREENING_RESULTS = 'Hepatitis Screening Results',
  VACCINATION_RECORD = 'Vaccination Record',
  PROBLEM_LIST = 'Problem List',
  NUTRITIONAL_ASSESSMENT = 'Nutritional Assessment',
  PATIENT_EDUCATION = 'Patient Education',
  MEDICATION_RECORD = 'Medication Record',
}

export enum PatientChartTabsOption {
  PATIENT_DATA_FORM = 'Patient Data Form',
  INFORMED_CONSENT = 'Informed Consent',
  DIALYSIS_PRESCRIPTION = 'Dialysis Prescription',
  HEMODIALYSIS_MONITORING = 'Hemodialysis Monitoring',
  MEDICATION_RECORD = 'Medication Record',
  LABORATORY_FLOW = 'Laboratory Flow',
  PROGRESS_NOTE = 'Progress Note',
}

export enum PATIENT_CHART_FORM_NAME {
  GENERAL_INFORMATION = 'general-information',
  MEDICAL_HISTORY_AND_PHYSICAL_EXAMINATION = 'medical-history-and-physical-examination-form',
  HEMODIALYSIS_TREATMENT = 'hemodialysis-treatment',
  RE_USE_OF_DIALYZERS = 're-use-of-dialyzers',
  ADVANCE_DIRECTIVES = 'advance-directives',
  STANDING_ORDER = 'standing-order',
  PHYSICIAN_ORDER = 'physician-order',
  CLINICAL_ABSTRACT = 'clinical-abstract',
  TREATMENT_SHEET = 'treatment-sheet',
  CONTINUOUS_FLOW_SHEET = 'continuous-flow-sheet',
  MONTHLY_LABORATORY_RESULT = 'monthly-laboratory-result',
  CXR_RESULT = 'cxr-result',
  HEPATITIS_SCREENING_RESULTS = 'hepatitis-screening-results',
  VACCINATION_RECORD = 'vaccination-record',
  PROBLEM_LIST = 'problem-list',
  NUTRITIONAL_ASSESSMENT = 'nutritional-assessment',
  PATIENT_EDUCATION = 'patient-education',
  MEDICATION_RECORD = 'medication-record',
}
