import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IFormState<T = any> {
  formData: T | null;
  activeStep: number;
  maxValidStep: number;
  formSteps: Array<T>;
  formAction: FormAction;
  formEventAction: FormEventAction;
}
export const FIRST_STEP = 0;

// use in Patient Chart
export enum FormAction {
  SAVE = 'SAVE',
  DISCARD = 'DISCARD',
}

// use in Charge Slip and SOA Form (Treatment Record)
export enum FormEventAction {
  REQUEST_FOR_APPROVAL = 'REQUEST_FOR_APPROVAL',
  EDIT = 'EDIT',
  CANCEL = 'CANCEL',
}

const initialState: IFormState = {
  activeStep: FIRST_STEP,
  maxValidStep: 0,
  formData: null,
  formSteps: [],
  formAction: null,
  formEventAction: null,
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormData: <T>(state, action: PayloadAction<T>) => {
      state.formData = action.payload;
    },
    setFormSteps: (state, action: PayloadAction<Array<any>>) => {
      state.formSteps = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
      if (state.maxValidStep < action.payload) {
        state.maxValidStep = action.payload;
      }
    },
    clearFormSteps: (state, action: PayloadAction<any>) => {
      state.activeStep = initialState.activeStep;
      state.maxValidStep = initialState.maxValidStep;
      state.formData = action.payload;
    },
    setMaxValidStep: (state, action: PayloadAction<number>) => {
      state.maxValidStep = action.payload;
      if (state.activeStep > action.payload) {
        state.activeStep = action.payload;
      }
    },
    clearFormState: (state) => {
      state.activeStep = initialState.activeStep;
      state.formSteps = initialState.formSteps;
      state.maxValidStep = initialState.maxValidStep;
      state.formData = initialState.formData;
    },
    setFormAction: (state, action: PayloadAction<FormAction>) => {
      state.formAction = action.payload;
    },
    setFormEventAction: (state, action: PayloadAction<FormEventAction>) => {
      state.formEventAction = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFormData,
  setActiveStep,
  setFormSteps,
  clearFormState,
  setMaxValidStep,
  clearFormSteps,
  setFormAction,
  setFormEventAction,
} = formSlice.actions;

export const formState = formSlice.getInitialState();

export default formSlice.reducer;
