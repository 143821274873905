import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toData } from './helpers';
import { NutritionalAssessmentFormType } from './types';

export function useGetNutritionalAssessmentHistory(
  options?: UseQueryOptions<
    ApiResponseType<{ nutritionalAssessmentForm: NutritionalAssessmentFormType }>,
    Error,
    NutritionalAssessmentFormType
  > & {
    id: NutritionalAssessmentFormType['uuid'];
  }
) {
  const {
    data: nutritionalAssessmentHistory,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetNutritionalAssessmentHistory,
  } = useQuery<
    ApiResponseType<{ nutritionalAssessmentForm: NutritionalAssessmentFormType }>,
    Error,
    NutritionalAssessmentFormType
  >([API_QUERIES.NUTRITIONAL_ASSESSMENT_HISTORIES, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<
        ApiResponseType<{ nutritionalAssessmentForm: NutritionalAssessmentFormType }>
      >(apiClient.getNutritionalAssessmentHistory, params);
    },
    select: (data) => toData(data?.data?.nutritionalAssessmentForm),
    enabled: !!options.id,
    ...options,
  });

  return {
    nutritionalAssessmentHistory,
    error,
    isError,
    isLoading,
    onGetNutritionalAssessmentHistory,
  };
}
