import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { useDebounce } from 'src/hooks';
import { TableParams } from 'src/redux/types';
import { ValueOptions } from '../PatientChartListing';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { ProductItem } from './types';

const search = {
  take: 10,
  skip: 0,
};

const transferProductOptions = (products: ProductItem[]) => {
  return products.map((item: ProductItem) => ({
    label: item.productName,
    value: item.productId,
  }));
};

const getValueProductOptions = (products: ProductItem[]) => {
  return products.map((item: ProductItem) => ({
    ...item,
  }));
};

export function useGetProductOptions(
  options?: UseInfiniteQueryOptions<PaginationResponseType<ProductItem>, Error>
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const [params, setParams] = useState<TableParams>(null);
  const debounceSearch = useDebounce(inputSearch);

  const {
    data: products,
    error,
    isError,
    isFetching,
    refetch: onGetProducts,
    fetchNextPage,
  } = useInfiniteQuery<PaginationResponseType<ProductItem>, Error>(
    [API_QUERIES.PRODUCTS, 'options', debounceSearch, { type: 'lazy' }],
    (props): Promise<PaginationResponseType<ProductItem>> => {
      const { pageParam = search } = props;

      return responseWrapper<PaginationResponseType<ProductItem>>(apiClient.getProducts, [
        { ...pageParam, ...params, search: inputSearch },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },

      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: !!params,
      ...options,
    }
  );
  const hasNext = useMemo(() => {
    if (isEmpty(products?.pages)) return null;
    return true;
  }, [products]);

  const productOptions: ValueOptions[] = useMemo(() => {
    if (isEmpty(products?.pages)) return [];
    return products.pages.reduce(
      (state, page, _pageIdx) => [...state, ...transferProductOptions(page.data)],
      []
    );
  }, [products]);

  const productsValue: ProductItem[] = useMemo(() => {
    if (isEmpty(products?.pages)) return [];
    return products.pages.reduce(
      (state, page, _pageIdx) => [...state, ...getValueProductOptions(page.data)],
      []
    );
  }, [products]);

  return {
    productsValue,
    productOptions,
    error,
    hasNext,
    isError,
    loading: isFetching,
    setInputSearch,
    onGetProducts,
    fetchNextPage,
    setParams,
  };
}
