import { ThemeProvider } from '@mui/material';
import React from 'react';
import { theme } from 'src/appConfig/muiTheme';
import { Loading } from '../common';

type Props = {
  isLoading: boolean;
  children: React.ReactNode;
};

const CustomLoadingDetailView: React.FC<Props> = ({ children, isLoading = false }) => {
  return (
    <ThemeProvider theme={theme}>
      {isLoading ? <Loading loadingStyle={2} variant="secondary" /> : <>{children}</>}
    </ThemeProvider>
  );
};

export default CustomLoadingDetailView;
