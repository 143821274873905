import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { HepatitisScreeningResultsFormValue, HepatitisScreeningResultsResponse } from './types';

export function useCreateHepatitisScreeningResults(
  options?: UseMutationOptions<
    ApiResponseType<HepatitisScreeningResultsResponse>,
    Error,
    HepatitisScreeningResultsFormValue
  >
) {
  const {
    mutate: createHepatitisScreeningResults,
    data,
    isLoading,
    isSuccess,
  } = useMutation<
    ApiResponseType<HepatitisScreeningResultsResponse>,
    Error,
    HepatitisScreeningResultsFormValue
  >({
    mutationFn: (payload: HepatitisScreeningResultsFormValue) =>
      responseWrapper(apiClient.createHepatitisScreeningResults, [payload]),
    ...options,
  });

  return {
    createHepatitisScreeningResults,
    data,
    isLoading,
    isSuccess,
  };
}
