export enum TreatmentSheetKey {
  Id = 'uuid',
  PatientCode = 'patientCode',
  PatientChartCode = 'patientChartCode',
  PatientName = 'patientName',
  PatientId = 'patientId',
  Date = 'date',
  MachineNumber = 'machineNumber',
  DialyzerType = 'dialyzerType',
  ReUse = 'reUse',
  HeparinLoad = 'heparinLoad',
  CC = 'cc',
  Min = 'min',
  Anesthesia = 'anesthesia',
  Allergies = 'allergies',
  PostWt = 'postWt',
  PreHD = 'preHD',
  Variance = 'variance',
  DryWt = 'dryWt',
  PrevWt = 'prevWt',
  UFGoal = 'ufGoal',
  IsFeverChills = 'isFeverChills',
  IsDyspnea = 'isDyspnea',
  IsHeadache = 'isHeadache',
  IsChestPain = 'isChestPain',
  IsNausea = 'isNausea',
  IsPalpitations = 'isPalpitations',
  IsVomiting = 'isVomiting',
  IsPruritus = 'isPruritus',
  IsInsomnia = 'isInsomnia',
  IsCough = 'isCough',
  IsPreDialysisSymptomOther = 'isPreDialysisSymptomOther',
  PreDialysisSymptomOther = 'preDialysisSymptomOther',
  IsPallor = 'isPallor',
  IsRales = 'isRales',
  IsIcterisia = 'isIcterisia',
  IsArrthythmia = 'isArrthythmia',
  IsCrackles = 'isCrackles',
  IsAscites = 'isAscites',
  IsPedalEdema = 'isPedalEdema',
  IsGeneralizedJaundice = 'isGeneralizedJaundice',
  IsWheezes = 'isWheezes',
  IsPreDialysisPEOther = 'isPreDialysisPEOther',
  PreDialysisPEOther = 'preDialysisPEOther',
  IsProceedWithHD = 'assessment',
  HDAccess = 'hdAccess',
  Bruit = 'bruit',
  Thrill = 'thrill',
  IsTemporaryCatheter = 'catheter',
  TemporaryCatheterType = 'temporaryCatheterType',
  TemporaryCatheterPosition = 'temporaryCatheterPosition',
  CatheterSite = 'catheterSite',
  IsDischarge = 'discharge',
  IsRedness = 'redness',
  IsSutureIntact = 'sutureIntact',
  Fistula = 'fistula',
  Graft = 'graft',
  Endorsement = 'endorsement',
  PreAssessment = 'preAssessment',
  ReceivedOn = 'receivedOn',
  PreAssessmentBP = 'preAssessmentBP',
  PreAssessmentHRPR = 'preAssessmentHRPR',
  PreAssessmentTemp = 'preAssessmentTemp',
  ScheduleTime = 'timeShift',
  TimeOn = 'timeOn',
  TimeOff = 'timeOff',
  BloodFlowRate = 'bloodFlowRate',
  PreAssessmentInfo = 'preAssessmentInfo',
  TotalFluidRemoved = 'totalFluidRemoved',
  EPO = 'epo',
  Others = 'other',
  TotalFluidRemovedBP = 'totalFluidRemovedBP',
  TotalFluidRemovedHR = 'totalFluidRemovedHR',
  TotalFluidRemovedTemp = 'totalFluidRemovedTemp',
  TotalFluidRemovedPriming = 'priming',
  TotalFluidRemovedCannulation = 'cannulation',
  TotalFluidRemovedInitiation = 'initiation',
  TotalFluidRemovedTermination = 'termination',
  IsAmbulatoryPostAssessment = 'isAmbulatoryPostAssessment',
  IsFeverPostAssessment = 'isFeverPostAssessment',
  IsHematomaPostAssessment = 'isHematomaPostAssessment',
  IsEdemaPostAssessment = 'isEdemaPostAssessment',
  IsWheelChairPostAssessment = 'isWheelChairPostAssessment',
  IsCrampsPostAssessment = 'isCrampsPostAssessment',
  IsTinnitusPostAssessment = 'isTinnitusPostAssessment',
  IsStretcherPostAssessment = 'isStretcherPostAssessment',
  IsChestPainPostAssessment = 'isChestPainPostAssessment',
  IsDyspnePostAssessment = 'isDyspneaPostAssessment',
  IsProlongedBleedingPostAssessment = 'isProlongedBleedingPostAssessment',
  ProlongedBleedingPostAssessment = 'prolongedBleedingPostAssessment',
  ProlongedBleedingMin = 'prolongedBleedingMin',
  NursesNotes = 'nurseNote',
  PhysiciansOrderAdvanceDirectives = 'physiciansOrderAdvanceDirectives',
  PatientsEducationHealthTeaching = 'patientsEducationHealthTeaching',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',

  Time = 'time',
  BP = 'bp',
  Pulse = 'pulse',
  BFR = 'bfr',
  AVP = 'avp',
  TMP = 'tmp',
  ActualUFVol = 'actualUFVol',
  Remarks = 'remarks',
  RN = 'rn',

  SignatureDate = 'signatureDate',
  MedicalTeam = 'medicalTeam',
  DocumentUrls = 'documentUrls',
}
