/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { Callback } from 'src/redux/types';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPresignedDownloadPayload, GetPresignedDownloadResponse } from './types';

export function useGetPresignedDownloadUrl(
  options?: UseQueryOptions<
    ApiResponseType<GetPresignedDownloadResponse>,
    Error,
    GetPresignedDownloadResponse
  > & {
    onDecodeUrlChange?: Callback;
  }
) {
  const [params, setParams] = useState<GetPresignedDownloadPayload>(null);
  const {
    // mutate: onGetDecodeUrl,
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useQuery<ApiResponseType<GetPresignedDownloadResponse>, Error, GetPresignedDownloadResponse>(
    [API_QUERIES._DECODE_FILE_URL, params],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<GetPresignedDownloadResponse>>(
          apiClient.getDecodeUserServiceUrl,
          params
        );
      },
      enabled: !!params,
      select: getResponseData,
      ...options,
    }
  );

  useEffect(() => {
    if (data) {
      options.onDecodeUrlChange(data.url, params);
      setParams(null);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    error,
    isError,
    isLoading,
    isSuccess,
    onGetDecodeUrl: setParams,
    data,
  };
}
