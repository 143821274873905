import cn from 'classnames'
import React, { useRef, useState } from 'react'
import { useClickInside, useClickOutside } from 'src/hooks'
import './styles.scss'

const Dropdown: React.FC<Props> = ({ options, value, onChange }) => {
  const [showDrop, setShowDrop] = useState(false)

  const dropRef = useRef(null)

  useClickInside(dropRef, () => {
    setShowDrop(prev => !prev)
  })

  useClickOutside(dropRef, () => {
    setShowDrop(false)
  })

  const handleChangeValue = (e, value) => {
    e.stopPropagation()
    setShowDrop(false)
    onChange && onChange(value)
  }

  const getLabel = () => {
    const item = options?.find(
      item => item.value === value
    )

    return item?.label
  }

  return <div className='cmp-dropdown'>
    <div className='cmp-dropdown--body' ref={dropRef}>
      <div className='label'>{getLabel()}</div>
      <div className='arrow-container'>
        <div className='arrow-down' />
        <div className={
          cn(
            'dropItems',
            { 'hidden': !showDrop }
          )
        }>
          {
            options?.map(
              item =>
                <div
                  key={item.value}
                  className={
                    cn(
                      'item',
                      { 'active': item.value === value }
                    )
                  }
                  onClick={(e) => handleChangeValue(e, item.value)}
                  role="presentation"
                >
                  {item.label}
                </div>
            )
          }
        </div>
      </div>

    </div>
  </div>
}

type Props = {
  options: Array<{ label: string, value: string }>,
  value: string,
  onChange: Function
}

export default Dropdown