import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TreatmentRecordPatientsResponse } from './types';

export function useGetTreatmentsPatientCharts(
  options?: UseQueryOptions<
    ApiResponseType<TreatmentRecordPatientsResponse>,
    Error,
    TreatmentRecordPatientsResponse
  > & {
    patientId: string;
    treatmentId: string;
  }
) {
  const {
    data: treatmentRecordData,
    error,
    isFetching,
    refetch: onGetAllPatients,
  } = useQuery<
    ApiResponseType<TreatmentRecordPatientsResponse>,
    Error,
    TreatmentRecordPatientsResponse
  >(
    [
      API_QUERIES.TREATMENTS_RECORD_PATIENTS,
      { patientId: options?.patientId, treatmentId: options?.treatmentId },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [, ...params] = query.queryKey;

        return responseWrapper<ApiResponseType<TreatmentRecordPatientsResponse>>(
          apiClient.getTreatmentsPatientCharts,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      select: getResponseData,
      keepPreviousData: true,
      ...options,
    }
  );

  const { id, patientChartCode, patientId, treatmentId, ...patientChart } =
    treatmentRecordData || {};

  const queryClient = useQueryClient();

  const handleInvalidateAllPatientChartListing = () =>
    queryClient.invalidateQueries(API_QUERIES.TREATMENTS_RECORD_PATIENTS);

  return {
    patientChartId: id,
    patientChartListing: [patientChart][0],
    treatmentRecordData,
    error,
    isFetching,
    onGetAllPatients,
    handleInvalidateAllPatientChartListing,
  };
}
