/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';

export function useDeletePatientChart(options?: UseMutationOptions<any, Error, any>) {
  const {
    mutate: onDeletePatientChart,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, any>({
    mutationFn: (payload: any) => responseWrapper(apiClient.deletePatientChart, [payload]),
    ...options,
  });

  return {
    onDeletePatientChart,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
