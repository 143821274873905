import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { connect } from 'react-redux';
import { IMAGES } from 'src/appConfig/images';
import { useLogout } from 'src/queries';
import { setAuthenticated } from 'src/redux/auth/authSlice';
import { IRootState } from 'src/redux/store';
import { getTitleCase } from 'src/utils';
import { Image } from '../common';
import './styles.scss';
const NavAvatar: React.FC<Props> = ({ pathname, userProfile }) => {
  const { logout } = useLogout();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const {
    fullName,
    roles: [firstRole],
  } = userProfile || { roles: [] };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    logout();
  };

  const open = Boolean(anchorEl);
  return (
    <Fragment>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
      >
        <Image src={IMAGES.defaultUser} className="ctn-navbar__avatar" />
        <Box sx={{ marginLeft: 1 }}>
          <Typography variant="h5" className="ctn-navbar__text">
            {getTitleCase(fullName?.replace('_', ' '))}
          </Typography>
          <Typography variant="body2" className="ctn-navbar__text">
            {getTitleCase(firstRole?.role?.displayName)}
          </Typography>
        </Box>
      </Stack>
      <Menu
        id={'simple-Menu'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ mt: 1 }}
        elevation={1}
      >
        <MenuItem onClick={handleLogOut}>
          <RiLogoutBoxLine size={20} />
          <Typography sx={{ ml: 1 }}>Log out</Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & { pathname: string };

const mapStateToProps = (state: IRootState) => ({
  userProfile: state.auth.user,
});

const mapDispatchToProps = {
  onSetAuth: setAuthenticated,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavAvatar);
