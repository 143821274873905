import { Location } from 'history';
import React, { Suspense, lazy, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Redirect, RouteProps, Switch, useHistory } from 'react-router-dom';

import { PATHS } from 'src/appConfig/paths';
import Navbar from 'src/components/Navbar';
import { IRootState } from 'src/redux/rootReducer';
import { Navigator, TenantService } from 'src/services';
import Dev from './Dev';

import AuthContainer from './StartupContainers/AuthContainer';
import ContentContainer from './StartupContainers/ContentContainer';
import DialogContainer from './StartupContainers/DialogContainer';
import NotFound from './StartupContainers/NotFound';
import ToastContainer from './StartupContainers/ToastContainer';

import { Box } from '@mui/material';
import { useComponentDidMount } from 'src/hooks';
import ScrollToTop from './StartupContainers/ScrollToTop';

import { useProSidebar } from 'react-pro-sidebar';
import { useDispatch } from 'react-redux';
import SidebarMenus from 'src/components/SidebarMenus';
import { Loading } from 'src/components/common';
import CustomRoute from 'src/components/common/CustomRouting';
import { setShowSidebar } from 'src/redux/common/commonSlice';
import LightboxContainer from './StartupContainers/LightboxContainer';

// =================  AUTH ====================================
// const Dashboard = lazy(() => import('./Dashboard'));
const Login = lazy(() => import('./UAMContainer/Signin'));
const ForgotPassword = lazy(() => import('./UAMContainer/ForgotPassword'));
const WelCome = lazy(() => import('./UAMContainer/Welcome'));
//=============== Patient Chart =============================
const PatientChartManagement = lazy(() => import('./PatientChartManagement'));
const PatientManagement = lazy(() => import('./PatientManagement'));

const NurseManagement = lazy(() => import('./NurseManagement'));
const AttendingNephrologistManagement = lazy(() => import('./AttendingNephrologistManagement'));
//=============== Appointments =============================
const AppointmentsManagement = lazy(() => import('./AppointmentsManagement'));
const PatientSchedule = lazy(() => import('./PatientSchedule/PatientScheduleListing'));

// ============== Refunds ===========
const RefundsManagement = lazy(() => import('./RefundsManagement'));

//=============== User Management ========================
const UsersManagementContainers = React.lazy(() => import('./UsersManagement'));
// ============================= Templates =========================
const TemplatesManagementContainers = React.lazy(() => import('./TemplatesManagement'));

// ========================== Final Manual ===============
const FinalManualContainers = React.lazy(() => import('./FinalManual'));

const Routing: React.FC<ContainerProps> = ({ location, isAuthenticated }) => {
  const { showNavbar } = useSelector((state: IRootState) => state.common);

  const { collapsed, toggled } = useProSidebar();

  const sidebarWidth = toggled && isAuthenticated ? (collapsed ? 80 : 288) : 0;
  const navbarHeight = toggled && showNavbar ? 94 : 0;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShowSidebar(true));
  }, [dispatch]);

  Navigator.setTopHistory(useHistory());

  useComponentDidMount(() => {
    const currentWebTenant = TenantService.getWebTenant();
    TenantService.setTenant({ name: currentWebTenant });
  });

  return (
    <Box>
      <Navbar location={location} />
      <SidebarMenus location={location} />
      <Suspense fallback={<Loading />}>
        <Box
          sx={{
            marginLeft: `${sidebarWidth}px`,
            backgroundColor: '#ffffff',
            marginTop: `${navbarHeight}px`,
            height: '100%',
            minHeight: '100vh',
          }}
        >
          <Switch location={location}>
            <CustomRoute
              path={PATHS.root}
              render={() => <Redirect to={{ pathname: PATHS.patient }} />}
              exact
            />

            <CustomRoute
              path={PATHS.templates}
              component={TemplatesManagementContainers}
              pageRequiredAuth
              exact
            />
            <CustomRoute
              path={PATHS.finalManual}
              component={FinalManualContainers}
              pageRequiredAuth
              exact
            />
            <CustomRoute
              path={PATHS.patientChart}
              component={PatientChartManagement}
              pageRequiredAuth
            />
            <CustomRoute path={PATHS.patient} component={PatientManagement} pageRequiredAuth />

            <CustomRoute path={PATHS.refunds} component={RefundsManagement} pageRequiredAuth />

            <CustomRoute
              path={PATHS.appointments}
              component={AppointmentsManagement}
              pageRequiredAuth
            />
            <CustomRoute path={PATHS.nurses} component={NurseManagement} pageRequiredAuth />
            <CustomRoute
              path={PATHS.attendingNephrologist}
              component={AttendingNephrologistManagement}
              pageRequiredAuth
            />
            <CustomRoute
              path={PATHS.patientSchedule}
              component={PatientSchedule}
              pageRequiredAuth
              exact
            />

            {/* <CustomRoute path={PATHS.dashboard} component={Dashboard} pageRequiredAuth exact /> */}
            {/* TODO: implement later */}
            <CustomRoute
              path={PATHS.userManagements}
              component={UsersManagementContainers}
              pageRequiredAuth
              exact
            />

            {/* AUTH ROUTING */}
            <CustomRoute path={PATHS.welcome} component={WelCome} exact />
            <CustomRoute path={PATHS.signIn} component={Login} exact />
            <CustomRoute path={PATHS.logout} component={Login} exact />
            <CustomRoute path={PATHS.forgotPassword} component={ForgotPassword} />
            <CustomRoute path={PATHS.dev} component={Dev} pageRequiredAuth />
            <CustomRoute path="*" component={NotFound} pageRequiredAuth exact />
          </Switch>
        </Box>
      </Suspense>
      <ContentContainer />
      <LightboxContainer />
      <AuthContainer />
      <DialogContainer />
      <ToastContainer />
      <ScrollToTop />
    </Box>
  );
};

type ContainerProps = ReturnType<typeof mapStateToProps> & RouteProps & { location: Location };

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(Routing);
