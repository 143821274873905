import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { ReUseDialyzersFormKey, ReUseDialyzersFormType } from 'src/queries';

export type ReUseDialyzersFormikProps = {
  values: ReUseDialyzersFormType;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors?: FormikErrors<any>;
  touched?: FormikTouched<any>;
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
  setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean) => void;
  getErrorMessage: (fieldName: ReUseDialyzersFormKey) => string;
  dirty: boolean;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  handleReset: (e?: React.FormEvent<HTMLFormElement>) => void;
  validateForm: (values?: ReUseDialyzersFormType) => Promise<FormikErrors<ReUseDialyzersFormType>>;
};

export const toInitialValues = (
  data?: {},
  isResetForm: boolean = false
): ReUseDialyzersFormType => ({
  [ReUseDialyzersFormKey.PatientChartCode]: data?.[ReUseDialyzersFormKey.PatientChartCode] || '',
  [ReUseDialyzersFormKey.FirstName]: data?.[ReUseDialyzersFormKey.FirstName] || '',
  [ReUseDialyzersFormKey.MiddleName]: data?.[ReUseDialyzersFormKey.MiddleName] || '',
  [ReUseDialyzersFormKey.LastName]: data?.[ReUseDialyzersFormKey.LastName] || '',
  [ReUseDialyzersFormKey.SignatureOverPrintedNamePatient]: isResetForm
    ? ''
    : data?.[ReUseDialyzersFormKey.SignatureOverPrintedNamePatient] || '',
  [ReUseDialyzersFormKey.PatientSignatureDate]: isResetForm
    ? ''
    : data?.[ReUseDialyzersFormKey.PatientSignatureDate] || '',
  [ReUseDialyzersFormKey.Witness]: isResetForm ? '' : data?.[ReUseDialyzersFormKey.Witness] || '',
  [ReUseDialyzersFormKey.WitnessSignatureDate]: isResetForm
    ? ''
    : data?.[ReUseDialyzersFormKey.WitnessSignatureDate] || '',
  [ReUseDialyzersFormKey.UploadDocument]: isResetForm
    ? []
    : data?.[ReUseDialyzersFormKey.UploadDocument] || [],
});

export const toDataReUseDialyzersForm = (data) => {
  return {
    ...data,
    [ReUseDialyzersFormKey.UploadDocument]: data?.[ReUseDialyzersFormKey.UploadDocument].map(
      (item) => JSON.stringify(item)
    ),
  };
};
