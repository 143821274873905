import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { TreatmentSheet } from './types';

export function useEditTreatmentSheet(
  options?: UseMutationOptions<TreatmentSheet, Error, TreatmentSheet>
) {
  const {
    mutate: editTreatmentSheet,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<TreatmentSheet, Error, TreatmentSheet>({
    mutationFn: (payload: TreatmentSheet) =>
      responseWrapper(apiClient.editTreatmentSheet, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editTreatmentSheet,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
