import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { MonthlyLaboratoryResult } from './types';

export function useCreateMonthlyLaboratoryResult(
  options?: UseMutationOptions<
    { monthlyLaboratlyResult: MonthlyLaboratoryResult },
    Error,
    MonthlyLaboratoryResult
  >
) {
  const { mutate: createMonthlyLaboratoryResult, isLoading } = useMutation<
    { monthlyLaboratlyResult: MonthlyLaboratoryResult },
    Error,
    MonthlyLaboratoryResult
  >({
    mutationFn: (payload: MonthlyLaboratoryResult) =>
      createResponseWrapper(apiClient.createMonthlyLaboratoryResult, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createMonthlyLaboratoryResult,
    isLoading,
  };
}
