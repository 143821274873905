import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { IoCalendar } from 'react-icons/io5';
import { getRandomId } from 'src/utils';
import { isEmpty } from 'src/validations';
import Element from '../Element';

import './styles.scss';

export type DateRange = [Date, Date];

const DateRangePicker: React.FC<Props> = ({
  label,
  name,
  monthsShown,
  onChange,
  errorMessage,
  containerClassName,
  classNames,
  placeholder = 'MM/DD/YYYY - MM/DD/YYYY',
  dateFormat = 'MM/DD/YYYY',
  selectedDates,
  scrollableYearDropdown,
  popperPlacement = 'bottom-start',
  ...props
}) => {
  const id = useRef<string>(`datepicker-${getRandomId()}`);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dates, setDates] = useState<DateRange>(selectedDates as DateRange);

  const handleDateChange = (dates: DateRange) => {
    setDates(dates);
    if (!!dates[0] && !!dates[1]) {
      setIsOpen(false);
      onChange(name, dates);
      setDates(selectedDates);
    }
  };

  const formatDates = (): string => {
    if (isEmpty(selectedDates)) return '';
    return selectedDates?.map((x) => dayjs(x).format(dateFormat as string)).join(' - ') || '';
  };

  const handleClickOutside = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsOpen(false);
  };

  // For more information:
  // https://reactdatepicker.com/

  const hasError = !isEmpty(errorMessage);
  const startDate = dates?.[0];
  const endDate = dates?.[1];

  useEffect(() => {
    setDates(selectedDates);
  }, [selectedDates]);

  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={cn('cmp-datepicker', containerClassName)}
    >
      <Stack position="relative">
        <DatePicker
          id={id.current}
          onChange={handleDateChange}
          placeholderText={placeholder}
          className={cn(
            'cmp-datepicker__input',
            { 'cmp-datepicker__input--error': hasError },
            classNames
          )}
          showPopperArrow={false}
          {...props}
          value={formatDates()}
          selectsRange
          monthsShown={monthsShown}
          scrollableYearDropdown={scrollableYearDropdown}
          startDate={startDate}
          endDate={endDate}
          open={isOpen}
          onInputClick={() => setIsOpen(true)}
          onClickOutside={handleClickOutside}
          popperProps={{
            positionFixed: true,
          }}
          portalId="root"
        />
        <i className="cmp-date-range-picker--calendar-icon">
          <IoCalendar
            onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
              e.stopPropagation();
              setIsOpen((prev) => !prev);
            }}
          />
        </i>
      </Stack>
    </Element>
  );
};

type Props = Omit<ReactDatePickerProps, 'onChange'> & {
  errorMessage?: string;
  containerClassName?: string;
  classNames?: string;
  placeholder?: string;
  label?: string;
  selectedDates?: [Date, Date];
  monthsShown?: number;
  scrollableYearDropdown?: boolean;
  popperPlacement?: string;
  onChange?: (name: string, value: DateRange) => void;
};

export default DateRangePicker;
