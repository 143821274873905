import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TableParams } from 'src/redux/types';
import { ErrorService } from 'src/services';
import apiClient from '../../apiClient';
import { PaginationResponseType, responseWrapper } from '../../helpers';
import { API_QUERIES } from '../../keys';
import { ClinicalAbstract } from './types';

export function useGetAllClinicalAbstractHistories(
  options?: UseQueryOptions<PaginationResponseType<ClinicalAbstract>, Error> & {
    uuid: ClinicalAbstract['uuid'];
  }
) {
  const [params, setParams] = useState<TableParams>({});
  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetAllClinicalAbstractHistories,
  } = useQuery<PaginationResponseType<ClinicalAbstract>, Error>(
    [API_QUERIES.CLINICAL_ABSTRACT_FORMS_HISTORY, { ...params, uuid: options.uuid }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return responseWrapper<PaginationResponseType<ClinicalAbstract>>(() =>
          apiClient.getAllClinicalAbstractHistory(options.uuid, params)
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.uuid,
      onError: ErrorService.handler,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllClinicalAbstractHistories = () =>
    queryClient.invalidateQueries([
      API_QUERIES.CLINICAL_ABSTRACT_FORMS_HISTORY,
      { uuid: options.uuid },
    ]);

  const { data: clinicalAbstractHistories, hasNext, payloadSize, totalRecords } = data || {};

  return {
    clinicalAbstractHistories,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isLoading,
    setParams,
    onGetAllClinicalAbstractHistories,
    handleInvalidateAllClinicalAbstractHistories,
  };
}
