import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toMedicationRecord } from './helpers';
import { MedicationRecord } from './types';

export function useGetMedicationRecord(
  options?: UseQueryOptions<
    ApiResponseType<{ medicationRecord: MedicationRecord }>,
    Error,
    MedicationRecord
  > & {
    id: MedicationRecord['uuid'];
  }
) {
  const {
    data: medicationRecord,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetMedicationRecord,
  } = useQuery<ApiResponseType<{ medicationRecord: MedicationRecord }>, Error, MedicationRecord>(
    [API_QUERIES.MEDICATION_RECORD, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ medicationRecord: MedicationRecord }>>(
          apiClient.getMedicationRecord,
          params
        );
      },
      select: (data) => toMedicationRecord(data.data.medicationRecord),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateMedicationRecord = () =>
    queryClient.invalidateQueries([API_QUERIES.MEDICATION_RECORD, { id: options.id }]);

  return {
    medicationRecord,
    error,
    isError,
    isLoading,
    onGetMedicationRecord,
    handleInvalidateMedicationRecord,
  };
}
