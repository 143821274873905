import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import { PatientForms } from './types';
import { responseWrapper } from '../helpers';
import apiClient from '../apiClient';

export function useEditPatient(
  options?: UseMutationOptions<PatientForms, Error, PatientForms>
) {
  const {
    mutate: editPatient,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<PatientForms, Error, PatientForms>({
    mutationFn: (payload: PatientForms) =>
      responseWrapper(apiClient.updatePatient, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    editPatient,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
