import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../../apiClient';
import { ApiResponseType, responseWrapper } from '../../helpers';
import { API_QUERIES } from '../../keys';
import { toData } from './helpers';
import { NutritionalAssessmentFormType } from './types';

export function useGetNutritionalAssessmentForm(
  options?: UseQueryOptions<
    ApiResponseType<{ nutritionalAssessmentForm: NutritionalAssessmentFormType }>,
    Error,
    NutritionalAssessmentFormType
  > & {
    id: NutritionalAssessmentFormType['id'];
  }
) {
  const {
    data: nutritionalAssessmentForm,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetNutritionalAssessmentFormById,
  } = useQuery<
    ApiResponseType<{ nutritionalAssessmentForm: NutritionalAssessmentFormType }>,
    Error,
    NutritionalAssessmentFormType
  >([API_QUERIES.NUTRITIONAL_ASSESSMENT_FORM, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<
        ApiResponseType<{ nutritionalAssessmentForm: NutritionalAssessmentFormType }>
      >(apiClient.getNutritionalAssessmentForm, params);
    },
    select: (data) => toData(data?.data?.nutritionalAssessmentForm),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateNutritionalAssessmentForm = () =>
    queryClient.invalidateQueries([API_QUERIES.NUTRITIONAL_ASSESSMENT_FORM, { id: options.id }]);

  return {
    nutritionalAssessmentForm,
    error,
    isError,
    isLoading,
    onGetNutritionalAssessmentFormById,
    handleInvalidateNutritionalAssessmentForm,
  };
}
