import { Accept } from 'react-dropzone';

export const ONE_HOUR = 60 * 60 * 1000;

export const COMMON_TYPE: Accept = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  // 'image/webp': ['.webp'],
  'application/pdf': ['.pdf'],
};

export const muiResponsive = {
  MOBILE: '(max-width:600px)',
  TABLET: '(max-width:960px)',
  LARGE_SCREEN: '(max-width:1200px)',
  EXTRA_LARGE_SCREEN: '(max-width:1440px)',
};

export const COLOR_CODE = {
  PRIMARY: '#005FA9',
  PRIMARY_BLUR: '#DFEAFB',
  PRIMARY_DARK: '#1f1f1f', // primary 900
  PRIMARY_LIGHT: '#B9B9B9', // primary 300
  PRIMARY_00: '#F0F6FA',
  SECONDARY: '#0088CC',
  SUCCESS: '#53B483',
  SUCCESS_200: '#D9F9E6',
  SUCCESS_700: '#2F9461',
  WARNING: '#FBAC2B',
  BG_WARNING: '#FEF5F5',
  DANGER: '#CD3636',
  WHITE: '#fff',
  BACKGROUND: '#FFFFFF',
  INFO: '#388FE3',
  DISABLED: '#91979E',
  PRIMARY_900: '#1f1f1f',
  PRIMARY_800: '#1f1f1f',
  PRIMARY_700: '#333333',
  PRIMARY_600: '#525252',
  PRIMARY_500: '#787878',
  PRIMARY_400: '#999999',
  PRIMARY_300: '#b9b9b9',
  PRIMARY_200: '#d9d9d9',
  PRIMARY_100: '#ececec',
  PRIMARY_50: '#f8f8f8',
  PRIMARY_500_BASE: '#0060D1',
  DEFAULT_BORDER: '1px solid #ccc',
  DEFAULT_BORDER_NAVBAR: '1px solid #e1f0fa',
  GRAY_400: '#9EA5AD',
  GRAY_600: '#596066',
  GRAY_900: '#24292E',
  GRAY_700: '#454C52',
  GRAY_50: '#F8F8F9',
  GREY_100: '#EDEFF1',
  GREY_200: '#E5E7EA ',
  GRAY_800: '#383F45',
  GRAY_LIGHT: '#cccccc',
  GREY_600_LIGHT: '#F7F9FA',
  GRAY_600_DARK: '#F7F9FA',
  GRAY_100: '#F6F7F9',
  DEFAULT_ROW_ERROR_BORDER: '2px solid #DB0012',
  DEFAULT_TABLE_ERROR_BORDER: '3px solid #DB0012',
  RED: '#CD3636',
  RED_LIGHT: '#FEF2F2',
  GREY_SCALE: '#596066',
  GRAY_SCALE_500: '#676E76',
  DEFAULT_TABLE_BORDER: '#0060d1',
  NAVBAR: '#e1f0fa',
  HEADER_PRINT: 'rgba(0, 96, 209, 0.05)',
  ORANGE: '#FB8C55',
  ORANGE_LIGHT: '#FEF5F5',
  GREEN_LIGHT: '#EFFDF6',
  YELLOW_LIGHT: '#FEFAF5',
  BLUE_LIGHT: '#F0F7FC',
};

export enum BOOLEAN {
  true = 'true',
  false = 'false',
}

export const NO_OPENER = 'noopener noreferrer';

export const ACCEPT_FILE_TYPE: Accept = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/webp': ['.webp'],
  'application/pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
};

export const FIRST_TIME_LOGIN_EXP_DAYS = 7;
export const RESET_PASSWORD_EXP_HOUR = 1;
