import { isEmpty } from 'lodash';
import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import {
  ApiResponseType,
  PaginationResponseType,
  getResponseData,
  responseWrapper,
} from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { RefundRecordItemResponse } from './types';

export function useGetAllRefundListing(
  options?: UseQueryOptions<
    ApiResponseType<PaginationResponseType<RefundRecordItemResponse>>,
    Error,
    PaginationResponseType<RefundRecordItemResponse>
  >
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data: allRefundsListing,
    error,
    isFetching,
    refetch: onGetAllRefunds,
  } = useQuery<
    ApiResponseType<PaginationResponseType<RefundRecordItemResponse>>,
    Error,
    PaginationResponseType<RefundRecordItemResponse>
  >([API_QUERIES.REFUNDS, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [, ...params] = query.queryKey;

      return responseWrapper<ApiResponseType<PaginationResponseType<RefundRecordItemResponse>>>(
        apiClient.getRefundRecordsListing,
        params
      );
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    select: getResponseData,
    enabled: !isEmpty(params),
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateAllRefundsListing = () =>
    queryClient.invalidateQueries(API_QUERIES.REFUNDS);

  const { data: refundListing, hasNext, payloadSize, totalRecords } = allRefundsListing || {};

  return {
    refundListing,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isFetching,
    onGetAllRefunds,
    setParams,
    handleInvalidateAllRefundsListing,
  };
}
