import { ClinicalAbstractKey, PhysicianOrderKey, StandingOrderKey } from './keys';

export interface StandingOrder {
  [StandingOrderKey.ID]?: string;
  [StandingOrderKey.PatientCode]?: string;
  [StandingOrderKey.PatientChartCode]: string;
  [StandingOrderKey.NameOfPatient]?: string;
  [StandingOrderKey.AttendingNephrologist]?: string;
  [StandingOrderKey.HemodialysisAccess]?: string;
  [StandingOrderKey.Diagnosis]?: string;
  [StandingOrderKey.Modality]?: string;
  [StandingOrderKey.Duration]?: string;
  [StandingOrderKey.Frequency]?: string;
  [StandingOrderKey.DryWeight]?: string;
  [StandingOrderKey.Potassium]?: string;
  [StandingOrderKey.Calcium]?: string;
  [StandingOrderKey.Heparinization]?: string;
  [StandingOrderKey.Dialyzer]?: string;
  [StandingOrderKey.Temperature]?: string;
  [StandingOrderKey.UFProfile]?: string;
  [StandingOrderKey.Access]?: string;
  [StandingOrderKey.DocumentUrls]?: any[];
  [StandingOrderKey.DateTime]?: string;
  [StandingOrderKey.UpdatedBy]?: string;
  [StandingOrderKey.UpdatedAt]?: string;
  [StandingOrderKey.Date]: string;
  [StandingOrderKey.Physician]: string;
  [StandingOrderKey.MedicalTeam]: string;
}

export interface ClinicalAbstract {
  [ClinicalAbstractKey.PatientChartCode]: string;
  [ClinicalAbstractKey.PatientChartId]?: string;
  [ClinicalAbstractKey.Uuid]?: string;
  [ClinicalAbstractKey.Date]?: string;
  [ClinicalAbstractKey.Name]?: string;
  [ClinicalAbstractKey.Birthdate]?: string;
  [StandingOrderKey.Diagnosis]?: string;
  [ClinicalAbstractKey.OtherMedicalProblem]?: string;
  [ClinicalAbstractKey.DateStartedDialysis]?: string;
  [ClinicalAbstractKey.EDW]?: string;
  [ClinicalAbstractKey.Dialyzer]?: string;
  [ClinicalAbstractKey.Duration]?: string;
  [ClinicalAbstractKey.Frequency]?: string;
  [ClinicalAbstractKey.BloodFlow]?: string;
  [ClinicalAbstractKey.Heparinization]?: string;
  [ClinicalAbstractKey.DateTaken]?: string;
  [ClinicalAbstractKey.HbsAg]?: string;
  [ClinicalAbstractKey.AntiHBS]?: string;
  [ClinicalAbstractKey.AntiHCV]?: string;
  [ClinicalAbstractKey.Medications]?: string;
  [ClinicalAbstractKey.CECDD]?: string;
  [ClinicalAbstractKey.Lic]?: string;
  [ClinicalAbstractKey.CreateAt]?: string;
  [ClinicalAbstractKey.UpdatedBy]?: string;
  [ClinicalAbstractKey.UpdatedAt]?: string;
  [ClinicalAbstractKey.DocumentUrls]?: any[];
}

export enum HemodialysisAccessType {
  PermanentCatheter = '001',
  Temp = '002',
  PermanentAccess = '003',
}

export interface PhysicianOrder {
  [PhysicianOrderKey.Id]?: string;
  [PhysicianOrderKey.PatientId]?: string;
  [PhysicianOrderKey.PatientChartCode]: string;
  [PhysicianOrderKey.PatientName]?: string;
  [PhysicianOrderKey.Age]?: number;
  [PhysicianOrderKey.Gender]?: string;
  [PhysicianOrderKey.AttendingNephrologist]?: string;
  [PhysicianOrderKey.ProgressNotes]?: string;
  [PhysicianOrderKey.InterdisciplinaryOrders]?: string;
  [PhysicianOrderKey.PatientChartId]?: string;
  [PhysicianOrderKey.DocumentUrls]?: any[];
  [PhysicianOrderKey.PatientCode]?: string;
  [PhysicianOrderKey.Date]?: string;

  [PhysicianOrderKey.CreatedAt]?: string;
  [PhysicianOrderKey.UpdatedAt]?: string;
  [PhysicianOrderKey.UpdatedBy]?: string;
  [PhysicianOrderKey.CreatedBy]?: string;
}

export interface Document {
  name: string;
  size: number;
  url: string;
  type?: string;
}
