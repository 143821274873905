import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { MedicalAndPhysicalResponse } from './types';

export function useGetMedicalAndPhysicalDetail(
  options?: UseQueryOptions<
    ApiResponseType<MedicalAndPhysicalResponse>,
    Error,
    MedicalAndPhysicalResponse
  > & {
    uuid: string;
  }
) {
  const {
    data: medicalAndPhysicalDetail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetMedicalAndPhysicalById,
  } = useQuery<ApiResponseType<MedicalAndPhysicalResponse>, Error, MedicalAndPhysicalResponse>(
    [API_QUERIES.MEDICAL_AND_PHYSICAL_FORM, { uuid: options.uuid }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<MedicalAndPhysicalResponse>>(
          apiClient.getMedicalAndPhysicalDetail,
          params
        );
      },
      select: getResponseData,
      enabled: false,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateMedicalAndPhysicalDetail = () =>
    queryClient.invalidateQueries(API_QUERIES.MEDICAL_AND_PHYSICAL_FORM);

  return {
    medicalAndPhysicalDetail,
    error,
    isError,
    isLoading,
    onGetMedicalAndPhysicalById,
    handleInvalidateMedicalAndPhysicalDetail,
  };
}
