import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toData } from './helpers';
import { TreatmentSheet } from './types';

export function useGetTreatmentSheet(
  options?: UseQueryOptions<
    ApiResponseType<{ treatmentSheetForm: TreatmentSheet }>,
    Error,
    TreatmentSheet
  > & {
    id: TreatmentSheet['uuid'];
  }
) {
  const {
    data: treatmentSheetForm,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTreatmentSheetForm,
  } = useQuery<ApiResponseType<{ treatmentSheetForm: TreatmentSheet }>, Error, TreatmentSheet>(
    [API_QUERIES.TREATMENT_SHEET_FORM, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ treatmentSheetForm: TreatmentSheet }>>(
          apiClient.getTreatmentSheet,
          params
        );
      },
      select: (data) => toData(data.data.treatmentSheetForm),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTreatmentSheet = () =>
    queryClient.invalidateQueries([API_QUERIES.TREATMENT_SHEET_FORM, { id: options.id }]);

  return {
    treatmentSheetForm,
    error,
    isError,
    isLoading,
    onGetTreatmentSheetForm,
    handleInvalidateTreatmentSheet,
  };
}
