import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toData } from './helpers';
import { PatientEducationFormType } from './types';

export function useGetPatientEducationForm(
  options?: UseQueryOptions<
    ApiResponseType<{ patientEducationForm: PatientEducationFormType }>,
    Error,
    PatientEducationFormType
  > & {
    id: PatientEducationFormType['id'];
  }
) {
  const {
    data: patientEducationForm,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetPatientEducationFormById,
  } = useQuery<
    ApiResponseType<{ patientEducationForm: PatientEducationFormType }>,
    Error,
    PatientEducationFormType
  >([API_QUERIES.PATIENT_EDUCATION_FORM, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ patientEducationForm: PatientEducationFormType }>>(
        apiClient.getPatientEducationForm,
        params
      );
    },
    select: (data) => toData(data?.data?.patientEducationForm),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidatePatientEducationForm = () =>
    queryClient.invalidateQueries([API_QUERIES.PATIENT_EDUCATION_FORM, { id: options.id }]);

  return {
    patientEducationForm,
    error,
    isError,
    isLoading,
    onGetPatientEducationFormById,
    handleInvalidatePatientEducationForm,
  };
}
