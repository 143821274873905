import { FormAction } from 'src/redux/form/formSlice';

export const isEmptyValueCheckBox = ({
  keyValueCheckbox,
  formAction,
  keyName,
  setFieldValue,
}: {
  keyValueCheckbox;
  formAction: FormAction;
  keyName: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}) => {
  const isEmpty =
    [...keyValueCheckbox].every((item) => item === false) && formAction === FormAction.SAVE;
  if (isEmpty) {
    setFieldValue(keyName, null);
    return true;
  } else {
    setFieldValue(keyName, true);
    return false;
  }
};
