import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { useDebounce } from 'src/hooks';
import { TableParams } from 'src/redux/types';
import { ValueOptions } from '../PatientChartListing';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Package } from './types';

const search = {
  take: 10,
  skip: 0,
};

const transferPackageOptions = (products: Package[]) => {
  return products.map((item: Package) => ({
    label: item.name,
    value: item.id,
  }));
};

export function useGetPackageOptions(
  options?: UseInfiniteQueryOptions<PaginationResponseType<Package>, Error>
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const [params, setParams] = useState<TableParams>(null);
  const debounceSearch = useDebounce(inputSearch);

  const {
    data: packages,
    error,
    isError,
    isFetching,
    refetch: onGetPackages,
    fetchNextPage,
  } = useInfiniteQuery<PaginationResponseType<Package>, Error>(
    [API_QUERIES.PACKAGES, 'options', debounceSearch, { type: 'lazy' }],
    (props): Promise<PaginationResponseType<Package>> => {
      const { pageParam = search } = props;

      return responseWrapper<PaginationResponseType<Package>>(apiClient.getPackages, [
        { ...pageParam, ...params, search: inputSearch },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },

      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: !!params,
      ...options,
    }
  );
  const hasNext = useMemo(() => {
    if (isEmpty(packages?.pages)) return null;
    return true;
  }, [packages]);

  const packageOptions: ValueOptions[] = useMemo(() => {
    if (isEmpty(packages?.pages)) return [];
    return packages.pages.reduce(
      (state, page, _pageIdx) => [...state, ...transferPackageOptions(page.data)],
      []
    );
  }, [packages]);

  return {
    packageOptions,
    packages,
    error,
    hasNext,
    isError,
    loading: isFetching,
    setInputSearch,
    onGetPackages,
    fetchNextPage,
    setParams,
  };
}
