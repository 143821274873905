import { Stack } from '@mui/material';
import cn from 'classnames';
import React, { useRef } from 'react';
import {
  default as DatePicker,
  default as ReactDatePicker,
  ReactDatePickerProps,
} from 'react-datepicker';
import { IoCalendar } from 'react-icons/io5';
import { Callback } from 'src/redux/types';
import { getRandomId } from 'src/utils';
import { isEmpty } from 'src/validations';
import Element from '../Element';
import './styles.scss';

const DateSelector: React.FC<Props> = ({
  label,
  onChange,
  errorMessage,
  containerClassName,
  classNames,
  placeholder = 'MM/DD/YYYY',
  dateFormat = 'MM/dd/yyyy',
  required,
  name,
  onBlur,
  positionFixed = true,
  maxDate,
  infoTooltipMessage = '',
  value,
  ...props
}) => {
  const id = useRef<string>(`datepicker-${getRandomId()}`);
  const inputRef = useRef<ReactDatePicker>(null);

  // For more information:
  // https://reactdatepicker.com/
  const handleChange = (value: Date) => {
    onChange(name, value);
  };

  const handleBlur = () => {
    onBlur(name, true);
  };

  const hasError = !isEmpty(errorMessage);
  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={cn('cmp-datepicker', containerClassName)}
      required={required}
    >
      <Stack position="relative">
        <DatePicker
          id={id.current}
          ref={inputRef}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholderText={placeholder}
          className={cn(
            'cmp-datepicker__input',
            { 'cmp-datepicker__input--error': hasError },
            classNames
          )}
          showPopperArrow={false}
          isClearable={true}
          dateFormat={dateFormat}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          popperProps={{
            positionFixed: positionFixed,
          }}
          selected={value ? new Date(value) : undefined}
          portalId="root"
          {...props}
        />
        <i className="cmp-datepicker--calendar-icon">
          <IoCalendar
            onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
              e.stopPropagation();
              inputRef.current?.setOpen(true);
            }}
          />
        </i>
      </Stack>
    </Element>
  );
};

type BaseDatePickerProps = Pick<
  ReactDatePickerProps,
  Exclude<keyof ReactDatePickerProps, 'onChange' | 'onBlur'>
>;

type Props = BaseDatePickerProps & {
  errorMessage?: string;
  containerClassName?: string;
  classNames?: string;
  placeholder?: string;
  mask?: string;
  label?: string | React.ReactNode;
  onChange: Callback;
  onBlur?: Callback;
  hasDifferentValue?: boolean;
  positionFixed?: boolean;
  maxDate?: Date;
  infoTooltipMessage?: string;
};

export default DateSelector;
