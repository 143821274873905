import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { PatientEducationFormType } from './types';

export function useEditPatientEducationForm(
  options?: UseMutationOptions<PatientEducationFormType, Error, PatientEducationFormType>
) {
  const {
    mutate: editPatientEducationForm,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<PatientEducationFormType, Error, PatientEducationFormType>({
    mutationFn: (payload: PatientEducationFormType) =>
      responseWrapper(apiClient.updatePatientEducationForm, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    editPatientEducationForm,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
