import { NurseKey } from './keys';

export interface NursesList {
  [NurseKey.Id]?: string;
  [NurseKey.Prefix]?: string;
  [NurseKey.Address]?: string;
  [NurseKey.Suffix]?: string;
  [NurseKey.Sex]?: string;
  [NurseKey.FirstName]?: string;
  [NurseKey.MiddleName]?: string;
  [NurseKey.LastName]?: string;
  [NurseKey.EmergencyContactPhone]?: string;
  [NurseKey.BirthDate]?: Date;
  [NurseKey.Email]?: string;
  [NurseKey.Phone]?: string;
  [NurseKey.PrcID]?: string;
  [NurseKey.Status]?: string;
}

export enum NurseStatus {
  All = 0,
  Active = 1,
  Inactive = 2,
}

export interface NursesItemValue {
  uuid: string;
  originalNum: number;
  nurseCode: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  phone: string;
  birthDate: Date;
  address: string;
  email: string;
  emergencyContactPhone: string;
  sex: string;
  status: number;
  prcId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: null;
  updatedBy: null;
}
