/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable security/detect-object-injection */
import { Box } from '@mui/material';
import { Formik } from 'formik';
import { Location } from 'history';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useMemo, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import CustomLoadingDetailView from 'src/components/CustomLoadingDetailView';
import EmptyTable from 'src/components/EmptyTable';
import FormAlert from 'src/components/FormAlert';
import { Accordion, Button, Grid, Table, Text, View } from 'src/components/common';
import { HISTORY_ACTION } from 'src/containers/PatientChartManagement/AddNewPatientChart/enum';
import { PATIENT_CHART_FORM_NAME } from 'src/containers/shared';
import {
  ReUseDialyzersFormKey,
  ReUseDialyzersFormType,
  useCreateReUseDialyzersForm,
  useEditReUseDialyzersForm,
  useGetAllPatientChartListing,
  useGetPatientChartById,
  useGetReUseDialyzersForm,
  useGetReUseDialyzersHistories,
  useGetReUseDialyzersHistory,
} from 'src/queries';
import { hideAllDialog, hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { FormAction, setFormAction } from 'src/redux/form/formSlice';
import { IRootState } from 'src/redux/store';
import { TableParams } from 'src/redux/types';
import { Navigator, Toastify } from 'src/services';
import Prompt from 'src/services/Prompt';
import { formatDate } from 'src/utils';
import { TAB_NAME_VALUE } from '../../../enum';
import FormHeader from '../../FormHeader';
import { allColumnVersionHistory } from '../../shared';
import ReUseDialyzers from './ReUseDialyzers';
import { ReUseDialyzersFormikProps, toDataReUseDialyzersForm, toInitialValues } from './helpers';
import { reUseDialyzersValidateSchema } from './validateSchema';

const ReUseDialyzersForm: React.FC<Props> = ({ formAction, onSetFormAction }) => {
  const { toggleSidebar } = useProSidebar();
  const location = useLocation();
  const { id, uuid, formCode, historyId } = useParams<{
    id: string;
    uuid: string;
    formCode: string;
    historyId: string;
  }>();

  const isTreatmentHistoryForm = useMemo(
    () => location.pathname.includes('treatment-history'),
    [location]
  );

  const history = useHistory();

  const isHistoryView = !!historyId;
  const isEditReUseDialyzers = !!uuid;
  const isEditView = !!uuid && !isHistoryView;
  const [isResetForm, setIsResetForm] = useState(false);

  const { patientChartDetail } = useGetPatientChartById({ id });

  const { reUseDialyzersForm, handleInvalidateReUseDialyzersForm } = useGetReUseDialyzersForm(
    !historyId && { id: uuid }
  );

  useEffect(() => {
    if (isTreatmentHistoryForm) {
      toggleSidebar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTreatmentHistoryForm]);

  const { reUseDialyzersHistory } = useGetReUseDialyzersHistory({ id: historyId });

  const { handleInvalidateAllPatientChartListing } = useGetAllPatientChartListing();

  const {
    reUseDialyzersHistories,
    isLoading,
    setParams,
    handleInvalidateReUseDialyzersHistories,
    totalRecords,
  } = useGetReUseDialyzersHistories(
    !historyId && {
      id: uuid,
    }
  );

  const handleGetReUseDialyzersHistories = (params: TableParams) => {
    setParams(params);
  };

  const { createReUseDialyzersForm, isLoading: isLoadingCreate } = useCreateReUseDialyzersForm({
    onSuccess: (data) => {
      Toastify.success('Re-Use Dialyzers form created successfully.');
      handleInvalidateAllPatientChartListing();
      handleInvalidateReUseDialyzersForm();
      if (isTreatmentHistoryForm) {
        const href = PATHS.treatmentHistoryPatientChartDetail
          .replace(':id', id)
          .replace(':formCode', formCode)
          .replace(':uuid', data?.reUseOfDialyzersForm.uuid);
        Navigator.replace(href);
      } else {
        Navigator.navigate(
          PATHS.patientChartDetailForm
            .replace(':id', id)
            .replace(':formCode', PATIENT_CHART_FORM_NAME.RE_USE_OF_DIALYZERS)
            .replace(':uuid', data?.reUseOfDialyzersForm?.uuid)
        );
      }
    },
  });

  const { editReUseDialyzersForm, isUpdating } = useEditReUseDialyzersForm({
    onSuccess: () => {
      Toastify.success('Re-Use Dialyzers form updated successfully.');
      handleInvalidateReUseDialyzersHistories();
      handleInvalidateAllPatientChartListing();
      handleInvalidateReUseDialyzersForm();
    },
  });

  const handleSubmit = (values: ReUseDialyzersFormType) => {
    if (uuid) {
      editReUseDialyzersForm(toDataReUseDialyzersForm({ ...values, uuid }));
    } else {
      createReUseDialyzersForm(toDataReUseDialyzersForm({ ...values, patientChartId: id }));
    }
  };

  const initialValues = useMemo(() => {
    const patientChartCode = patientChartDetail?.patientChartCode;
    return historyId
      ? toInitialValues({
          ...reUseDialyzersHistory,
          [ReUseDialyzersFormKey.PatientChartCode]: patientChartCode,
          [ReUseDialyzersFormKey.FirstName]: patientChartDetail?.patient?.firstName,
          [ReUseDialyzersFormKey.MiddleName]: patientChartDetail?.patient?.middleName,
          [ReUseDialyzersFormKey.LastName]: patientChartDetail?.patient?.lastName,
        })
      : toInitialValues(
          {
            ...reUseDialyzersForm,
            [ReUseDialyzersFormKey.PatientChartCode]: patientChartCode,
            [ReUseDialyzersFormKey.FirstName]: patientChartDetail?.patient?.firstName,
            [ReUseDialyzersFormKey.MiddleName]: patientChartDetail?.patient?.middleName,
            [ReUseDialyzersFormKey.LastName]: patientChartDetail?.patient?.lastName,
          },
          isResetForm
        );
  }, [reUseDialyzersForm, historyId, reUseDialyzersHistory, patientChartDetail, isResetForm]);

  const tableOptions: MUIDataTableOptions = {
    count: totalRecords,
    filter: false,
    searchOpen: false,
    search: false,
  };

  const columns = useMemo(
    () =>
      allColumnVersionHistory({
        id: id,
        uuid: uuid,
        formCode: formCode as PATIENT_CHART_FORM_NAME,
        isTreatmentHistoryForm: isTreatmentHistoryForm,
      }),
    [id, uuid, formCode, isTreatmentHistoryForm]
  );

  // navigate to patient chart list when click button back
  // in case create success
  useEffect(() => {
    return history.listen(() => {
      if (history.action === HISTORY_ACTION.POP && isEditReUseDialyzers) {
        Navigator.navigate(`${PATHS.patientChart}/${id}?tab=${TAB_NAME_VALUE.INFORMED_CONSENT}`);
      }
    });
  }, [history, isEditReUseDialyzers]);

  const updatedAt = reUseDialyzersHistory
    ? formatDate(reUseDialyzersHistory?.updatedAt || reUseDialyzersHistory?.createdAt)
    : formatDate(reUseDialyzersForm?.updatedAt || reUseDialyzersForm?.createdAt);

  const updatedBy = reUseDialyzersHistory
    ? reUseDialyzersHistory?.updatedBy || reUseDialyzersHistory?.createdBy
    : reUseDialyzersForm?.updatedBy || reUseDialyzersForm?.createdBy;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={reUseDialyzersValidateSchema}
    >
      {({
        errors,
        touched,
        getFieldProps,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        values,
        dirty,
        validateForm,
        handleReset,
        resetForm,
      }) => {
        const getErrorMessage = (fieldName: ReUseDialyzersFormKey) => {
          return touched[fieldName] && errors[fieldName] ? (errors[fieldName] as string) : '';
        };

        const formikProps: ReUseDialyzersFormikProps = {
          getFieldProps,
          values,
          setFieldTouched,
          setFieldValue,
          errors,
          touched,
          getErrorMessage,
          dirty,
          handleSubmit,
          validateForm,
          handleReset,
        };

        const handleAddNew = () => {
          setIsResetForm(true);
          resetForm();
        };

        const blockCondition = (location: Location<string>) => {
          if (formAction === FormAction.DISCARD || isHistoryView) {
            return false;
          }

          const sideBarPaths = [
            PATHS.patient,
            PATHS.appointments,
            PATHS.inventory,
            PATHS.nurses,
            PATHS.attendingNephrologist,
          ];

          const isJumpToAnotherPath = sideBarPaths.some((path) => location.pathname.includes(path));

          if ((isEditReUseDialyzers || dirty) && isJumpToAnotherPath && !isTreatmentHistoryForm) {
            return true;
          }

          const isTabsPath = location.search.includes('tab');
          if (isTabsPath && dirty) {
            return true;
          }

          const acceptJumpPaths = [
            PATHS.patientChartDetailForm,
            PATHS.patientChartFormHistoryDetails,
            PATHS.treatmentHistoryPatientChartDetail,
            PATHS.treatmentHistoryPatientChartHistoryDetail,
          ];

          const isAcceptJumpPaths = acceptJumpPaths.some((path) =>
            location.pathname.includes(path)
          );

          if (isAcceptJumpPaths) {
            return false;
          }
        };

        return (
          <Prompt
            title="Leave without saving"
            message="Changes will not be saved. Are you sure to leave?"
            cancelOkText="Leave"
            cancelText="Cancel"
            condition={blockCondition}
            preventShowDialog={isTreatmentHistoryForm && formAction !== FormAction.DISCARD && dirty}
          >
            <Box>
              <FormHeader formikProps={formikProps} isSubmitting={isLoadingCreate || isUpdating} />
              <CustomLoadingDetailView isLoading={isLoading}>
                <View className="px-120 py-40">
                  <View renderIf={!!uuid}>
                    <FormAlert>
                      <Text>
                        Last Updated: {updatedAt} {updatedBy}
                      </Text>
                      {isEditView && <Button onClick={handleAddNew}>Add New</Button>}
                    </FormAlert>
                  </View>
                  <ReUseDialyzers isHistoryView={isHistoryView} formikProps={formikProps} />
                  <Grid.Item
                    variant="is-full"
                    renderIf={!!uuid && !isHistoryView}
                    className="px-0 py-0"
                  >
                    <Accordion title="Version History">
                      <Table
                        onAction={handleGetReUseDialyzersHistories}
                        isLoading={isLoading}
                        data={reUseDialyzersHistories}
                        tableOptions={tableOptions}
                        columns={columns}
                        defaultSortOrder={{
                          name: 'createdAt',
                          direction: 'desc',
                        }}
                        emptyComponent={<EmptyTable />}
                      />
                    </Accordion>
                  </Grid.Item>
                </View>
              </CustomLoadingDetailView>
            </Box>
          </Prompt>
        );
      }}
    </Formik>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};

const mapStateToProps = (state: IRootState) => ({
  formAction: state.form.formAction,
});

const mapDispatchToProps = {
  onShowDialog: showDialog,
  onHideDialog: hideDialog,
  onHideAllDialog: hideAllDialog,
  onSetFormAction: setFormAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReUseDialyzersForm);
