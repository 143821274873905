import { Callback } from 'src/redux/types';

export enum FileUploadType {
  avatar = 'avatars',
  SIGNATURES = 'signatures',
  PHOTOS = 'photos',
  CLAIM_ISSUE_DOCUMENT = 'CLAIM_ISSUE_DOCUMENT',
  Photos = 'photos',
  Signatures = 'signatures',
}

export interface GetPresignedPayload {
  fileName?: string;
  contentType?: string;
  fileData?: File;
  type?: FileUploadType | null;
  callback?: Callback;
  fullPath?: string;
  keepOriginalQuality?: boolean;
  keyId?: string;
  url?: string;
}
export interface GetMultiPresignedPayload {
  files: GetPresignedPayload[];
  callback?: Callback;
}

export type AttachmentPayload = {
  fileUrl: string;
  name: string;
};

export type UploadFilePayload = {
  url: string;
  fileData: File;
  contentType?: string;
  setProgress?: Callback;
};

export type GetPresignedDownloadPayload = {
  filePath: string;
};

export type GetPresignedDownloadResponse = {
  url: string;
};

export enum PollStatus {
  _REQUESTING = 'Requesting...',
  _GENERATING = 'Exporting...',
  _DOWNLOADING = 'Downloading...',
}

export interface PollOptions {
  fileName: string;
  fileType: string;
  path?: string;
  get: string;
  head: string;
  getMethod?: boolean;
}

export type PollSettings = {
  maxAttempts: number; // retry download in max x seconds
  delayOrDelayCallback?: number | (() => number); // retry per y second
  getMethod?: boolean;
};

export interface PollAndDownloadType extends PollOptions {
  head: string;
  get: string;
  settings?: PollSettings;
}
