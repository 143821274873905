import { NutritionalAssessmentFormKey } from './keys';

export const toData = (data) => {
  return {
    ...data,
    [NutritionalAssessmentFormKey.UploadDocument]: data?.[
      NutritionalAssessmentFormKey.UploadDocument
    ].map((item) => JSON.parse(item)),
    [NutritionalAssessmentFormKey.ProgressNotesAssessment]: data?.[
      NutritionalAssessmentFormKey.ProgressNotesAssessment
    ].map((item) => JSON.parse(item)),
  };
};
