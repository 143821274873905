import { get, isEmpty, isEqual, isNil } from 'lodash';
import { ErrorService, Toastify } from 'src/services';
import { deepKeys, scrollToTopError } from '.';

export const isEqualPrevAndNextObjByPath = <T>({
  prevValues,
  nextValues,
  path,
  checkEqualLengthArray,
}: {
  prevValues: T;
  nextValues: T;
  path: string;
  checkEqualLengthArray?: boolean;
}) => {
  const prev = get(prevValues, path);
  const next = get(nextValues, path);
  return checkEqualLengthArray && Array.isArray(prev) && Array.isArray(next)
    ? prev.length === next.length
    : isEqual(prev, next);
};

export const handleShowErrorMsg = (error: Error, prefix: string = '') => {
  Toastify.error(`${!isEmpty(prefix) ? `${prefix}: ` : ''}${getErrorMessageFromResponse(error)}`);
};

export const handleScrollToTopError = <T>(errors: T) => {
  return setTimeout(() => {
    scrollToTopError(deepKeys(errors));
  }, 100);
};

export const getErrorMessageFromResponse = (error: Error) => {
  let errorMessage = ErrorService.MESSAGES.unknown;
  if (!isEmpty(error)) {
    if (typeof error?.message === 'string') {
      errorMessage = error?.message;
    } else {
      errorMessage = error?.message[0];
    }
  }

  return errorMessage;
};

export const isString = (value: any): value is String => {
  return typeof value === 'string';
};

export const getOptionLabel = (options: { label: string; value: any }[], value: any) => {
  return options.find((option) => option.value === value)?.label || '';
};

export const convertNumberOrNull = (value: string | number) => {
  if (isNil(value)) return null;

  if (isString(value) && !value) return null;

  return Number(value || 0);
};
