import Tag, { TagVariant } from 'src/components/common/Tag';
import { getStartCase } from 'src/utils';

export enum Status {
  // Add more status here
  // BLUE
  NEW = 'NEW',

  // GREEN
  ACTIVE = 'ACTIVE',
  CONFIRMED = 'CONFIRMED',

  // RED
  INACTIVE = 'INACTIVE',
  WAITING = 'WAITING',
  // YELLOW
  PENDING = 'PENDING',

  // GRAY
  NO_SHOW = 'NO-SHOW',
}

const StatusTag: React.FC<Props> = ({ status }) => {
  const getVariant = (status: Status): TagVariant => {
    switch (status) {
      case Status.NEW:
        return 'is-primary';
      case Status.ACTIVE:
        return 'is-success';
      case Status.INACTIVE:
        return 'is-danger';
      case Status.PENDING:
        return 'is-warning';
      case Status.CONFIRMED:
        return 'is-success';
      case Status.WAITING:
        return 'is-danger';
      case Status.NO_SHOW:
        return 'is-light';
      default:
        return 'is-customize';
    }
  };
  return (
    <Tag isLight variant={getVariant(status as Status)}>
      {getStartCase(status)}
    </Tag>
  );
};
type Props = { status: string };

export default StatusTag;
