import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../../apiClient';
import { ApiResponseType, responseWrapper } from '../../helpers';
import { API_QUERIES } from '../../keys';
import { toData } from './helpers';
import { ReUseDialyzersFormType } from './types';

export function useGetReUseDialyzersForm(
  options?: UseQueryOptions<
    ApiResponseType<{ reUseOfDialyzersForm: ReUseDialyzersFormType }>,
    Error,
    ReUseDialyzersFormType
  > & {
    id: ReUseDialyzersFormType['id'];
  }
) {
  const {
    data: reUseDialyzersForm,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetReUseDialyzersFormById,
  } = useQuery<
    ApiResponseType<{ reUseOfDialyzersForm: ReUseDialyzersFormType }>,
    Error,
    ReUseDialyzersFormType
  >([API_QUERIES.REUSE_DIALYZERS_FORM, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ reUseOfDialyzersForm: ReUseDialyzersFormType }>>(
        apiClient.getReUseDialyzersForm,
        params
      );
    },
    select: (data) => toData(data?.data?.reUseOfDialyzersForm),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateReUseDialyzersForm = () =>
    queryClient.invalidateQueries([API_QUERIES.REUSE_DIALYZERS_FORM, { id: options.id }]);

  return {
    reUseDialyzersForm,
    error,
    isError,
    isLoading,
    onGetReUseDialyzersFormById,
    handleInvalidateReUseDialyzersForm,
  };
}
