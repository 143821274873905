import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../../apiClient';
import { responseWrapper } from '../../helpers';
import { NutritionalAssessmentFormType } from './types';

export function useEditNutritionalAssessmentForm(
  options?: UseMutationOptions<NutritionalAssessmentFormType, Error, NutritionalAssessmentFormType>
) {
  const {
    mutate: editNutritionalAssessmentForm,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<NutritionalAssessmentFormType, Error, NutritionalAssessmentFormType>({
    mutationFn: (payload: NutritionalAssessmentFormType) =>
      responseWrapper(apiClient.updateNutritionalAssessmentForm, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    editNutritionalAssessmentForm,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
