export enum StandingOrderKey {
  ID = 'uuid',
  PatientCode = 'patientCode',
  PatientChartCode = 'patientChartCode',
  NameOfPatient = 'patientName',
  AttendingNephrologist = 'attendingNephrologist',
  HemodialysisAccess = 'hemodialysisAccess',
  Diagnosis = 'diagnosis',
  Modality = 'modality',
  Duration = 'duration',
  Frequency = 'frequency',
  DryWeight = 'dryWeight',
  Potassium = 'potassium',
  Calcium = 'calcium',
  Heparinization = 'heparinization',
  Dialyzer = 'dialyzer',
  Temperature = 'temperature',
  UFProfile = 'ufProfile',
  Access = 'access',
  DocumentUrls = 'documentUrls',
  DateTime = 'createdAt',
  UpdatedBy = 'updatedBy',
  UpdatedAt = 'updatedAt',
  Date = 'date',
  Physician = 'physician',
  MedicalTeam = 'medicalTeam',
}

export enum ClinicalAbstractKey {
  Uuid = 'uuid',
  PatientCode = 'patientCode',
  PatientChartCode = 'patientChartCode',
  PatientChartId = 'patientChartId',
  Date = 'date',
  Name = 'patientName',
  Birthdate = 'birthDate',
  Diagnosis = 'diagnosis',
  OtherMedicalProblem = 'otherMedicalProblems',
  DateStartedDialysis = 'dateStartedDialysis',
  EDW = 'edw',
  Dialyzer = 'dialyzer',
  Duration = 'duration',
  Frequency = 'frequency',
  BloodFlow = 'bloodFlow',
  Heparinization = 'heparinization',
  DateTaken = 'takenDate',
  HbsAg = 'hbsAg',
  AntiHBS = 'antiHBS',
  AntiHCV = 'antiHCV',
  Medications = 'medications',
  CECDD = 'commonlyEncountered',
  Lic = 'headNephrologist',
  DocumentUrls = 'documentUrls',
  CreateAt = 'createdAt',
  UpdatedBy = 'updatedBy',
  UpdatedAt = 'updatedAt',
}

export enum PhysicianOrderKey {
  Id = 'uuid',
  PatientName = 'patientName',
  PatientChartCode = 'patientChartCode',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  PatientId = 'patientId',
  Age = 'age',
  Gender = 'sex',
  AttendingNephrologist = 'attendingNephrologist',
  ProgressNotes = 'progressNote',
  InterdisciplinaryOrders = 'interdisciplinaryOrder',
  PatientChartId = 'patientChartId',
  DocumentUrls = 'documentUrls',
  PatientCode = 'patientCode',
  Date = 'date',

  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  CreatedBy = 'createdBy',
}
