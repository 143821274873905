import { ProblemListKey } from './keys';
import { ProblemList } from './types';

export const toProblemList = (data: ProblemList) => {
  return {
    ...data,
    [ProblemListKey.DocumentUrls]: data?.documentUrls?.map((res) => JSON.parse(res)),
    [ProblemListKey.ProblemList]: data?.problemListInfo?.map((res) => JSON.parse(res)),
  };
};
