import { TextareaAutosize } from 'src/components/common';
import { BodyBasicRows } from 'src/components/common/CustomTable/types';
import { TableAddRowInterface } from './types';

export const allColumns = (handleChange, data) => (): BodyBasicRows => {
  return data.map((lineItemRow: TableAddRowInterface, index) => {
    return {
      columns: [
        {
          label: 'Name',
          content: (
            <TextareaAutosize
              resize="none"
              onChange={(e) => handleChange(lineItemRow.id, 'name', e.target.value)} //on change the id, the key to edit, and value
              value={lineItemRow.name}
            />
          ),
          headerStyle: {
            width: '222px',
          },
          style: {
            padding: '20px',
          },
        },
        {
          label: 'Address',
          headerStyle: {
            width: '200px',
          },
          content: (
            <TextareaAutosize
              resize="none"
              onChange={(e) => handleChange(lineItemRow.id, 'address', e.target.value)}
              value={lineItemRow.address}
            />
          ),
        },
      ],
    };
  });
};
