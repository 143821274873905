import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { RefundRequestFormValue, RefundRequestFormValueRes } from './types';

export function useUpdateRefundRequest(
  options?: UseMutationOptions<
    ApiResponseType<RefundRequestFormValueRes>,
    Error,
    RefundRequestFormValue
  >
) {
  const {
    mutate: updateRefundRequestForm,
    data,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<RefundRequestFormValueRes>, Error, RefundRequestFormValue>({
    mutationFn: (payload: RefundRequestFormValue) =>
      responseWrapper(apiClient.editRequestForm, [payload]),
    ...options,
  });

  return {
    updateRefundRequestForm,
    data,
    isLoading,
    isSuccess,
  };
}
