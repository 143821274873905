import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'src/hooks';
import { TableParams } from 'src/redux/types';
import { getFullName } from 'src/utils';
import { isEmpty } from 'src/validations';
import { AttendingNephrologistType } from '.';
import { SelectOption } from '../Contents';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';

const search = {
  take: 10,
  skip: 0,
};

const transferPatientsOptions = (patientItem: AttendingNephrologistType[]) => {
  return patientItem.map((item) => ({
    label: getFullName({
      firstName: item?.firstName,
      lastName: item?.lastName,
      middleName: item?.middleName,
    }),
    value: item?.uuid,
  }));
};

export function useGetAttendingNephrologistOption(
  options?: UseInfiniteQueryOptions<PaginationResponseType<AttendingNephrologistType>, Error>
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const [params, setParams] = useState<TableParams>(null);
  const debounceSearch = useDebounce(inputSearch);

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getNoneEligibleOptions,
    fetchNextPage,
  } = useInfiniteQuery<PaginationResponseType<AttendingNephrologistType>, Error>(
    [API_QUERIES.ATTENDING_NEPHROLOGIST_OPTIONS, 'options', debounceSearch, { type: 'lazy' }],
    (props): Promise<PaginationResponseType<AttendingNephrologistType>> => {
      const { pageParam = search } = props;

      return responseWrapper<PaginationResponseType<AttendingNephrologistType>>(
        apiClient.getAllAttendingNephrologists,
        [{ ...pageParam, ...params, search: inputSearch }]
      );
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: !!params,
      ...options,
    }
  );

  const nephrologistOption: SelectOption[] = useMemo(() => {
    if (isEmpty(data?.pages)) return [];
    return data.pages.reduce(
      (state, page, _pageIdx) => [...state, ...transferPatientsOptions(page.data)],
      []
    );
  }, [data]);

  const hasNext = useMemo(() => {
    if (isEmpty(data?.pages)) return null;
    return true;
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidatePatients = () =>
    queryClient.invalidateQueries(API_QUERIES.ATTENDING_NEPHROLOGIST_OPTIONS);

  return {
    nephrologistOption,
    error,
    hasNext,
    isError,
    loading: isFetching,
    setInputSearch,
    getNoneEligibleOptions,
    fetchNextPage,
    setParams,
    handleInvalidatePatients,
  };
}
