import { useCallback, useState, useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Button, Text, View } from 'src/components/common';
import CustomTable from 'src/components/common/CustomTable';
import { allColumns } from './allColumns';
import { v4 as uuid } from 'uuid';
import { TableAddRowInterface } from './types';
import { toNewData, toSubmitData } from './helpers';

const TableAddRow: React.FC<Props> = ({ data, onChange, fieldName }) => {
  const [dataState, setDataState] = useState([]);

  useEffect(() => {
    if (data.length) {
      setDataState(toNewData(data)); // format data from props, add id field
    }
  }, [data]);

  // TODO: Do not follow this (Tuyen Tran)
  const handleChange = (id, name, value) => {
    const newData = [...dataState];
    const changingIndex = newData.findIndex((item) => item.id === id);
    const changingItem = newData.find((item) => item.id === id);
    changingItem[name as string] = value;
    newData.splice(changingIndex, 1, changingItem);
    setDataState(newData);
    onChange(fieldName, toSubmitData(newData)); // convert data to array without id
    //onChange name + value to use with formik
    //props will be like the input or other component: onChange={setFieldValue}
  };

  const handleAddRow = () => {
    const isEmptyLastItem = //check if user didn't change any field in the last row
      !dataState.at(dataState.length - 1).name && !dataState.at(dataState.length - 1).address;
    if (!isEmptyLastItem) {
      // avoid add multiple empty data rows
      setDataState((prev) => [...prev, { id: uuid(), name: '', address: '' }]); // uuid to create random ID
    }
  };

  const columns = useCallback(
    () => allColumns(handleChange, dataState)(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataState]
  );

  return (
    <>
      <CustomTable.Basic bodyList={columns()} />
      <View isRow className="mt-8">
        <Button variant="text" onClick={handleAddRow}>
          <View isRow align="center">
            <AiOutlinePlus className="mr-8 color-primary-base-500" size={20} />
            <Text className="color-primary-base-500">Add Row</Text>
          </View>
        </Button>
      </View>
    </>
  );
};
type Props = {
  data: TableAddRowInterface[];
  onChange: Function;
  fieldName?: string;
};
export default TableAddRow;
