export enum CONTINUOUS_FLOW_SHEET_FORM_KEY_VALUE {
  NAME_OF_PATIENT = 'nameOfPatient',
  PATIENT_CHART_ID = 'patientCode',
  ATTENDING_NEPHROLOGIST = 'attendingNephrologist',
  DIAGNOSIS = 'diagnosis',
  HEMODIALYSIS_ACCESS = 'hemodialysisAccess',

  // for form
  FILTER_BY_DATE = 'filterByDate',
  //
  START_DATE = 'startDate',
  END_DATE = 'endDate',

  CONTINUOUS_FLOW_SHEET_LIST = 'continuousFlowSheetList',

  SignatureDate = 'signatureDate',
  MedicalTeam = 'medicalTeam',
  DocumentUrls = 'documentUrls',
}

export enum CONTINUOUS_FLOW_SHEETS_ITEM_KEY {
  DATE = 'date',
  // Weight
  WEIGHT_EDW = 'weightEdw',
  WEIGHT_PRE = 'weightPre',
  WEIGHT_POST = 'weightPost',
  WEIGHT_VAR = 'weightVar',

  // Blood
  BLOOD_PRE = 'bloodPre',
  BLOOD_POST = 'bloodPost',

  HGB = 'hgb',
  KT_V = 'ktv',
  REMARKS = 'remarks',
}
