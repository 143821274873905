import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { isEmpty } from 'src/validations';
import { ContinuousFlowSheetResponse } from './types';

export function useGetContinuousFlowSheet(
  options?: UseQueryOptions<
    ApiResponseType<ContinuousFlowSheetResponse>,
    Error,
    ContinuousFlowSheetResponse
  > & {
    uuid: string;
  }
) {
  const [dateFilter, setParams] = useState<{ startDate: string; endDate: string }>(null);

  const {
    data: continuousFlowSheetDetail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetContinuousFlowSheetById,
  } = useQuery<ApiResponseType<ContinuousFlowSheetResponse>, Error, ContinuousFlowSheetResponse>(
    [API_QUERIES.CONTINUOUS_FLOW_SHEET, { uuid: options.uuid, dateFilter }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<ContinuousFlowSheetResponse>>(
          apiClient.getContinuousFlowSheetDetail,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      select: getResponseData,
      enabled: !isEmpty(dateFilter),
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateContinuousFlowSheetDetail = () =>
    queryClient.invalidateQueries([
      API_QUERIES.CONTINUOUS_FLOW_SHEET,
      { uuid: options.uuid, dateFilter },
    ]);

  return {
    continuousFlowSheetDetail,
    error,
    isError,
    isLoading,
    setParams,
    onGetContinuousFlowSheetById,
    handleInvalidateContinuousFlowSheetDetail,
  };
}
