import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { AddNewAppointmentFormValue, AppointmentFormValueResponse } from './types';

export function useCreateAppointment(
  options?: UseMutationOptions<
    ApiResponseType<AppointmentFormValueResponse>,
    Error,
    AddNewAppointmentFormValue
  >
) {
  const {
    mutate: createAppointmentForm,
    data,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<AppointmentFormValueResponse>, Error, AddNewAppointmentFormValue>(
    {
      mutationFn: (payload: AddNewAppointmentFormValue) =>
        responseWrapper(apiClient.createAppointmentForm, [payload]),
      ...options,
    }
  );

  return {
    createAppointmentForm,
    data,
    isLoading,
    isSuccess,
  };
}
