import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { ProblemList } from './types';

export function useEditProblemList(options?: UseMutationOptions<ProblemList, Error, ProblemList>) {
  const {
    mutate: editProblemList,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<ProblemList, Error, ProblemList>({
    mutationFn: (payload: ProblemList) => responseWrapper(apiClient.editProblemList, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editProblemList,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
