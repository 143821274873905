export enum NewRefundRequestKeys {
  PATIENT_ID = 'patientId',
  OFFICIAL_RECEIPT_NUMBER = 'officialReceiptNumber',
  ID = 'id',
  SEQUENCE_NUMBER = 'sequenceNumber',
  REQUEST_FORM = 'requestForm',
  LAST_UPDATED = 'lastUpdated',
  LAST_MODIFIED_BY = 'lastModifiedBy',
  INCIDENT_REPORT = 'incidentReport',
}
