/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, AutocompleteProps } from '@mui/material';
import { Callback } from 'src/redux/types';
import { emptyFunction } from 'src/utils';
import { LazyCheckPoint, MuiInput } from '..';

const getSelectedOptions = (value: string, options: SelectOption[], fallbackOption: SelectOption) =>
  value ? options.find((i) => i.value === value) ?? fallbackOption : null;

const MuiAutoCompleteCmp: React.FC<MuiAutoCompleteProps> = ({
  options,
  label,
  errorMessage = '',
  required = false,
  isLoading = false,
  variant = 'outlined',
  onChange = emptyFunction,
  value,
  infoToolTipWithArrow,
  infoTooltipMessage,
  infoTooltipPlacement,
  name,
  popupIcon,
  isGetOptionOnChange = false,
  onFetchNextPage,
  allowLazyLoad,
  placeholder,
  sx,
  fallbackOption = null,
  disabled,
  ...props
}) => {
  const handleChange = (_: unknown, value: SelectOption) =>
    onChange(name, isGetOptionOnChange ? value : value?.value ?? null);

  const selectedOption = getSelectedOptions(value, options, fallbackOption);

  return (
    <Autocomplete
      onChange={handleChange}
      disabled={disabled}
      options={options}
      renderInput={({ ...params }) => (
        <MuiInput
          {...params}
          {...{
            label,
            required,
            errorMessage,
            infoToolTipWithArrow,
            infoTooltipMessage,
            infoTooltipPlacement,
            name,
            placeholder,
            variant,
          }}
        />
      )}
      loading={isLoading}
      sx={{
        ...sx,
        pointerEvents: disabled ? 'none' : 'unset',
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderWidth: 1,
          },
          '&.Mui-disabled': {
            pointerEvents: 'none',
          },
        },
        '& .MuiPaper-root': {
          display: disabled ? 'none' : 'block',
        },
        '& .MuiAutocomplete-endAdornment': {
          button: {
            transform: popupIcon ? 'none' : undefined,
          },
        },
      }}
      ListboxProps={{
        style: {
          backgroundColor: 'white',
        },
      }}
      isOptionEqualToValue={({ value }, { value: _value }) => value === _value}
      value={selectedOption}
      popupIcon={popupIcon}
      renderOption={(props, option) => {
        const label = (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
        if (allowLazyLoad && options.at(options.length - 1)?.value === option?.value) {
          return (
            <LazyCheckPoint onFirstEnter={onFetchNextPage} key={`check-point-${option.value}`}>
              {label}
            </LazyCheckPoint>
          );
        }

        return label;
      }}
      filterOptions={(options, _state) => options}
      // selectOnFocus
      {...props}
    />
  );
};

type BaseInputProps = Pick<
  AutocompleteProps<any, boolean | undefined, boolean | undefined, undefined>,
  Exclude<
    keyof AutocompleteProps<any, boolean | undefined, boolean | undefined, undefined>,
    'label' | 'renderInput' | 'options' | 'value' | 'multiple'
  >
>;
export interface SelectOption {
  label?: string | React.ReactNode;
  value: any;
  prefix?: string | React.ReactNode;
  subLabel?: string | React.ReactNode;
  key?: any;
}
export type MuiAutoCompleteProps = Omit<BaseInputProps, 'onBlur' | 'onChange'> & {
  options?: SelectOption[];
  label?: string | React.ReactNode;
  className?: string;
  value?: any;
  errorMessage?: string;
  placeholder?: string;
  containerClassName?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  variant?: 'filled' | 'standard' | 'outlined';
  isLoading?: boolean;
  isClickable?: boolean;
  onChange?: Callback;
  onBlur?: Callback;
  onInputChange?: Callback;
  infoTooltipMessage?: string;
  infoTooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  infoToolTipWithArrow?: boolean;
  isGetOptionOnChange?: boolean;
  allowLazyLoad?: boolean;
  onFetchNextPage?: Callback;
  fallbackOption?: SelectOption;
};

export default MuiAutoCompleteCmp;
