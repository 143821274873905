import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toPhysicianOrder } from './helpers';
import { PhysicianOrder } from './types';

export function useGetPhysicianOrderHistory(
  options?: UseQueryOptions<
    ApiResponseType<{ physicianOrderHistory: PhysicianOrder }>,
    Error,
    PhysicianOrder
  > & {
    id: PhysicianOrder['uuid'];
  }
) {
  const {
    data: physicianOrderHistory,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetPhysicianOrderHistory,
  } = useQuery<ApiResponseType<{ physicianOrderHistory: PhysicianOrder }>, Error, PhysicianOrder>(
    [API_QUERIES.PHYSICIAN_ORDER_FORMS, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ physicianOrderHistory: PhysicianOrder }>>(
          apiClient.getPhysicianOrderHistory,
          params
        );
      },
      select: ({ data }) => toPhysicianOrder(data.physicianOrderHistory),
      enabled: !!options.id,
      ...options,
    }
  );

  return {
    physicianOrderHistory,
    error,
    isError,
    isLoading,
    onGetPhysicianOrderHistory,
  };
}
