import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { PATHS } from 'src/appConfig/paths';
import { PATIENT_CHART_FORM_NAME } from 'src/containers/shared';
import { PatientChartItem } from 'src/queries';
import { TreatmentRecordPatients } from 'src/queries/TreatmentHistory/PatientChart';
import { Navigator } from 'src/services';
import { TAB_NAME_VALUE } from './enum';

export const tabsOption = [
  {
    label: 'Patient Data Form',
    value: 'patient-data-form',
  },
  {
    label: 'Informed Consent',
    value: 'informed-consent',
  },
  {
    label: 'Dialysis Prescription',
    value: 'dialysis-prescription',
  },
  {
    label: 'Hemodialysis Monitoring',
    value: 'hemodialysis-monitoring',
  },
  {
    label: 'Medication Record',
    value: 'medication-record',
  },
  {
    label: 'Laboratory Flow',
    value: 'laboratory-flow',
  },
  {
    label: 'Progress Note',
    value: 'progress-note',
  },
];

export const handleNavigateBackToTab = ({
  id,
  formCode,
}: {
  id: string;
  formCode: PATIENT_CHART_FORM_NAME;
}) => {
  switch (formCode) {
    case PATIENT_CHART_FORM_NAME.GENERAL_INFORMATION:
    case PATIENT_CHART_FORM_NAME.MEDICAL_HISTORY_AND_PHYSICAL_EXAMINATION:
      return Navigator.navigate(
        `${PATHS.patientChart}/${id}?tab=${TAB_NAME_VALUE.PATIENT_DATA_FORM}`
      );
    case PATIENT_CHART_FORM_NAME.HEMODIALYSIS_TREATMENT:
    case PATIENT_CHART_FORM_NAME.RE_USE_OF_DIALYZERS:
    case PATIENT_CHART_FORM_NAME.ADVANCE_DIRECTIVES:
      return Navigator.navigate(
        `${PATHS.patientChart}/${id}?tab=${TAB_NAME_VALUE.INFORMED_CONSENT}`
      );
    case PATIENT_CHART_FORM_NAME.STANDING_ORDER:
    case PATIENT_CHART_FORM_NAME.PHYSICIAN_ORDER:
    case PATIENT_CHART_FORM_NAME.CLINICAL_ABSTRACT:
      return Navigator.navigate(
        `${PATHS.patientChart}/${id}?tab=${TAB_NAME_VALUE.DIALYSIS_PRESCRIPTION}`
      );
    case PATIENT_CHART_FORM_NAME.TREATMENT_SHEET:
    case PATIENT_CHART_FORM_NAME.CONTINUOUS_FLOW_SHEET:
      return Navigator.navigate(
        `${PATHS.patientChart}/${id}?tab=${TAB_NAME_VALUE.HEMODIALYSIS_MONITORING}`
      );
    case PATIENT_CHART_FORM_NAME.MEDICATION_RECORD:
      return Navigator.navigate(
        `${PATHS.patientChart}/${id}?tab=${TAB_NAME_VALUE.MEDICATION_RECORD}`
      );
    case PATIENT_CHART_FORM_NAME.MONTHLY_LABORATORY_RESULT:
    case PATIENT_CHART_FORM_NAME.CXR_RESULT:
    case PATIENT_CHART_FORM_NAME.HEPATITIS_SCREENING_RESULTS:
    case PATIENT_CHART_FORM_NAME.VACCINATION_RECORD:
      return Navigator.navigate(
        `${PATHS.patientChart}/${id}?tab=${TAB_NAME_VALUE.LABORATORY_FLOW}`
      );
    case PATIENT_CHART_FORM_NAME.PROBLEM_LIST:
    case PATIENT_CHART_FORM_NAME.NUTRITIONAL_ASSESSMENT:
    case PATIENT_CHART_FORM_NAME.PATIENT_EDUCATION:
      return Navigator.navigate(`${PATHS.patientChart}/${id}?tab=${TAB_NAME_VALUE.PROGRESS_NOTE}`);
    default:
      return null;
  }
};

export const transferTabName = ({
  patientChartDetail,
  label,
}: {
  patientChartDetail: PatientChartItem;
  label: string;
}) => {
  dayjs.extend(relativeTime);
  const {
    medicalHistoryAndPhysicalExamination,
    physicianOrderForm,
    hemodialysisTreatment,
    patientGeneralInfo,
    reUseOfDialyzersForm,
    medicationRecordForm,
    advanceDirectiveForm,
    treatmentSheetForm,
    clinicalAbstractForm,
    standingOrderForm,
    monthlyLaboratoryResultForm,
    cxrResultForm,
    problemListForm,
    continuousFlowSheetForm,
    hepatitisScreeningResultForm,
    nutritionalAssessmentForm,
    vaccinationRecord,
    patientEducationForm,
  } = patientChartDetail || {};

  switch (label) {
    case TAB_NAME_VALUE.PATIENT_DATA_FORM:
      return [
        {
          label: 'General Information',
          href: PATIENT_CHART_FORM_NAME.GENERAL_INFORMATION,
          uuid: patientGeneralInfo?.uuid ?? '',
          lastUpdateAt:
            patientGeneralInfo?.updatedAt || patientGeneralInfo?.createdAt
              ? dayjs().to(dayjs(patientGeneralInfo?.updatedAt || patientGeneralInfo?.createdAt))
              : '--',
        },
        {
          label: 'Medical History and Physical Examination Form',
          href: PATIENT_CHART_FORM_NAME.MEDICAL_HISTORY_AND_PHYSICAL_EXAMINATION,
          uuid: medicalHistoryAndPhysicalExamination?.uuid ?? '',
          lastUpdateAt:
            medicalHistoryAndPhysicalExamination?.updatedAt ||
            medicalHistoryAndPhysicalExamination?.createdAt
              ? dayjs().to(
                  dayjs(
                    medicalHistoryAndPhysicalExamination?.updatedAt ||
                      medicalHistoryAndPhysicalExamination?.createdAt
                  )
                )
              : '--',
        },
      ];
    case TAB_NAME_VALUE.INFORMED_CONSENT:
      return [
        {
          label: 'Hemodialysis Treatment',
          href: PATIENT_CHART_FORM_NAME.HEMODIALYSIS_TREATMENT,
          uuid: hemodialysisTreatment?.uuid ?? '',
          lastUpdateAt:
            hemodialysisTreatment?.updatedAt || hemodialysisTreatment?.createdAt
              ? dayjs().to(
                  dayjs(hemodialysisTreatment?.updatedAt || hemodialysisTreatment?.createdAt)
                )
              : '--',
        },
        {
          label: 'Re- Use of Dialyzers',
          href: PATIENT_CHART_FORM_NAME.RE_USE_OF_DIALYZERS,
          uuid: reUseOfDialyzersForm?.uuid ?? '',
          lastUpdateAt:
            reUseOfDialyzersForm?.updatedAt || reUseOfDialyzersForm?.createdAt
              ? dayjs().to(
                  dayjs(reUseOfDialyzersForm?.updatedAt || reUseOfDialyzersForm?.createdAt)
                )
              : '--',
        },
        {
          label: 'Advance Directives (Released of Responsibility)',
          href: PATIENT_CHART_FORM_NAME.ADVANCE_DIRECTIVES,
          uuid: advanceDirectiveForm?.uuid ?? '',
          lastUpdateAt:
            advanceDirectiveForm?.updatedAt || advanceDirectiveForm?.createdAt
              ? dayjs().to(
                  dayjs(advanceDirectiveForm?.updatedAt || advanceDirectiveForm?.createdAt)
                )
              : '--',
        },
      ];
    case TAB_NAME_VALUE.DIALYSIS_PRESCRIPTION:
      return [
        {
          label: 'Standing Order',
          href: PATIENT_CHART_FORM_NAME.STANDING_ORDER,
          uuid: standingOrderForm?.uuid ?? '',
          lastUpdateAt:
            standingOrderForm?.updatedAt || standingOrderForm?.createdAt
              ? dayjs().to(dayjs(standingOrderForm?.updatedAt || standingOrderForm?.createdAt))
              : '--',
        },
        {
          label: 'Physician Order',
          href: PATIENT_CHART_FORM_NAME.PHYSICIAN_ORDER,
          uuid: physicianOrderForm?.uuid ?? '',
          lastUpdateAt:
            physicianOrderForm?.updatedAt || physicianOrderForm?.createdAt
              ? dayjs().to(dayjs(physicianOrderForm?.updatedAt || physicianOrderForm?.createdAt))
              : '--',
        },
        {
          label: 'Clinical Abstract',
          href: PATIENT_CHART_FORM_NAME.CLINICAL_ABSTRACT,
          uuid: clinicalAbstractForm?.uuid ?? '',
          lastUpdateAt:
            clinicalAbstractForm?.updatedAt || clinicalAbstractForm?.createdAt
              ? dayjs().to(
                  dayjs(clinicalAbstractForm?.updatedAt || clinicalAbstractForm?.createdAt)
                )
              : '--',
        },
      ];
    case TAB_NAME_VALUE.HEMODIALYSIS_MONITORING:
      return [
        {
          label: 'Treatment Sheet',
          href: PATIENT_CHART_FORM_NAME.TREATMENT_SHEET,
          uuid: treatmentSheetForm?.uuid ?? '',
          lastUpdateAt:
            treatmentSheetForm?.updatedAt || treatmentSheetForm?.createdAt
              ? dayjs().to(dayjs(treatmentSheetForm?.updatedAt || treatmentSheetForm?.createdAt))
              : '--',
        },
        {
          label: 'Continuous Flow Sheet',
          href: PATIENT_CHART_FORM_NAME.CONTINUOUS_FLOW_SHEET,
          uuid: continuousFlowSheetForm?.uuid ?? '',
          lastUpdateAt:
            continuousFlowSheetForm?.updatedAt || continuousFlowSheetForm?.createdAt
              ? dayjs().to(
                  dayjs(continuousFlowSheetForm?.updatedAt || continuousFlowSheetForm?.createdAt)
                )
              : '--',
        },
      ];
    case TAB_NAME_VALUE.MEDICATION_RECORD:
      return [
        {
          label: 'Medication Record',
          href: PATIENT_CHART_FORM_NAME.MEDICATION_RECORD,
          uuid: medicationRecordForm?.uuid ?? '',
          lastUpdateAt:
            medicationRecordForm?.updatedAt || medicationRecordForm?.createdAt
              ? dayjs().to(
                  dayjs(medicationRecordForm?.updatedAt || medicationRecordForm?.createdAt)
                )
              : '--',
        },
      ];
    case TAB_NAME_VALUE.LABORATORY_FLOW:
      return [
        {
          label: 'Monthly Laboratory Result',
          href: PATIENT_CHART_FORM_NAME.MONTHLY_LABORATORY_RESULT,
          uuid: monthlyLaboratoryResultForm?.uuid ?? '',
          lastUpdateAt:
            monthlyLaboratoryResultForm?.updatedAt || monthlyLaboratoryResultForm?.createdAt
              ? dayjs().to(
                  dayjs(
                    monthlyLaboratoryResultForm?.updatedAt || monthlyLaboratoryResultForm?.createdAt
                  )
                )
              : '--',
        },
        {
          label: 'CXR Result',
          href: PATIENT_CHART_FORM_NAME.CXR_RESULT,
          uuid: cxrResultForm?.uuid ?? '',
          lastUpdateAt:
            cxrResultForm?.updatedAt || cxrResultForm?.createdAt
              ? dayjs().to(dayjs(cxrResultForm?.updatedAt || cxrResultForm?.createdAt))
              : '--',
        },
        {
          label: 'Hepatitis Screening Results',
          href: PATIENT_CHART_FORM_NAME.HEPATITIS_SCREENING_RESULTS,
          uuid: hepatitisScreeningResultForm?.uuid ?? '',
          lastUpdateAt:
            hepatitisScreeningResultForm?.updatedAt || hepatitisScreeningResultForm?.createdAt
              ? dayjs().to(
                  dayjs(
                    hepatitisScreeningResultForm?.updatedAt ||
                      hepatitisScreeningResultForm?.createdAt
                  )
                )
              : '--',
        },
        {
          label: 'Vaccination Record',
          href: PATIENT_CHART_FORM_NAME.VACCINATION_RECORD,
          uuid: vaccinationRecord?.uuid,
          lastUpdateAt:
            vaccinationRecord?.updatedAt || vaccinationRecord?.createdAt
              ? dayjs().to(dayjs(vaccinationRecord?.updatedAt || vaccinationRecord?.createdAt))
              : '--',
        },
      ];

    case TAB_NAME_VALUE.PROGRESS_NOTE:
      return [
        {
          label: 'Problem List',
          href: PATIENT_CHART_FORM_NAME.PROBLEM_LIST,
          uuid: problemListForm?.uuid ?? '',
          lastUpdateAt:
            problemListForm?.updatedAt || problemListForm?.createdAt
              ? dayjs().to(dayjs(problemListForm?.updatedAt || problemListForm?.createdAt))
              : '--',
        },
        {
          label: 'Nutritional Assessment',
          href: PATIENT_CHART_FORM_NAME.NUTRITIONAL_ASSESSMENT,
          uuid: nutritionalAssessmentForm?.uuid ?? '',
          lastUpdateAt:
            nutritionalAssessmentForm?.updatedAt || nutritionalAssessmentForm?.createdAt
              ? dayjs().to(
                  dayjs(
                    nutritionalAssessmentForm?.updatedAt || nutritionalAssessmentForm?.createdAt
                  )
                )
              : '--',
        },
        {
          label: 'Patient Education',
          href: PATIENT_CHART_FORM_NAME.PATIENT_EDUCATION,
          uuid: patientEducationForm?.uuid ?? '',
          lastUpdateAt:
            patientEducationForm?.updatedAt || patientEducationForm?.createdAt
              ? dayjs().to(
                  dayjs(patientEducationForm?.updatedAt || patientEducationForm?.createdAt)
                )
              : '--',
        },
      ];
  }
};

export const getNameFormValue = (value: PATIENT_CHART_FORM_NAME | TreatmentRecordPatients) => {
  if (!value) return '';
  switch (value) {
    case PATIENT_CHART_FORM_NAME.GENERAL_INFORMATION:
    case TreatmentRecordPatients.PATIENT_GENERAL_INFO:
      return 'General Information';
    case PATIENT_CHART_FORM_NAME.MEDICAL_HISTORY_AND_PHYSICAL_EXAMINATION:
    case TreatmentRecordPatients.MEDICAL_HISTORY_AND_PHYSICAL_EXAMINATION:
      return 'Medical History and Physical Examination Form';
    case PATIENT_CHART_FORM_NAME.HEMODIALYSIS_TREATMENT:
    case TreatmentRecordPatients.HEMODIALYSIS_TREATMENT:
      return 'Hemodialysis Treatment';
    case PATIENT_CHART_FORM_NAME.RE_USE_OF_DIALYZERS:
    case TreatmentRecordPatients.RE_USE_OF_DIALYZERS_FORM:
      return 'Re- Use of Dialyzers';
    case PATIENT_CHART_FORM_NAME.ADVANCE_DIRECTIVES:
    case TreatmentRecordPatients.ADVANCE_DIRECTIVE_FORM:
      return 'Advance Directives (Released of Responsibility)';
    case PATIENT_CHART_FORM_NAME.STANDING_ORDER:
    case TreatmentRecordPatients.STANDING_ORDER_FORM:
      return 'Standing Order';
    case PATIENT_CHART_FORM_NAME.PHYSICIAN_ORDER:
    case TreatmentRecordPatients.PHYSICIAN_ORDER_FORM:
      return 'Physician Order';
    case PATIENT_CHART_FORM_NAME.CLINICAL_ABSTRACT:
    case TreatmentRecordPatients.CLINICAL_ABSTRACT_FORM:
      return 'Clinical Abstract';
    case PATIENT_CHART_FORM_NAME.TREATMENT_SHEET:
    case TreatmentRecordPatients.TREATMENT_SHEET_FORM:
      return 'Treatment Sheet';
    case PATIENT_CHART_FORM_NAME.CONTINUOUS_FLOW_SHEET:
    case TreatmentRecordPatients.CONTINUOUS_FLOW_SHEET_FORM:
      return 'Continuous Flow Sheet';
    case PATIENT_CHART_FORM_NAME.MONTHLY_LABORATORY_RESULT:
    case TreatmentRecordPatients.MONTHLY_LABORATORY_RESULT_FORM:
      return 'Monthly Laboratory Result';
    case PATIENT_CHART_FORM_NAME.CXR_RESULT:
    case TreatmentRecordPatients.CXR_RESULT_FORM:
      return 'CXR Result';
    case PATIENT_CHART_FORM_NAME.HEPATITIS_SCREENING_RESULTS:
    case TreatmentRecordPatients.HEPATITIS_SCREENING_RESULT_FORM:
      return 'Hepatitis Screening Results';
    case PATIENT_CHART_FORM_NAME.VACCINATION_RECORD:
    case TreatmentRecordPatients.VACCINATION_RECORD:
      return 'Vaccination Record';
    case PATIENT_CHART_FORM_NAME.PROBLEM_LIST:
    case TreatmentRecordPatients.PROBLEM_LIST_FORM:
      return 'Problem List';
    case PATIENT_CHART_FORM_NAME.NUTRITIONAL_ASSESSMENT:
    case TreatmentRecordPatients.NUTRITIONAL_ASSESSMENT_FORM:
      return 'Nutritional Assessment';
    case PATIENT_CHART_FORM_NAME.PATIENT_EDUCATION:
    case TreatmentRecordPatients.PATIENT_EDUCATION_FORM:
      return 'Patient Education';
    case PATIENT_CHART_FORM_NAME.MEDICATION_RECORD:
    case TreatmentRecordPatients.MEDICATION_RECORD_FORM:
      return 'Medication Record';
    default:
      return '';
  }
};
