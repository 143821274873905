import { isEmpty } from 'lodash';
import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { TableParams } from 'src/redux/types';
import apiClient from '../../apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from '../../helpers';
import { API_QUERIES } from '../../keys';
import { WaitList } from './types';

export function useGetWaitlists(
  options?: UseQueryOptions<
    ApiResponseType<PaginationResponseType<WaitList>>,
    Error,
    PaginationResponseType<WaitList>
  >
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data,
    error,
    isFetching,
    refetch: onGetWaitLists,
  } = useQuery<
    ApiResponseType<PaginationResponseType<WaitList>>,
    Error,
    PaginationResponseType<WaitList>
  >([API_QUERIES.WAIT_LIST, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<PaginationResponseType<WaitList>>>(
        apiClient.getWaitLists,
        params
      );
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    enabled: !isEmpty(params),
    ...options,
  });

  const queryClient = useQueryClient();
  const handleInvalidateWaitlists = () => queryClient.invalidateQueries(API_QUERIES.WAIT_LIST);
  const { data: waitLists, hasNext, payloadSize, totalRecords } = data || {};

  return {
    waitLists,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isFetching,
    onGetWaitLists,
    setParams,
    handleInvalidateWaitlists,
  };
}
