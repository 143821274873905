export enum PatientRefundRequestFormKeyValue {
  REFUND_ID = 'refundId',
  DOCUMENT_CODE = 'documentCode',
  EFFECTIVE_DATE = 'effectiveDate',
  REV_NO = 'revNo',
  CONTACT_NO = 'contactNo',
  EMAIL = 'email',
  REFUND_REQUEST_TYPE = 'refundRequestType',
  OTHERS = 'others',
  REFUND_AMOUNT = 'refundAmount',
  ORIGINAL_PAYMENT_DATE = 'originalPaymentDate',
  PAYMENT_METHOD = 'paymentMethod',
  BANK_NAME = 'bankName',
  ACCOUNT_HOLDER_NAME = 'accountHolderName',
  ACCOUNT_NAME = 'accountName',
  RECEIPT_NUMBER = 'receiptNumber',
  DESCRIPTION = 'description',
  REQUESTOR_NAME = 'requestorName',
  RELATIONSHIP_TO_PATIENT = 'relationshipToPatient',
  REQUESTOR_CONTACT_NUMBER = 'requestorContactNumber',
  REQUESTOR_EMAIL = 'requestorEmail',
  IS_CONFIRMED_POLICY = 'isConfirmedPolicy',
  STATUS = 'status',
  SUPPORTING_DOCUMENTS = 'supportingDocuments',
  SIGNATURE_DOCUMENTS = 'signatureDocuments',

  PATIENT_NAME = 'patientName',
  PATIENT_ID = 'patientId',
  ADDRESS = 'address',
}
