import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { NutritionalAssessmentFormType } from './types';

export function useGetNutritionalAssessmentHistories(
  options?: UseQueryOptions<PaginationResponseType<NutritionalAssessmentFormType>, Error> & {
    id: NutritionalAssessmentFormType['uuid'];
  }
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetNutritionalAssessmentHistories,
  } = useQuery<PaginationResponseType<NutritionalAssessmentFormType>, Error>(
    [API_QUERIES.NUTRITIONAL_ASSESSMENT_HISTORIES, { ...params, id: options.id }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        return responseWrapper<PaginationResponseType<NutritionalAssessmentFormType>>(
          apiClient.getNutritionalAssessmentHistories,
          [params]
        );
      },
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateNutritionalAssessmentHistories = () =>
    queryClient.invalidateQueries([
      API_QUERIES.NUTRITIONAL_ASSESSMENT_HISTORIES,
      { id: options.id },
    ]);

  const { data: nutritionalAssessmentHistories, hasNext, payloadSize, totalRecords } = data || {};

  return {
    nutritionalAssessmentHistories,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isLoading,
    setParams,
    onGetNutritionalAssessmentHistories,
    handleInvalidateNutritionalAssessmentHistories,
  };
}
