import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { MedicationRecord, MedicationRecordPayload } from './types';

export function useEditMedicationRecord(
  options?: UseMutationOptions<MedicationRecord, Error, MedicationRecordPayload>
) {
  const {
    mutate: editMedicationRecord,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<MedicationRecord, Error, MedicationRecordPayload>({
    mutationFn: (payload: MedicationRecordPayload) =>
      responseWrapper(apiClient.editMedicationRecord, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editMedicationRecord,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
