import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import cn from 'classnames';
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableProps,
  MUIDataTableState,
} from 'mui-datatables';
import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { COLOR_CODE } from 'src/appConfig/constants';
import { Loading, View } from 'src/components/common';
import { IRootState } from 'src/redux/rootReducer';
import CustomFooterRender from './customFooterRender';
import CustomSearchRender from './customSearchRender';
import './styles.scss';

const TableBasic: React.FC<Props> = ({
  isLoading,
  containerClassName,
  onTableChange,
  currentPage,
  options,
  total,
  data,
  emptyComponent = 'No data',
  addRowButton,
  recordName,
  locationSearch,
  ...props
}) => {
  const tableOptions: MUIDataTableOptions = {
    serverSide: true,
    searchOpen: false,
    search: true,
    download: false,
    filter: true,
    print: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: isLoading ? <Loading variant="primary" loadingStyle={1} /> : emptyComponent,
      },
    },
    jumpToPage: false,
    rowHover: true,
    onTableChange,
    customSearchRender: (searchText: string, handleSearch: (_text: string) => void) => (
      <CustomSearchRender
        searchText={searchText}
        onSearch={handleSearch}
        placeholder={options.searchPlaceholder}
      />
    ),
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (_page: string | number) => void,
      changePage: (_newPage: number) => void
    ) => (
      <CustomFooterRender
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
        theme={getMuiTheme()}
        addRowButton={addRowButton}
        recordName={recordName}
      />
    ),

    ...options,
  };

  const hasRowClickAction = !!options?.onRowClick;
  const getMuiTheme = () =>
    createTheme({
      primary: {
        main: COLOR_CODE.PRIMARY,
        dark: COLOR_CODE.PRIMARY_DARK,
        light: COLOR_CODE.PRIMARY_LIGHT,
      },
      secondary: {
        main: COLOR_CODE.SECONDARY,
      },
      typography: {
        fontFamily: ['Roboto flex'].join(','),
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {},
            elevation4: {
              boxShadow: 'none',
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              paddingLeft: '4px !important',
              paddingRight: '0px !important',
              marginTop: 24,
            },
          },
        },
        MUIDataTableToolbarSelect: {
          styleOverrides: {
            root: {
              display: 'none',
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            hover: {
              cursor: hasRowClickAction ? 'pointer' : 'default',
              backgroundColor: COLOR_CODE.PRIMARY,
            },
            // root: {
            //   '&:nth-of-type(even)': {
            //     backgroundColor: COLOR_CODE.GRAY_50,
            //   },
            // },
            footer: {
              padding: '100px 0',
              borderBottom: '0px !important',
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: '12px 16px',
            },
            body: {
              cursor: hasRowClickAction ? 'pointer' : 'default',
            },
            head: {
              '&.MuiTableCell-root': {
                backgroundColor: COLOR_CODE.GRAY_50,
                color: COLOR_CODE.GRAY_600,
                fontWeight: 450,
                padding: '0 16px',
                borderBottom: 'none !important',
              },
              '&.MuiTableCell-root span button': {
                color: COLOR_CODE.GRAY_600,
                fontWeight: 450,
                padding: '12px 16px',
                textTransform: 'unset',
              },
              '&.MuiTableCell-root span button div, &.MuiTableCell-root span button div span svg': {
                color: `${COLOR_CODE.GRAY_600} !important`,
                fontWeight: 450,
                textTransform: 'unset',
              },
            },
          },
        },
        MuiTableBody: {
          styleOverrides: {
            root: {
              opacity: isLoading ? 0.3 : 1,
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            root: {
              borderBottom: 'unset',
              backgroundColor: 'unset',
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              // Hide Chip filter
              '&.MuiChip-filled.MuiChip-colorDefault.MuiChip-deletable': {
                display: 'none !important',
              },
            },
          },
        },
      },
    } as ThemeOptions);

  const muiDataTable = useMemo(
    () => {
      return <MUIDataTable data={data} options={tableOptions} {...props} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isLoading, locationSearch]
  );

  // More info: https://github.com/gregnb/mui-datatables
  return (
    <View className={cn('cmp-table-basic', containerClassName)} flexGrow={1}>
      <ThemeProvider theme={getMuiTheme()}>{muiDataTable}</ThemeProvider>
    </View>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  MUIDataTableProps & {
    containerClassName?: string;
    currentPage?: number;
    total?: number;
    onTableChange: (action: string, tableState: MUIDataTableState) => void;
    isLoading?: boolean;
    emptyComponent?: React.ReactNode;
    locationSearch: string;
    addRowButton?: React.ReactElement;
    recordName?: string;
    data?: any;
  };

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(memo(TableBasic));
