import { CXRResultKey, MonthlyLaboratoryResultKey } from './keys';
import { CXRResult, MonthlyLaboratoryResult } from './types';

export const toMonthlyLaboratoryResult = (data: MonthlyLaboratoryResult) => {
  return {
    ...data,
    [MonthlyLaboratoryResultKey.DocumentUrls]: data?.documentUrls?.map((res) => JSON.parse(res)),
    [MonthlyLaboratoryResultKey.Test]: data?.testsList?.map((res) => JSON.parse(res)),
  };
};

export const toCXRResult = (data: CXRResult) => {
  return {
    ...data,
    [CXRResultKey.DocumentUrls]: data?.documentUrls?.map((res) => JSON.parse(res)),
    [CXRResultKey.XRayReport]: data?.xRayReport?.map((res) => JSON.parse(res)),
  };
};
