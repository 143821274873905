/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from '@mui/material';
import React from 'react';
import { MdDownload } from 'react-icons/md';
import { connect } from 'react-redux';
import { COLOR_CODE } from 'src/appConfig/constants';
import useDownloadFile from 'src/hooks/useDownloadFile';
import { useGetPresignedDownloadUrl } from 'src/queries';
import { showLightBox } from 'src/redux/lightbox/lightboxSlice';
import { ErrorService } from 'src/services';
import Button from '../Button';
import LoadingCommon from '../LoadingCommon';

const RemoteDocumentPreview: React.FC<Props> = ({ name, url, onShowLightBox, loading }) => {
  const download = useDownloadFile();

  const { isLoading, onGetDecodeUrl } = useGetPresignedDownloadUrl({
    onDecodeUrlChange(decodedUrl: string, params) {
      download(decodedUrl, name, params.isPrint);
    },
    onError(error) {
      ErrorService.handler(error);
    },
  });

  const handleOpenFile = (url: string, name: string) => () => {
    onShowLightBox({ name, url });
  };

  const isDisabled = isLoading || loading || !url;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack flexGrow={1} mr={4} direction="row" alignItems="center">
        <Button
          variant="link-primary"
          style={{ height: 20, width: 'fit-content', padding: 0, marginRight: 8 }}
          onClick={handleOpenFile(url, name)}
          disabled={isDisabled}
        >
          {/* {getFileName(name)} */}
          {name}
        </Button>
        {loading && <LoadingCommon />}
      </Stack>
      <Stack direction="row" alignItems="center">
        <i>
          <MdDownload
            fontSize={20}
            color={COLOR_CODE.GREY_600_LIGHT}
            onClick={() => onGetDecodeUrl({ filePath: url })}
            style={{
              cursor: isDisabled ? 'progress' : 'pointer',
              pointerEvents: isDisabled ? 'none' : 'unset',
            }}
          />
        </i>
      </Stack>
    </Stack>
  );
};

type Props = typeof mapDispatchToProps & {
  url: string;
  name: string;
  loading?: boolean;
};

const mapDispatchToProps = {
  onShowLightBox: showLightBox,
};

export default connect(undefined, mapDispatchToProps)(RemoteDocumentPreview);
