import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { isEmpty } from 'src/validations';
import { VaccinationRecordFormValueDetailResponse } from './types';

export function useGetVaccinationRecordHistories(
  options?: UseQueryOptions<
    ApiResponseType<PaginationResponseType<VaccinationRecordFormValueDetailResponse>>,
    Error,
    PaginationResponseType<VaccinationRecordFormValueDetailResponse>
  > & {
    uuid: string;
  }
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data: vaccinationHistories,
    error,
    isFetching,
  } = useQuery<
    ApiResponseType<PaginationResponseType<VaccinationRecordFormValueDetailResponse>>,
    Error,
    PaginationResponseType<VaccinationRecordFormValueDetailResponse>
  >([API_QUERIES.VACCINATION_RECORD_HISTORIES, { ...params, uuid: options.uuid }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<
        ApiResponseType<PaginationResponseType<VaccinationRecordFormValueDetailResponse>>
      >(apiClient.getVaccinationRecordHistories, params);
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    enabled: !isEmpty(params),
    ...options,
  });

  const queryClient = useQueryClient();

  const handleVaccinationRecordHistories = () =>
    queryClient.invalidateQueries([
      API_QUERIES.VACCINATION_RECORD_HISTORIES,
      { uuid: options.uuid },
    ]);

  const {
    data: vaccinationRecordHistoriesListing,
    hasNext,
    payloadSize,
    totalRecords,
  } = vaccinationHistories || {};

  return {
    vaccinationRecordHistoriesListing,
    error,
    hasNext,
    payloadSize,
    totalRecords,
    isFetching,
    setParams,
    handleVaccinationRecordHistories,
  };
}
