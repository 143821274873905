import { formatDate, getFullName } from 'src/utils';
import { PaginationResponseType } from '../helpers';
import { AttendingNephrologistKey } from './keys';
import { AttendingNephrologistType } from './types';

export const toData = (data): PaginationResponseType<AttendingNephrologistType> => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    [AttendingNephrologistKey.DateOfBirth]: formatDate(
      item?.[AttendingNephrologistKey.DateOfBirth]
    ),
    [AttendingNephrologistKey.Name]: getFullName(item),
  })),
});