import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { ProblemList } from './types';

export function useGetProblemListHistories(
  options?: UseQueryOptions<PaginationResponseType<ProblemList>, Error> & {
    id: ProblemList['uuid'];
  }
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetProblemListHistories,
  } = useQuery<PaginationResponseType<ProblemList>, Error>(
    [API_QUERIES.PROBLEM_LIST_HISTORY, { ...params, id: options.id }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        return responseWrapper<PaginationResponseType<ProblemList>>(() =>
          apiClient.getProblemListHistories(options.id, params)
        );
      },
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateProblemListHistories = () =>
    queryClient.invalidateQueries([API_QUERIES.PROBLEM_LIST_HISTORY, { id: options.id }]);

  const { data: problemListHistories, totalRecords } = data || {};

  return {
    problemListHistories,
    totalRecords,
    error,
    isError,
    isLoading,
    setParams,
    onGetProblemListHistories,
    handleInvalidateProblemListHistories,
  };
}
