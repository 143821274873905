import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { VaccinationRecordFormValuePayload, VaccinationRecordResponse } from './types';

export function useCreateVaccinationRecord(
  options?: UseMutationOptions<
    ApiResponseType<VaccinationRecordResponse>,
    Error,
    VaccinationRecordFormValuePayload
  >
) {
  const {
    mutate: createVaccinationRecord,
    data,
    isLoading,
    isSuccess,
  } = useMutation<
    ApiResponseType<VaccinationRecordResponse>,
    Error,
    VaccinationRecordFormValuePayload
  >({
    mutationFn: (payload: VaccinationRecordFormValuePayload) =>
      responseWrapper(apiClient.createVaccinationRecord, [payload]),
    ...options,
  });

  return {
    createVaccinationRecord,
    data,
    isLoading,
    isSuccess,
  };
}
