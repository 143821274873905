import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toProblemList } from './helpers';
import { ProblemList } from './types';

export function useGetProblemList(
  options?: UseQueryOptions<ApiResponseType<{ problemList: ProblemList }>, Error, ProblemList> & {
    id: ProblemList['uuid'];
  }
) {
  const {
    data: problemList,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetProblemList,
  } = useQuery<ApiResponseType<{ problemList: ProblemList }>, Error, ProblemList>(
    [API_QUERIES.PROBLEM_LIST, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ problemList: ProblemList }>>(
          apiClient.getProblemList,
          params
        );
      },
      select: ({ data }) => toProblemList(data?.problemList),
      enabled: !!options.id,
      ...options,
    }
  );

  return {
    problemList,
    error,
    isError,
    isLoading,
    onGetProblemList,
  };
}
