import { isEmpty } from 'lodash';
import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { TableParams } from 'src/redux/types';
import apiClient from '../apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';

export function useGetAllPatientChartListing(
  options?: UseQueryOptions<
    ApiResponseType<PaginationResponseType<any>>,
    Error,
    PaginationResponseType<any>
  >
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data: allPatientChartListing,
    error,
    isFetching,
    refetch: onGetAllPatients,
  } = useQuery<ApiResponseType<PaginationResponseType<any>>, Error, PaginationResponseType<any>>(
    [API_QUERIES.PATIENT_CHART_LISTING, params],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [, ...params] = query.queryKey;

        return responseWrapper<ApiResponseType<PaginationResponseType<any>>>(
          apiClient.getPatientChartsListing,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !isEmpty(params),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllPatientChartListing = () =>
    queryClient.invalidateQueries(API_QUERIES.PATIENT_CHART_LISTING);

  const {
    data: patientChartListing,
    hasNext,
    payloadSize,
    totalRecords,
  } = allPatientChartListing || {};

  return {
    patientChartListing,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isFetching,
    onGetAllPatients,
    setParams,
    handleInvalidateAllPatientChartListing,
  };
}
