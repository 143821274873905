import { ClinicalAbstractKey, PhysicianOrderKey, StandingOrderKey } from './keys';
import { ClinicalAbstract, PhysicianOrder, StandingOrder } from './types';

export const toPhysicianOrder = (data: PhysicianOrder) => {
  const imageUrls = [];

  data.documentUrls.forEach((item) => {
    let image = JSON.parse(item);
    imageUrls.push(image);
  });

  return { ...data, [PhysicianOrderKey.DocumentUrls]: imageUrls || [] };
};

export const toClinicalAbstract = (data): ClinicalAbstract => {
  return {
    ...data,
    [ClinicalAbstractKey.DocumentUrls]: data?.documentUrls?.map((res) => JSON.parse(res)),
  };
};

export const toStandingOrder = (data: StandingOrder) => {
  return {
    ...data,
    [StandingOrderKey.DocumentUrls]: data?.documentUrls?.map((res) => JSON.parse(res)),
  };
};
