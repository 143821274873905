import { useState } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { compressFile } from 'src/utils';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { FileUploadType, GetPresignedFilePayload } from './types';
import { useGetDownloadFileUrl } from './useGetDownloadFileURL';
import { useUploadFileToS3 } from './useUploadFileToS3';

export function useUploadFile(
  options?: UseMutationOptions<any, Error, File> & {
    type: FileUploadType;
  }
) {
  let _compressFile: File = null;
  const {
    data: fileUrl,
    mutate: getPresignedUploadUrl,
    isSuccess,
    isLoading,
  } = useMutation<any, Error, File>({
    mutationFn: async (payload: File) => {
      _compressFile = await compressFile(payload);
      const formattedPayload: GetPresignedFilePayload = {
        fileName: _compressFile?.name,
        contentType: _compressFile?.type,
        type: options.type || FileUploadType.Signatures,
      };
      return responseWrapper<any>(apiClient.getPresignedUploadFile, [formattedPayload]);
    },
    onSuccess: (data) => {
      uploadFile({
        url: data?.data?.url,
        contentType: _compressFile?.type,
        fileData: _compressFile,
      });
    },
    ...options,
  });

  const { uploadFile, loading: isLoadingUploadFile } = useUploadFileToS3({
    onSuccess: (data, variables) => {
      onGetDecodeUrl({ filePath: variables.url });
    },
  });

  const [downloadUrl, setDownloadUrl] = useState('');

  const { onGetDecodeUrl, isLoading: loadingGet } = useGetDownloadFileUrl({
    onSuccess: (data) => {
      setDownloadUrl(data.data.url);
    },
  });

  return {
    fileUrl,
    downloadUrl,
    loading: isLoading || isLoadingUploadFile || loadingGet,
    isSuccess,
    getPresignedUploadUrl,
  };
}
