import { StatusType } from '../PatientForms';

export enum PATIENT_CHART_ITEM_KEY {
  UUID = 'uuid',
  NAME = 'name',
  PATIENT_ID = 'patientId',
  EMAIL = 'email',
  BIRTH_DATE = 'birthDate',
  PHONE = 'phone',
  PAYMENT_METHOD = 'paymentMethod',
  FIRST_NAME = 'firstName',
  CREATED_AT = 'createdAt',
}

export interface PatientChartListingItem {
  createdAt: string;
  createdBy: null;
  patient: PatientItem;
  patientId: string;
  updatedAt: string;
  updatedBy: null;
  uuid: string;
  patientChartCode: string;
}

export interface PatientItem {
  address: string;
  attendingNephrologist: string;
  createdAt: string;
  createdBy: string;
  birthDate: string;
  doctor_id: string;
  email: string;
  emergencyContactPhone: string;
  firstName: string;
  fullText: string;
  isEditable: string;
  status: StatusType;
  lastName: string;
  middleName: string;
  paymentMethod: string;
  phone: string;
  prefix: string;
  referringPosition: string;
  religion: string;
  sex: string;
  suffix: string;
  updatedAt: string;
  updatedBy: string;
  uuid: string;
  patientCode: string;
}

export interface ValueOptions {
  label: string;
  value: string;
}

export enum PATIENT_CHART_KEY_VALUE {
  PHONE_NUMBER = 'phoneNumber',
  PAYMENT_METHOD = 'paymentMethod',
  PATIENT_ID = 'patientId',
  SEARCH = 'search',
}
