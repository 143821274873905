import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';

export function useGetVaccinationRecordHistory(
  options?: UseQueryOptions<ApiResponseType<any>, Error, any> & {
    uuid: string;
  }
) {
  const {
    data: vaccinationRecordHistory,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetVaccinationRecordHistory,
  } = useQuery<ApiResponseType<any>, Error, any>(
    [API_QUERIES.VACCINATION_RECORD_HISTORIES, { uuid: options.uuid }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<any>>(apiClient.getVaccinationRecordHistory, params);
      },
      select: getResponseData,
      enabled: !!options.uuid,
      ...options,
    }
  );

  return {
    vaccinationRecordHistory,
    error,
    isError,
    isLoading,
    onGetVaccinationRecordHistory,
  };
}
