import React from 'react';
import { Grid, Input, Text, View } from 'src/components/common';
import { ReUseDialyzersFormKey } from 'src/queries';
import SignatureSections from '../../../shared/SignatureSections';
import { ReUseDialyzersFormikProps } from '../helpers';

const ReUseDialyzers: React.FC<Props> = ({
  formikProps,
  formikProps: { getFieldProps, getErrorMessage, setFieldValue, setFieldTouched, values },
  isHistoryView,
}) => {
  return (
    <Grid.Wrap>
      <Grid.Item variant="is-one-third">
        <Input
          label="First Name"
          required
          disabled
          {...getFieldProps(ReUseDialyzersFormKey.FirstName)}
        />
      </Grid.Item>
      <Grid.Item variant="is-one-third">
        <Input
          label="Middle Name"
          required
          disabled
          {...getFieldProps(ReUseDialyzersFormKey.MiddleName)}
        />
      </Grid.Item>
      <Grid.Item variant="is-one-third">
        <Input
          label="Last Name"
          required
          disabled
          {...getFieldProps(ReUseDialyzersFormKey.LastName)}
        />
      </Grid.Item>
      <Grid.Item variant="is-full" className="my-1">
        <View className="note-section">
          <Text>
            I have knowledge that the multiple use of a hemo-dialyzer has been a standard procedure
            in many dialysis centers throughout the world. The multiple use of a dialyzer means the
            re-use of dialyzer after proper cleaning, testing and disinfection. The said dialyzer
            will be re-used by only one patient the same patient who used the dialyzer for the first
            time. It shall only be re-used if it is capable of good performance as validated by the
            center's performance testing. It shall be re used for a maximum of ten (10) times only
            pursuant to the center's policy. Bloodlines will not be re-used.
          </Text>
          <br />
          <Text>
            <span className="fw-bold">DIALYSIS CARE PLUS</span> has carefully studied the procedure
            of dialyzer reprocessing and found it medically acceptable. DIALYSIS CARE PLUS is
            equipped with a semi-automated cleaning and performance testing and reprocessing
            machine. It uses heated citric acid as its disinfectant. To ensure patient safety,
            quality control measures are in place based on the guidelines set by the Association for
            the Advancement of Medical Instrumentation (AAMI).
          </Text>
          <br />
          <Text>
            I understand that all the patients receiving hemodialysis at DIALYSIS CARE PLUS may
            participate in the re-use program unless otherwise specified by the center's Medical
            Director.
          </Text>
          <br />
          <Text>
            A member of the medical / nursing staff had discussed the benefits and consequences of
            the re-use procedure with me. I understand the procedure and hereby, agrees to
            participate in this program.
          </Text>
        </View>
      </Grid.Item>
      <SignatureSections formikProps={formikProps} disabled={isHistoryView} />
    </Grid.Wrap>
  );
};

type Props = {
  formikProps: ReUseDialyzersFormikProps;
  isHistoryView?: boolean;
};

export default ReUseDialyzers;
