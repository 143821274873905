import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../../apiClient';
import { createResponseWrapper } from '../../helpers';
import { ReUseDialyzersFormTypeResponse } from './types';

export function useCreateReUseDialyzersForm(
  options?: UseMutationOptions<
    ReUseDialyzersFormTypeResponse,
    Error,
    ReUseDialyzersFormTypeResponse
  >
) {
  const {
    mutate: createReUseDialyzersForm,
    isLoading,
    isSuccess,
  } = useMutation<ReUseDialyzersFormTypeResponse, Error, ReUseDialyzersFormTypeResponse>({
    mutationFn: (payload: ReUseDialyzersFormTypeResponse) =>
      createResponseWrapper(apiClient.createReUseDialyzersForm, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createReUseDialyzersForm,
    isLoading,
    isSuccess,
  };
}
