import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { TableParams } from 'src/redux/types';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { NursesList } from './types';

export function useGetAllNurses(
  options?: UseQueryOptions<
    ApiResponseType<PaginationResponseType<NursesList>>,
    Error,
    PaginationResponseType<NursesList>
  >
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data,
    error,
    isFetching,
    refetch: onGetAllNurses,
  } = useQuery<
    ApiResponseType<PaginationResponseType<NursesList>>,
    Error,
    PaginationResponseType<NursesList>
  >([API_QUERIES.NURSES_LIST, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [, ...params] = query.queryKey;

      return responseWrapper<ApiResponseType<PaginationResponseType<NursesList>>>(
        apiClient.getNurses,
        params
      );
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    enabled: !isEmpty(params),
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateAllNurses = () => queryClient.invalidateQueries(API_QUERIES.NURSES_LIST);

  const { data: nurses, hasNext, payloadSize, totalRecords } = data || {};

  return {
    nurses,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isFetching,
    onGetAllNurses,
    setParams,
    handleInvalidateAllNurses,
  };
}
