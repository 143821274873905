import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../../apiClient';
import { ApiResponseType, responseWrapper } from '../../helpers';
import { AdvanceDirectivesFormValues } from './types';

export function useEditAdvanceDirectivesForm(
  options?: UseMutationOptions<
    ApiResponseType<AdvanceDirectivesFormValues>,
    Error,
    AdvanceDirectivesFormValues
  >
) {
  const {
    mutate: editAdvanceDirectivesForm,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<ApiResponseType<AdvanceDirectivesFormValues>, Error, AdvanceDirectivesFormValues>(
    {
      mutationFn: (payload: AdvanceDirectivesFormValues) =>
        responseWrapper(apiClient.updateAdvanceDirectivesForm, [payload]),
      ...options,
    }
  );

  return {
    editAdvanceDirectivesForm,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
