import { PaymentMethodOptions } from 'src/utils/paymentUtils';
import { PatientFormsKey } from './keys';

export interface PatientForms {
  [PatientFormsKey.Id]?: string;
  [PatientFormsKey.Prefix]?: string;
  [PatientFormsKey.FirstName]?: string;
  [PatientFormsKey.LastName]?: string;
  [PatientFormsKey.MiddleName]?: string;
  [PatientFormsKey.Suffix]?: string;
  [PatientFormsKey.Phone]?: string;
  [PatientFormsKey.Dob]?: string;
  [PatientFormsKey.Address]?: string;
  [PatientFormsKey.Email]?: string;
  [PatientFormsKey.EmergencyContactPhone]?: string;
  [PatientFormsKey.PaymentMethod]?: PaymentMethodOptions;
  [PatientFormsKey.PatientType]: string;
  [PatientFormsKey.EmergencyContactName]?: string;
  [PatientFormsKey.FullText]?: string;
  [PatientFormsKey.Sex]?: string;
  [PatientFormsKey.Religion]?: string;
  [PatientFormsKey.ReferringPosition]?: string;
  [PatientFormsKey.IsEditable]?: boolean;
  [PatientFormsKey.Status]?: StatusType;
  [PatientFormsKey.DocumentUrls]?: any[];
  [PatientFormsKey.PatientCode]?: string;
  [PatientFormsKey.PaymentOtherNote]?: string;
}

export enum StatusType {
  Active = 1,
  Inactive = 2,
}
