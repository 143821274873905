import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { PackageItem } from './types';

export function useGetPackageItems(
  options?: UseQueryOptions<ApiResponseType<PackageItem[]>, Error, PackageItem[]>
) {
  const [packageId, setPackageId] = useState<string>('');
  const {
    data: packageItems,
    error,
    isError,
    isFetching,
    refetch: onGetPackageItems,
  } = useQuery<ApiResponseType<PackageItem[]>, Error, PackageItem[]>(
    [API_QUERIES.PACKAGE_ITEMS, packageId],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<PackageItem[]>>(apiClient.getPackageItems, params);
      },
      select: getResponseData,
      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: !!packageId,
      keepPreviousData: true,
      ...options,
    }
  );
  const queryClient = useQueryClient();
  const handleInvalidatePackageItems = () =>
    queryClient.invalidateQueries([API_QUERIES.PACKAGE_ITEMS, packageId]);
  return {
    packageItems,
    error,
    isError,
    isFetching,
    onGetPackageItems,
    handleInvalidatePackageItems,
    setPackageId,
  };
}
