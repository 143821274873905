import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../../apiClient';
import { createResponseWrapper } from '../../helpers';
import { NutritionalAssessmentFormType, NutritionalAssessmentFormTypeResponse } from './types';

export function useCreateNutritionalAssessmentForm(
  options?: UseMutationOptions<
    NutritionalAssessmentFormTypeResponse,
    Error,
    NutritionalAssessmentFormType
  >
) {
  const { mutate: createNutritionalAssessmentForm, isLoading } = useMutation<
    NutritionalAssessmentFormTypeResponse,
    Error,
    NutritionalAssessmentFormType
  >({
    mutationFn: (payload: NutritionalAssessmentFormTypeResponse) =>
      createResponseWrapper(apiClient.createNutritionalAssessmentForm, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createNutritionalAssessmentForm,
    isLoading,
  };
}
