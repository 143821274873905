import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { AddTreatmentPatients } from './types';

export function useAddTreatmentsRecordPatients(
  options?: UseMutationOptions<ApiResponseType<AddTreatmentPatients>, Error, AddTreatmentPatients>
) {
  const {
    mutate: addTreatmentPatients,
    data,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<AddTreatmentPatients>, Error, AddTreatmentPatients>({
    mutationFn: (payload: AddTreatmentPatients) =>
      responseWrapper(apiClient.addTreatmentsPatientCharts, [payload]),
    ...options,
  });

  return {
    addTreatmentPatients,
    data,
    isLoading,
    isSuccess,
  };
}
