export enum TreatmentRecordKeys {
  ID = 'id',
  CODE = 'code',
  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdBy',
  CREATED_BY_NAME = 'createdByName',
  UPDATED_BY = 'updatedBy',
  UPDATED_BY_NAME = 'updatedByName',
  UPDATED_AT = 'updatedAt',
  PATIENT_ID = 'patientId',
  LOCATION = 'location',
  DATE = 'date',
  PATIENT_TYPE = 'patientType',
  PACKAGE_TYPE = 'packageType',
  NOTE = 'note',
  SESSION_COUNTER = 'sessionCounter',
  NET_AMOUNT = 'netAmount',
  PAID_AMOUNT = 'paidAmount',
  DATE_TIME_ADMITTED = 'dateTimeAdmitted',

  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum PatientTypeOptions {
  NEW_PATIENT = 'NEW',
  REGULAR_PATIENT = 'REGULAR',
  TRANSFEREE_PATIENT = 'TRANSFEREE',
  TRANSIENT_PATIENT = 'TRANSIENT',
}
