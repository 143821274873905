import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { TreatmentSheet } from './types';

export function useCreateTreatmentSheet(
  options?: UseMutationOptions<{ treatmentSheetForm: TreatmentSheet }, Error, TreatmentSheet>
) {
  const { mutate: createTreatmentSheet, isLoading } = useMutation<
    { treatmentSheetForm: TreatmentSheet },
    Error,
    TreatmentSheet
  >({
    mutationFn: (payload: TreatmentSheet) =>
      createResponseWrapper(apiClient.createTreatmentSheet, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createTreatmentSheet,
    isLoading,
  };
}
