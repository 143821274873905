import { PatientEducationFormKey } from './keys';

export const toData = (data) => {
  return {
    ...data,
    [PatientEducationFormKey.PatientEducationList]: data?.[
      PatientEducationFormKey.PatientEducationList
    ].map((item) => JSON.parse(item)),
    [PatientEducationFormKey.UploadDocument]: data?.[PatientEducationFormKey.UploadDocument].map(
      (item) => JSON.parse(item)
    ),
  };
};
