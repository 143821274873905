import { PatientFormsKey } from './keys';

export const toData = (data) => {
  return {
    ...data,
    [PatientFormsKey.DocumentUrls]: data?.[PatientFormsKey.DocumentUrls]?.map((item) => {
      try {
        return JSON.parse(item);
      } catch (error) {
        return [];
      }
    }),
  };
};
