import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoMdDownload } from 'react-icons/io';
import { connect } from 'react-redux';
import { COLOR_CODE } from 'src/appConfig/constants';
import { showLightBox } from 'src/redux/lightbox/lightboxSlice';
import { getImageBlobs } from 'src/services';
import Link from '../Link';
import LoadingCommon from '../LoadingCommon';
import View from '../View';

const FileUploadedItem: React.FC<Props> = ({
  file,
  index,
  onDelete,
  hideActions,
  isUploading,
  hideDelete,
  onShowLightbox,
  isExternalLink = false,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleDownload = () => {
    setIsFetching(true);
    getImageBlobs(file.url)
      .then((blobs) => {
        setIsFetching(false);
        const url = window.URL.createObjectURL(new Blob([blobs], { type: blobs.type }));
        const tag = document.createElement('a');
        tag.href = url;
        tag.download = file.name;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      })
      .catch(() => {
        setIsFetching(false);
      });
  };

  const handleDelete = () => {
    onDelete && onDelete(index);
  };

  const viewFile = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onShowLightbox({ name: file.name, url: file.url, isExternalLink: isExternalLink });
  };

  return (
    <View isRow key={file.url}>
      <Link href={file.url} onClick={viewFile}>
        {file.name}
      </Link>
      <View className="cmp-file-upload__actions" isRow align="center" renderIf={!hideActions}>
        {isFetching ? (
          <LoadingCommon />
        ) : (
          <IoMdDownload className="cursor-pointer" onClick={handleDownload} />
        )}
        {!hideDelete && (
          <AiOutlineDelete
            className="cursor-pointer ml-8"
            fontSize={20}
            color={COLOR_CODE.DANGER}
            onClick={handleDelete}
          />
        )}
      </View>
      <View renderIf={isUploading} className="ml-8">
        <LoadingCommon />
      </View>
    </View>
  );
};

type Props = typeof mapDispatchToProps & {
  file: {
    name: string;
    size: number;
    url: string;
  };
  index: number;
  onDelete: Function;
  hideActions: boolean;
  isUploading?: boolean;
  hideDelete?: boolean;
  isExternalLink?: boolean;
};

const mapDispatchToProps = {
  onShowLightbox: showLightBox,
};

export default connect(undefined, mapDispatchToProps)(FileUploadedItem);
