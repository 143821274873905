import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { PhysicianOrder } from './types';

export function useCreatePhysicianOrder(
  options?: UseMutationOptions<{ physicianOrder: PhysicianOrder }, Error, PhysicianOrder>
) {
  const { mutate: createPhysicianOrder, isLoading } = useMutation<
    { physicianOrder: PhysicianOrder },
    Error,
    PhysicianOrder
  >({
    mutationFn: (payload: PhysicianOrder) =>
      createResponseWrapper(apiClient.createPhysicianOrder, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createPhysicianOrder,
    isLoading,
  };
}
