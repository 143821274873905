import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { isEmpty } from 'src/validations';

export function useGetHepatitisScreeningResultsHistories(
  options?: UseQueryOptions<
    ApiResponseType<PaginationResponseType<any>>,
    Error,
    PaginationResponseType<any>
  > & {
    uuid: string;
  }
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data: hepatitisScreeningResultsHistories,
    error,
    isFetching,
  } = useQuery<ApiResponseType<PaginationResponseType<any>>, Error, PaginationResponseType<any>>(
    [API_QUERIES.HEMODIALYSIS_TREATMENT_HISTORIES, { ...params, uuid: options.uuid }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<PaginationResponseType<any>>>(
          apiClient.getHepatitisScreeningResultsHistories,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !isEmpty(params),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleHepatitisScreeningResultsHistories = () =>
    queryClient.invalidateQueries([
      API_QUERIES.HEMODIALYSIS_TREATMENT_HISTORIES,
      { uuid: options.uuid },
    ]);

  const {
    data: hepatitisScreeningHistoriesListing,
    hasNext,
    payloadSize,
    totalRecords,
  } = hepatitisScreeningResultsHistories || {};

  return {
    hepatitisScreeningHistoriesListing,
    error,
    hasNext,
    payloadSize,
    totalRecords,
    isFetching,
    setParams,
    handleHepatitisScreeningResultsHistories,
  };
}
