export enum HemodialysisTreatmentKey {
  Id = 'uuid',
  PatientChartCode = 'patientChartCode',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  DoctorName = 'drName',
  PatientName = 'signatureAuthorForm',
  PatientSignatureDate = 'signatureAuthorDate',
  WitnessName = 'witness',
  WitnessSignatureDate = 'witnessDate',
  DocumentUrls = 'documentUrls',
  PatientCode = 'patientCode',

  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  CreatedBy = 'createdBy',
}

export enum MachineNumber {
  Station1 = 'Station 1',
  Station2 = 'Station 2',
  Station3 = 'Station 3',
  Station4 = 'Station 4',
  Station5 = 'Station 5',
  Station6 = 'Station 6',
  Station7 = 'Station 7',
  Station8 = 'Station 8',
  Station9 = 'Station 9',
  Station10 = 'Station 10',
  Station11 = 'Station 11',
  Station12 = 'Station 12',
  Station13 = 'Station 13',
  Station14 = 'Station 14',
  Station15 = 'Station 15',
}

export enum ReUse {
  Once = 1,
  Twice = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
  Nine = 9,
  Ten = 10,
}

export enum DyalyzerType {
  High = 'High flux',
  Low = 'Low flux',
}

export enum HeparinLoad {
  Regular = 'Regular',
  LowDose = 'Low Dose',
  LowMolecular = 'Low Molecular',
  Flushing = 'Flushing',
}

export enum HDAccess {
  Left = 'Left',
  Right = 'Right',
  AVFistula = 'AV Fistula',
  AVGraft = 'AV Graft',
}

export enum BruitThrill {
  Strong = 'Strong',
  Weak = 'Weak',
  Absent = 'Absent',
}

export enum Catheter {
  Permanent = 'Permanent catheter',
  Temporary = 'Temporary catheter',
}

export enum YesNo {
  Yes = 'Yes',
  No = 'No',
}

export enum Anesthesia {
  None = 'None',
  Lidocaine = 'Lidocaine',
  EMLA = 'EMLA',
}

export enum Fistula {
  Right = 'Right Arm A-V Fistula',
  Left = 'Left Arm A-V Fistula',
}

export enum Graft {
  Right = 'Right Arm A-V Graft',
  Left = 'Left Arm A-V Graft',
}

export enum ReceivedOn {
  Ambulatory = 'Ambulatory',
  Wheelchair = 'Wheelchair',
  Stretcher = 'Stretcher',
}

export enum TimeShift {
  First = '1st shift',
  Second = '2rd shift',
  Third = '3rd shift',
}

export enum Assessment {
  HDProcess = 'Proceed with HD',
  NotHDProcess = 'Do not Proceed with HD',
}

export enum TemporaryCatheterType {
  Intrajugular = 'Intrajugular Catheter',
  Subclavian = 'Subclavian Catheter',
  Femoral = 'Femoral Catheter',
}

export enum IntrajugularType {
  RightIntrajugular = 'Right Intrajugular',
  LeftIntrajugular = 'Left Intrajugular',
}

export enum SubclavianType {
  RightSubclavian = 'Right Subclavian',
  LeftSubclavian = 'Left Subclavian',
}

export enum FemoralType {
  RightFemoral = 'Right Femoral',
  LeftFemoral = 'Left Femoral',
}
