import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { handleShowErrorMsg } from 'src/utils/handlerUtils';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { NurseResponse } from './types';

export function useGetNurseDetail(
  options?: UseQueryOptions<ApiResponseType<NurseResponse>, Error, NurseResponse> & {
    id: string;
  }
) {
  const {
    data: nurseData,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetNurseDetail,
  } = useQuery<ApiResponseType<NurseResponse>, Error, NurseResponse>(
    [API_QUERIES.NURSE_BY_ID, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<NurseResponse>>(apiClient.getNurseById, params);
      },
      select: getResponseData,
      enabled: !!options.id,
      onError: (error) => handleShowErrorMsg(error),
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateNurse = (id: string) =>
    queryClient.invalidateQueries([API_QUERIES.NURSE_BY_ID, { id }]);

  return {
    nurseData,
    error,
    isError,
    isLoading,
    onGetNurseDetail,
    handleInvalidateNurse,
  };
}
