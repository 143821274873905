import axios from 'axios';

export const getImageBlobs = (url: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(url, { responseType: 'blob', withCredentials: false })
        .then((data) => resolve(data.data))
        .catch((error) => reject(error));
    } catch (error) {
      reject(error);
    }
  });
};
