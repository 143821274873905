import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { VaccinationRecordByIDResponse } from './types';

export function useGetVaccinationRecordDetail(
  options?: UseQueryOptions<
    ApiResponseType<VaccinationRecordByIDResponse>,
    Error,
    VaccinationRecordByIDResponse
  > & {
    uuid: string;
  }
) {
  const {
    data: vaccinationRecordDetail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetVaccinationRecordById,
  } = useQuery<
    ApiResponseType<VaccinationRecordByIDResponse>,
    Error,
    VaccinationRecordByIDResponse
  >([API_QUERIES.VACCINATION_RECORD, { uuid: options.uuid }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<VaccinationRecordByIDResponse>>(
        apiClient.getVaccinationRecordDetail,
        params
      );
    },
    select: getResponseData,
    enabled: false,
    ...options,
  });

  const queryClient = useQueryClient();
  const handleInvalidateVaccinationRecordDetail = () =>
    queryClient.invalidateQueries([API_QUERIES.VACCINATION_RECORD, { uuid: options.uuid }]);

  return {
    vaccinationRecordDetail,
    error,
    isError,
    isLoading,
    // setParams,
    onGetVaccinationRecordById,
    handleInvalidateVaccinationRecordDetail,
  };
}
