import { SxProps } from '@mui/system';

export enum DEFAULT_TABLE_VALUE {
  COL_SPAN = 1,
  ROW_SPAN = 1,
}

export enum CellType {
  DEFAULT = 'default',
  INPUT = 'input',
  CURRENCY_INPUT = 'currency-input',
  CURRENCY_VALUE = 'currency-value',
  PRINT_CELL = 'print-cell',
  PRINT_CELL_HEAD = 'print-cell-head',
  PRINT_CELL_RIGHT = 'print-cell-right',
  CELL_BREAK_WORD = 'cell-break-word',
}
export interface BaseTableCell {
  key?: string;
  hide?: boolean;
  content: React.ReactNode;
  name?: string;
  subContent?: React.ReactNode;
  rowSpan?: number;
  colSpan?: number;
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  className?: string;
  type?: CellType;
  label?: string | React.ReactNode;
}

/* Table Layout */
export interface HeaderColumn extends BaseTableCell {}

export interface HeaderRow {
  key?: string;
  columns: HeaderColumn[];
  style?: SxProps;
  className?: string;
}

export type HeaderRows = HeaderRow[];

export interface BodyColumn extends BaseTableCell {
  isHeaderColumn?: boolean;
}

export interface BodyRow {
  key?: string;
  columns: BodyColumn[];
  style?: SxProps;
  className?: string;
  isHeaderRow?: boolean;
  errorMessage?: string;
  isVerticalTop?: boolean;
}

export type BodyRows = BodyRow[];

/* Basic Layout */
export interface BodyBasicColumn extends BaseTableCell {
  isHeaderColumn?: boolean;
  label?: string | React.ReactNode;
  headerStyle?: React.CSSProperties;
  headerClassName?: string;
}

export interface BodyBasicRow {
  key?: string;
  columns: BodyBasicColumn[];
  style?: SxProps;
  className?: string;
  isHeaderRow?: boolean;
  errorMessage?: string;
}

export type BodyBasicRows = BodyBasicRow[];

export interface BaseVertibleTableCell {
  key?: string;
  hide?: boolean;
  body?: HeaderColumnVertical[];
  isHeaderCell?: boolean;
  content?: string | React.ReactNode;
  name?: string;
  subContent?: React.ReactNode;
  rowSpan?: number;
  colSpan?: number;
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  className?: string;
  type?: CellType;
  label?: string | React.ReactNode;
}

/* Table Layout */
export interface HeaderColumnVertical extends BaseVertibleTableCell {}

export interface HeaderRowVertical {
  key?: string;
  rows?: HeaderColumnVertical[];
  style?: SxProps;
  className?: string;
}

export type HeaderRowsVertical = HeaderRowVertical[];

export interface BodyRowVertical extends BaseVertibleTableCell {}
export interface BodyVerticalColumn {
  key?: string;
  rows: BodyRowVertical[];
  style?: SxProps;
  className?: string;
  errorMessage?: string;
}

export type BodyVerticalColumns = BodyVerticalColumn[];

export interface BodyVerticalCell extends BaseVertibleTableCell {
  headerStyle?: React.CSSProperties;
  headerClassName?: string;
  body?: HeaderColumnVertical[];
  styles?: SxProps;
}

export interface BodyVerticalRow {
  key?: string;
  rows: BodyVerticalCell[];
  style?: SxProps;
  className?: string;
  errorMessage?: string;
}

export type BodyVertical = BodyVerticalRow[];
