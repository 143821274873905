export enum GeneralInformationFormKey {
  Id = 'id',
  UUID = 'uuid',
  PatientChartId = 'patientChartId',
  PatientChartCode = 'patientChartCode',

  // General Information
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  DateOfBirth = 'dateOfBirth',
  Age = 'age',
  Sex = 'sex',
  CivilStatus = 'civilStatus',
  Height = 'height',
  Weight = 'weight',
  BloodType = 'bloodType',
  Religion = 'religion',
  Photo = 'photos',
  PermanentAddress = 'permanentAddress',
  TelephoneNumber = 'telephoneNumber',
  PatientIdentification = 'identificationNo',
  TemporaryAddress = 'tempoparyAddress',
  MobileNumber = 'mobileNumber',
  EmailAddress = 'emailAddress',
  OfficeAddress = 'officeAddress',
  WorkNumber = 'workNumber',
  Occupation = 'occupation',
  GeneralAdditionalInformation = 'generalAdditionalInfo',

  // Person to notify in case of emergency
  Name = 'emergencyName',
  Relationship = 'emergencyRelationship',
  ContactNumber = 'emergencyContactNumber',
  NameRelativeNotLivingWithPatient = 'emergencyRelativeName',
  RelationshipRelativeNotLivingWithPatient = 'emergencyRelativeRelationship',
  ContactNumberRelativeNotLivingWithPatient = 'emergencyRelativeContactNumber',
  PersonNotifyAdditionalInformation = 'emergencyAdditionalInfo',

  // Medical Status
  MedicalDmNephropathy = 'medicalDmNephropathy',
  MedicalHpnNephrosclerosis = 'medicalHpnNephrosclerosis',
  MedicalChronicGlomerulonephritis = 'medicalChronicGlomerulonephritis',
  MedicalPolycysticKidneyDisease = 'medicalPolycysticKidneyDisease',
  MedicalChronicObstructiveNephropathy = 'medicalChronicObstructiveNephropathy',
  MedicalChronicPyelonephritis = 'medicalChronicPyelonephritis',
  MedicalOther = 'medicalOther',
  MedicalOtherNote = 'medicalOtherNote',

  MedicalPhilhealthStatus = 'medicalPhilhealthStatus',
  MedicalPhilhealthStatusNote = 'medicalPhilhealthStatusNote',

  MedicalPwdOrSc = 'medicalPwdOrSc',
  MedicalPwdOrScNote = 'medicalPwdOrScNote',

  MedicalTeam = 'medicalTeam',
  Nephrologist = 'medicalNephrologist',
  HospitalAffiliation = 'medicalHospitalAffiliation',

  MedicalPrecautionsStandard = 'medicalPrecautionsStandard',
  MedicalPrecautionsAirborne = 'medicalPrecautionsAirborne',
  MedicalPrecautionsContact = 'medicalPrecautionsContact',
  MedicalPrecautionsDroplet = 'medicalPrecautionsDroplet',
  MedicalPrecautionsOthers = 'medicalPrecautionsOthers',
  MedicalPrecautionsOthersNote = 'medicalPrecautionsOthersNote',

  Allergies = 'medicalAllergies',
  TypeOf1stRenalReplacementTherapy = 'medicalTypeOf1stRenal',
  DateOf1stHD = 'medicalDateOf1stRenal',
  DateOfAdmissionAtDCP = 'medicalDateOfAdmissionDcp',
  MedicalStatusAdditionalInformation = 'medicalAdditionalInfo',

  // Signature Confirmation
  ReferredBy = 'signatureReferredBy',
  PatientSignatureOverPrintedName = 'signaturePatient',
  PatientSignatureDate = 'signaturePatientDate',
  PhysicianSignatureOverPrintedName = 'signaturePhisician',
  PhysicianSignatureDate = 'signaturePhisicianDate',
  PhysicianOnDutySignature = 'signaturePhisicianFile',

  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',

  // Input Error Name Precautions and Primary Renal Diagnosis
  Primary_Renal_Diagnosis = 'primaryRenalDiagnosis',
  Precautions = 'precautions',
}
