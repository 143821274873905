export enum AdvanceDirectivesFormKey {
  UUID = 'uuid',
  PATIENT_CHART_CODE = 'patientChartCode',
  PATIENT_CHART_ID = 'patientChartId',
  TEXT_FIELD = 'textField',
  IS_REFUSED_OF_MEDICATION = 'isRefusedOfMedication',
  IS_REFUSED_OF_TREATMENT = 'isRefusedOfTreatment',
  IS_REFUSED_OF_BLOOD_TRANSFUSION = 'isRefusedOfBloodTransfusion',
  IS_REFUSED_OF_DIAGNOSTICS_PROCEDURES = 'isRefusedOfDiagnosticsProcedures',
  IS_DNR = 'isDNR',
  IS_DNI = 'isDNI',
  IS_OTHER = 'isOther',
  OTHER_TEXT_FIELD = 'otherTextField',
  signatureAuthorForm = 'signatureAuthorForm',
  SIGNATURE_AUTHOR_DATE = 'signatureAuthorDate',
  WITNESS = 'witness',
  WITNESS_DATE = 'witnessDate',
  DOCUMENT_URLS = 'documentUrls',
  UPDATED_AT = 'updatedAt',
  UPDATED_BY = 'updatedBy',
  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdBy',
  // for validation form
  RE_USE_CHECK_BOX_KEY = 'reUseCheckBoxKey',
}
