import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MyProfile } from 'src/queries';
import { PermissionsService } from 'src/services';
import { IRootState } from '../store';
import { MyPermissions } from './types';

const setMyPermissionsStore = (_permissions: string[] = []): MyPermissions => ({
  user: {
    canCreate: _permissions.includes('USER:C'),
    canView: _permissions.includes('USER:R'),
    canUpdate: _permissions.includes('USER:U'),
    canDelete: _permissions.includes('USER:D'),
  },
  role: {
    canCreate: _permissions.includes('ROLE:C'),
    canView: _permissions.includes('ROLE:R'),
    canUpdate: _permissions.includes('ROLE:U'),
    canDelete: _permissions.includes('ROLE:D'),
  },
  permission: {
    canCreate: _permissions.includes('PERMISSION:C'),
    canView: _permissions.includes('PERMISSION:R'),
    canUpdate: _permissions.includes('PERMISSION:U'),
    canDelete: _permissions.includes('PERMISSION:D'),
  },
  userPermissions: {
    canCreate: _permissions.includes('USER_PERMISSIONS:C'),
    canView: _permissions.includes('USER_PERMISSIONS:R'),
    canUpdate: _permissions.includes('USER_PERMISSIONS:U'),
    canDelete: _permissions.includes('USER_PERMISSIONS:D'),
  },
  appointment: {
    canCreate: _permissions.includes('ADMISSION') || _permissions.includes('ADMIN'),
    canView: _permissions.includes('ADMISSION') || _permissions.includes('ADMIN'),
    canUpdate: _permissions.includes('ADMISSION') || _permissions.includes('ADMIN'),
    canDelete: _permissions.includes('ADMISSION') || _permissions.includes('ADMIN'),
  },
  nurse: {
    canCreate: _permissions.includes('CLINICAL') || _permissions.includes('ADMIN'),
    canView: _permissions.includes('CLINICAL') || _permissions.includes('ADMIN'),
    canUpdate: _permissions.includes('CLINICAL') || _permissions.includes('ADMIN'),
    canDelete: _permissions.includes('CLINICAL') || _permissions.includes('ADMIN'),
  },
  attendingNephrologit: {
    canCreate: _permissions.includes('CLINICAL') || _permissions.includes('ADMIN'),
    canView: _permissions.includes('CLINICAL') || _permissions.includes('ADMIN'),
    canUpdate: _permissions.includes('CLINICAL') || _permissions.includes('ADMIN'),
    canDelete: _permissions.includes('CLINICAL') || _permissions.includes('ADMIN'),
  },
  patientSchedule: {
    canCreate: _permissions.includes('CLINICAL') || _permissions.includes('ADMIN'),
    canView:
      _permissions.includes('CLINICAL') ||
      _permissions.includes('ADMIN') ||
      _permissions.includes('ADMISSION'),
    canUpdate:
      _permissions.includes('CLINICAL') ||
      _permissions.includes('ADMIN') ||
      _permissions.includes('ADMISSION'),
    canDelete: _permissions.includes('CLINICAL') || _permissions.includes('ADMIN'),
  },
  refund: {
    canCreate: _permissions.includes('FINANCE') || _permissions.includes('ADMIN'),
    canView: _permissions.includes('FINANCE') || _permissions.includes('ADMIN'),
    canUpdate: _permissions.includes('FINANCE') || _permissions.includes('ADMIN'),
    canDelete: _permissions.includes('FINANCE') || _permissions.includes('ADMIN'),
  },
});

export interface IAuthState {
  isAuthenticated?: boolean;
  user: MyProfile;
  isPasswordVerify: boolean;
  isWelcomeScreen: boolean;
  isUpdatedCurrentRole: boolean;
  myPermissions: MyPermissions;
  myPermissionsStrings: string[];
}

const initialState: IAuthState = {
  isAuthenticated: null,
  user: null,
  isPasswordVerify: false,
  isWelcomeScreen: false,
  isUpdatedCurrentRole: false,
  myPermissions: null,
  myPermissionsStrings: [],
};

const userSelector = (state: IRootState) => state.auth.user;

export const userNameSelector = createSelector(
  userSelector,
  (user) => `${user.firstName} ${user.lastName}`
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setPasswordVerify: (state, action: PayloadAction<boolean>) => {
      state.isPasswordVerify = action.payload;
    },
    setIsWelcomeScreen: (state, action: PayloadAction<boolean>) => {
      state.isWelcomeScreen = action.payload;
    },
    setIsUpdatedCurrentRole: (state, action: PayloadAction<boolean>) => {
      state.isUpdatedCurrentRole = action.payload;
    },
    setProfile: (state, action: PayloadAction<MyProfile>) => {
      state.user = action.payload;
    },
    setMyPermissions: (state, action: PayloadAction<string[]>) => {
      state.myPermissions = setMyPermissionsStore(action.payload);
      state.myPermissionsStrings = action.payload;
      PermissionsService.setPermissions(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAuthenticated,
  setProfile,
  setPasswordVerify,
  setIsWelcomeScreen,
  setIsUpdatedCurrentRole,
  setMyPermissions,
} = authSlice.actions;

export const authState = authSlice.getInitialState();

export default authSlice.reducer;
