import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { authResponseWrapper } from 'src/queries/helpers';
import { DCPIIncidentReportFormValue } from './types';

export function useUpdateIncidentReport(
  options?: UseMutationOptions<any, Error, DCPIIncidentReportFormValue>
) {
  const {
    data: incidentReportData,
    mutate: updateIncidentReport,
    isSuccess,
    isLoading,
  } = useMutation<any, Error, DCPIIncidentReportFormValue>({
    mutationFn: (payload: DCPIIncidentReportFormValue) =>
      authResponseWrapper<any>(apiClient.updateIncidentReport, [payload]),
    ...options,
  });
  return {
    incidentReportData,
    updateIncidentReport,
    isSuccess,
    isLoading,
  };
}
