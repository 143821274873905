import { useEffect, useMemo, useState } from 'react';
import { ErrorService } from 'src/services';
import { PollOptions, PollSettings } from './types';

export async function* pollByGenerator(
  headUrl: string,
  getUrl: string,
  { maxAttempts = 180, getMethod = false }: PollSettings
) {
  let currentAttempt = 0;
  while (true) {
    try {
      const result = getMethod
        ? await fetch(getUrl, { method: 'GET', cache: 'no-cache' })
        : await fetch(headUrl, {
            method: 'HEAD',
            cache: 'no-cache',
          });
      if (result.ok) {
        return result;
      }
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw result;
    } catch (error) {
      if (currentAttempt >= maxAttempts) return null;
      yield error;
      currentAttempt += 1;
      // await waiter(2000);
    }
  }
}

export function usePollExportFile(options: { defaultOptions: PollOptions }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { get, head, getMethod } = options.defaultOptions;

  const fileGenerator = useMemo(
    () =>
      pollByGenerator(head, get, {
        maxAttempts: 180,
        getMethod,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setIsLoading(true);
    let interval: NodeJS.Timer;
    // eslint-disable-next-line prefer-const
    interval = setInterval(async () => {
      const { done, value } = await fileGenerator.next();
      if (done === true) {
        clearInterval(interval);
        if (value) return setIsLoading(false);
        ErrorService.handler({ message: 'Export File Failed!' });
        return setIsLoading(false);
      }
    }, 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading };
}
