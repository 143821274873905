import { Grid, Typography } from '@mui/material';
import { DatePicker, FileUpload, Input } from 'src/components/common';
import { FileUploadRes } from 'src/queries';
import { getErrorMessage } from 'src/utils';
import { AdvanceDirectivesFormTypeFormikProps } from '../../InformedConsent/AdvanceDirectivesForm/types';
import { HemodialysisTreatmentFormikProps } from '../../InformedConsent/HemodialysisTreatmentContainer/helpers';
import { ReUseDialyzersFormikProps } from '../../InformedConsent/ReUseDialyzersForm/helpers';
import { SignatureSectionKeyValue } from './keys';

const SignatureSections = <
  T extends
    | AdvanceDirectivesFormTypeFormikProps
    | HemodialysisTreatmentFormikProps
    | ReUseDialyzersFormikProps
>({
  formikProps,
  disabled,
}: Props<T>) => {
  const { values, errors, touched, setFieldValue, setFieldTouched, getFieldProps } = formikProps;

  const handleUploadFile = (data: FileUploadRes[]) => {
    setFieldValue(SignatureSectionKeyValue.DOCUMENT_URLS, [...data]);
  };

  const _getErrorMessage = (fieldName: any) => {
    return getErrorMessage(fieldName, { touched, errors });
  };
  return (
    <Grid container spacing={2} my={2}>
      <Grid item xs={6}>
        <Input
          label="Signature Over Printed Name (Patient)	Or Authorized Representative to Consent for Patient"
          required
          disabled={disabled}
          {...getFieldProps(SignatureSectionKeyValue.SIGNATURE_AUTHOR_FORM)}
          errorMessage={_getErrorMessage(SignatureSectionKeyValue.SIGNATURE_AUTHOR_FORM)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label="Date"
          required
          disabled={disabled}
          {...getFieldProps(SignatureSectionKeyValue.SIGNATURE_AUTHOR_DATE)}
          errorMessage={_getErrorMessage(SignatureSectionKeyValue.SIGNATURE_AUTHOR_DATE)}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          label="Witness"
          required
          disabled={disabled}
          {...getFieldProps(SignatureSectionKeyValue.WITNESS)}
          errorMessage={_getErrorMessage(SignatureSectionKeyValue.WITNESS)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label="Date"
          required
          disabled={disabled}
          {...getFieldProps(SignatureSectionKeyValue.WITNESS_DATE)}
          errorMessage={_getErrorMessage(SignatureSectionKeyValue.WITNESS_DATE)}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" my={2}>
          Upload Document
        </Typography>
        <FileUpload
          onChange={handleUploadFile}
          value={values?.documentUrls}
          errorMessage={_getErrorMessage(SignatureSectionKeyValue.DOCUMENT_URLS)}
          disabled={disabled}
          hideDelete={disabled}
        />
      </Grid>
    </Grid>
  );
};

type Props<
  T extends
    | AdvanceDirectivesFormTypeFormikProps
    | HemodialysisTreatmentFormikProps
    | ReUseDialyzersFormikProps
> = {
  formikProps: T extends AdvanceDirectivesFormTypeFormikProps
    ? AdvanceDirectivesFormTypeFormikProps
    : T extends HemodialysisTreatmentFormikProps
    ? HemodialysisTreatmentFormikProps
    : T extends ReUseDialyzersFormikProps
    ? ReUseDialyzersFormikProps
    : unknown;
  disabled: boolean;
};

export default SignatureSections;
