import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { StandingOrder } from './types';

export function useCreateStandingOrder(
  options?: UseMutationOptions<{ standingOrderForm: StandingOrder }, Error, StandingOrder>
) {
  const { mutate: createStandingOrder, isLoading } = useMutation<
    { standingOrderForm: StandingOrder },
    Error,
    StandingOrder
  >({
    mutationFn: (payload: StandingOrder) =>
      createResponseWrapper(apiClient.createStandingOrder, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createStandingOrder,
    isLoading,
  };
}
