export enum AuthCurrentUserRole {
  CLINICAL = 'CLINICAL',
  ADMISSION = 'ADMISSION',
  FINANCE = 'FINANCE',
  ADMIN = 'ADMIN',
}

export interface ProfilePayload {
  phoneNumber: string;
  mailingAddress: string;
  country: string;
  zipCode: string;
  city: string;
  state: string;
  careOf: string;
}
export interface MyProfile {
  id: string;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  username: string;
  email: string;
  userType: string;
  hasPin: boolean;
  status: string;
  profile: null;
  roles: Role[];
}

export interface Role {
  userId: string;
  roleId: string;
  createdAt?: string;
  updatedAt?: string;
  role: RoleDetail;
}

export interface RoleDetail {
  id: string;
  name: string;
  displayName: string;
  description: string;
}
