export enum DCPIIncidentReportFormKeysValue {
  refundId = 'refundId',
  id = 'id',
  docCode = 'documentCode',
  effectiveDate = 'effectiveDate',
  revNo = 'revNo',
  // Tracking Number
  trackingNumber = 'trackingNumber',
  // A. Division/Department/Section/Unit
  incidentDate = 'incidentDate',
  incidentTime = 'incidentTime',
  incidentType = 'incidentType',
  incidentLocation = 'incidentLocation',
  // B. Description of incident
  description = 'description',
  applicablePolicyProcedureJDForm = 'applicablePolicy',
  applicableLawStandard = 'applicableLaw',
  originatorReporter = 'reporter',
  reporterSignedDate = 'reporterSignedDate',
  departmentHead = 'departmentHead',
  departmentHeadDate = 'departmentHeadSignedDate',
  incidentSignatureDocuments = 'incidentSignatureDocuments',
  // C. Immediate Action/s Taken
  immediateActionTaken = 'immediateActionTaken',
  // D. Root Cause Investigation & Analysis
  rootCauseInvestigationAnalysis = 'rootCause',
  // E. Corrective Action/s
  correctiveAction = 'correctiveAction',
  //
  preparedBy = 'preparedBy',
  preparedPosition = 'preparedPosition',
  preparedPositionDate = 'preparedDate',
  approvedBy = 'approvedBy',
  approvedPosition = 'approvedPosition',
  approvedPositionDate = 'approvedDate',
  correctiveSignatureDocuments = 'correctiveSignatureDocuments',
  // F. Follow-up, Verification of Effectiveness & Close-out
  incidentReportItems = 'incidentReportItems',
  followUpStatusRemarks = 'remark',
  incidentReportId = 'incidentReportId',
  internalQualityAuditorName = 'auditorName',
  followUpDate = 'date',
  followUpSignatureDocuments = 'followUpSignatureDocuments',
  status = 'status',
}
