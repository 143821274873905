import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';

export function useUpdateContinuousFlowSheet(
  options?: UseMutationOptions<ApiResponseType<any>, Error, any>
) {
  const {
    mutate: updateContinuousFlowSheet,
    data,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<any>, Error, any>({
    mutationFn: (payload: any) => responseWrapper(apiClient.updateContinuousFlowSheet, [payload]),
    ...options,
  });

  return {
    updateContinuousFlowSheet,
    data,
    isLoading,
    isSuccess,
  };
}
