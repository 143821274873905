import { ReactNode } from 'react';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import { Image } from 'src/components/common';
import { AuthCurrentUserRole } from 'src/queries';
import { Permission } from 'src/redux/auth/types';
import appConfig from '.';
import { COLOR_CODE } from './constants';
import { IMAGES } from './images';
import { PATHS } from './paths';

export const isActive = (href: string) => {
  return window.location.pathname === href;
};
export type MenuType = {
  title: string;
  subTitle?: string;
  name?: string;
  icon: ReactNode;
  subMenu?: MenuType[];
  href?: string;
  permissions: Permission[];
  roles: string[];
};

export const SidebarMenu: MenuType[] = [
  // TODO: implement later
  // {
  //   title: 'Dashboard',
  //   name: 'dashboard',
  //   icon: (
  //     <div className="title-icon" aria-details="Dashboard">
  //       <Image src={IMAGES.iconDashboard} />
  //     </div>
  //   ),
  //   href: PATHS.dashboard,
  //   permissions: appConfig.DASHBOARD_PERMISSION,
  //   roles: [
  //     AuthCurrentUserRole.CLINICAL,
  //     AuthCurrentUserRole.ADMISSION,
  //     AuthCurrentUserRole.FINANCE,
  //     AuthCurrentUserRole.ADMIN,
  //   ],
  // },
  {
    title: 'All Patients',
    name: 'all-patients',
    icon: (
      <div className="title-icon" aria-details="All Patients">
        <Image src={IMAGES.iconPeople} />
      </div>
    ),
    href: PATHS.patient,
    permissions: appConfig.DASHBOARD_PERMISSION,
    roles: [
      AuthCurrentUserRole.CLINICAL,
      AuthCurrentUserRole.ADMISSION,
      AuthCurrentUserRole.FINANCE,
      AuthCurrentUserRole.ADMIN,
    ],
  },

  {
    title: 'Appointments',
    subTitle: 'Appointments Confirmation',
    name: 'appointments',
    icon: (
      <div className="title-icon" aria-details="Appointments">
        <Image src={IMAGES.iconAppointment} />
      </div>
    ),
    href: PATHS.appointments,
    permissions: appConfig.DASHBOARD_PERMISSION,
    roles: [AuthCurrentUserRole.ADMISSION, AuthCurrentUserRole.ADMIN],
  },
  {
    title: 'Patient Schedule',
    name: 'patient-schedule',
    icon: (
      <div className="title-icon" aria-details="Patient Schedule">
        <Image src={IMAGES.iconSchedule} />
      </div>
    ),
    href: PATHS.patientSchedule,
    permissions: appConfig.DASHBOARD_PERMISSION,
    roles: [AuthCurrentUserRole.CLINICAL, AuthCurrentUserRole.ADMISSION, AuthCurrentUserRole.ADMIN],
  },
  {
    title: 'Patient Chart',
    name: 'patient-chart',
    icon: (
      <div className="title-icon" aria-details="Patient Chart">
        <Image src={IMAGES.iconChart} />
      </div>
    ),
    href: PATHS.patientChart,
    permissions: appConfig.DASHBOARD_PERMISSION,
    roles: [AuthCurrentUserRole.CLINICAL, AuthCurrentUserRole.ADMIN],
  },
  {
    title: 'All Nurses',
    name: 'all-nurses',
    icon: (
      <div className="title-icon">
        <Image src={IMAGES.iconNurses} />
      </div>
    ),
    href: PATHS.nurses,
    permissions: appConfig.DASHBOARD_PERMISSION,
    roles: [AuthCurrentUserRole.CLINICAL, AuthCurrentUserRole.ADMIN],
  },
  {
    title: 'Attending Nephrologist',
    name: 'attending-nephrologist',
    icon: (
      <div className="title-icon">
        <Image src={IMAGES.iconAttendingNephrologist} />
      </div>
    ),
    href: PATHS.attendingNephrologist,
    permissions: appConfig.DASHBOARD_PERMISSION,
    roles: [AuthCurrentUserRole.CLINICAL, AuthCurrentUserRole.ADMIN],
  },
  {
    title: 'Refund',
    name: 'refund',
    icon: (
      <div className="title-icon" aria-details="Refund">
        <Image src={IMAGES.iconRefunds} />
      </div>
    ),
    href: PATHS.refunds,
    permissions: appConfig.DASHBOARD_PERMISSION,
    roles: [AuthCurrentUserRole.FINANCE, AuthCurrentUserRole.ADMIN],
  },
  {
    title: 'Templates',
    name: 'templates',
    icon: (
      <div className="title-icon" aria-details="Templates">
        <Image src={IMAGES.iconTemplates} />
      </div>
    ),
    href: PATHS.templates,
    permissions: appConfig.DASHBOARD_PERMISSION,
    roles: [
      AuthCurrentUserRole.FINANCE,
      AuthCurrentUserRole.ADMIN,
      AuthCurrentUserRole.CLINICAL,
      AuthCurrentUserRole.ADMISSION,
    ],
  },
  {
    title: 'Final Manual',
    name: '/final-manual',
    icon: (
      <div className="title-icon" aria-details="Final Manual">
        <IoMdHelpCircleOutline size={24} color={COLOR_CODE.INFO} />
      </div>
    ),
    href: PATHS.finalManual,
    permissions: appConfig.DASHBOARD_PERMISSION,
    roles: [
      AuthCurrentUserRole.FINANCE,
      AuthCurrentUserRole.ADMIN,
      AuthCurrentUserRole.CLINICAL,
      AuthCurrentUserRole.ADMISSION,
    ],
  },
  // TODO
  // {
  //   title: 'User Management',
  //   name: 'user-management',
  //   icon: (
  //     <div className="title-icon">
  //       <Image src={IMAGES.iconPeople} />
  //     </div>
  //   ),
  //   href: PATHS.userManagements,
  //   permissions: appConfig.DASHBOARD_PERMISSION,
  //   roles: [
  //     AuthCurrentUserRole.CLINICAL,
  //     AuthCurrentUserRole.ADMISSION,
  //     AuthCurrentUserRole.ADMIN,
  //     AuthCurrentUserRole.FINANCE,
  //   ],
  // },
];
