import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { AttendingNephrologistType } from './types';

export function useEditAttendingNephrologist(
  options?: UseMutationOptions<AttendingNephrologistType, Error, AttendingNephrologistType>
) {
  const {
    mutate: editAttendingNephrologist,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<AttendingNephrologistType, Error, AttendingNephrologistType>({
    mutationFn: (payload: AttendingNephrologistType) =>
      responseWrapper(apiClient.updateAttendingNephrologist, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    editAttendingNephrologist,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
