import dayjs, { Dayjs } from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import momentTz from 'moment-timezone';

dayjs.extend(weekday);
dayjs.extend(relativeTime);
dayjs.extend(calendar);

export const DateFormat = 'MM/DD/YYYY';
export const DateFormatWithHour = 'DD/MM/YYYY HH:mm';
export const DateFormatDisplay = 'MMMM DD, YYYY';
export const DateFormatDisplayShort = 'MMM DD, YYYY';
export const DateFormatDisplayMinute = 'MM/DD/YYYY hh:mm A';
export const isoFormat = 'YYYY-MM-DDTHH:mm:ss.sssZ';
export const DateFormatYYYYMMDD = 'YYYY-MM-DD';

export const TimeFormat = 'HH:mm';

export type DateType = string | Date | Dayjs;

/**
 * Get date display
 * @param {string|date|Dayjs} value
 * @param {string} languageCode
 */
export const getDateDisplay = (value: DateType, format = DateFormat) => {
  if (!value) return '';

  return dayjs(value).format(format);
};

export const getDate = (date: DateType) => {
  if (!date) return null;
  return dayjs(date).toDate();
};

export const getIsoDateFormat = (value: DateType) => {
  if (!value) return null;
  return dayjs(value).toISOString();
};

/**
 * Get date display
 * @param {string|date|Dayjs} value
 * @param {string} languageCode
 */
export const getTimeDisplay = (value: string) => {
  return dayjs(value).format(TimeFormat);
};

/// dayjs has many cases incorrect format with timezone so using moment-timezone for this case
/// Reference issues : https://github.com/iamkun/dayjs/issues/1827
export const localTimeToHawaii = (dateTime) => {
  const date = momentTz(dateTime).format(DateFormatWithHour);
  return momentTz(date, DateFormatWithHour).utcOffset('-1000').format(DateFormatDisplayMinute);
};

export const convertToManilaDate = (dateTime: string | Date, formatDate = isoFormat): string => {
  return momentTz(dateTime).tz('Asia/Manila').format(formatDate);
};
export const getCalendarTime = (value: string) =>
  value
    ? dayjs(value).calendar(null, {
        sameDay: '[Today at] hh:mm A', // The same day ( Today at 2:30 AM )
        nextDay: '[Tomorrow at] hh:mm A', // The next day ( Tomorrow at 2:30 AM )
        nextWeek: 'dddd [at] hh:mm A', // The next week ( Sunday at 2:30 AM )
        lastDay: '[Yesterday at] hh:mm A', // The day before ( Yesterday at 2:30 AM )
        lastWeek: '[Last] dddd [at] hh:mm A', // Last week ( Last Monday at 2:30 AM )
        sameElse: 'MMMM d [at] hh:mm A', // Everything else ( July 10 at 2:30 AM )
      })
    : null;
