import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { VaccinationRecordFormValuePayload } from './types';

export function useUpdateVaccinationRecord(
  options?: UseMutationOptions<ApiResponseType<any>, Error, VaccinationRecordFormValuePayload>
) {
  const {
    mutate: updateVaccinationRecord,
    data,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<any>, Error, VaccinationRecordFormValuePayload>({
    mutationFn: (payload: VaccinationRecordFormValuePayload) =>
      responseWrapper(apiClient.updateVaccinationRecord, [payload]),
    ...options,
  });

  return {
    updateVaccinationRecord,
    data,
    isLoading,
    isSuccess,
  };
}
