import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { MedicationRecord, MedicationRecordPayload } from './types';

export function useCreateMedicationRecord(
  options?: UseMutationOptions<
    { medicationRecord: MedicationRecord },
    Error,
    MedicationRecordPayload
  >
) {
  const { mutate: createMedicationRecord, isLoading } = useMutation<
    { medicationRecord: MedicationRecord },
    Error,
    MedicationRecordPayload
  >({
    mutationFn: (payload: MedicationRecordPayload) =>
      createResponseWrapper(apiClient.createMedicationRecord, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createMedicationRecord,
    isLoading,
  };
}
