import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { AdvanceDirectivesFormValues } from './types';

export function useGetAdvanceDirectivesHistories(
  options?: UseQueryOptions<PaginationResponseType<AdvanceDirectivesFormValues>, Error> & {
    uuid: string;
  }
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetAdvanceDirectivesHistories,
  } = useQuery<PaginationResponseType<AdvanceDirectivesFormValues>, Error>(
    [API_QUERIES.ADVANCE_DIRECTIVES_FORM_HISTORIES, { ...params, uuid: options.uuid }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;

        return responseWrapper<PaginationResponseType<AdvanceDirectivesFormValues>>(
          apiClient.getAdvanceDirectivesHistories,
          params
        );
      },
      enabled: !!options.uuid,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateAdvanceDirectivesHistories = () =>
    queryClient.invalidateQueries([
      API_QUERIES.ADVANCE_DIRECTIVES_FORM_HISTORIES,
      { uuid: options.uuid },
    ]);

  const { data: advanceDirectivesHistories, totalRecords } = data || {};

  return {
    advanceDirectivesHistories,
    totalRecords,
    error,
    isError,
    isLoading,
    setParams,
    onGetAdvanceDirectivesHistories,
    handleInvalidateAdvanceDirectivesHistories,
  };
}
