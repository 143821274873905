import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { WaitListConfirm } from './types';

export function useUpdateConfirmAppointment(
  options?: UseMutationOptions<WaitListConfirm, Error, WaitListConfirm>
) {
  const {
    mutate: updateWaitingListConfirm,
    data,
    isLoading,
    isSuccess,
  } = useMutation<WaitListConfirm, Error, WaitListConfirm>({
    mutationFn: (payload: WaitListConfirm) =>
      responseWrapper(apiClient.updateAppointmentConfirmable, [payload]),
    ...options,
  });

  return {
    updateWaitingListConfirm,
    data,
    isLoading,
    isSuccess,
  };
}
