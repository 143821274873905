import { Callback } from 'src/redux/types';
import { isEmpty } from 'src/validations';
import { GenderOption } from './enum';

export const genderSelectOptions = [
  {
    label: 'Male',
    value: GenderOption.Male,
  },
  {
    label: 'Female',
    value: GenderOption.Female,
  },
];

export const philippinesPhoneCountryCode = 'PH';

// Need to define validation schema
// Reference on for DCPI Incident Report Form (Treatment History)
// valueItemTable value table
// prefixKeyTable key table
export const addRowsFunctionTable = ({
  valueItemTable,
  prefixKeyTable,
  emptyTableValue,
  requiredFields,
  callback,
  setFieldTouched,
  setFieldValue,
  setFieldError,
}: {
  valueItemTable: any[];
  prefixKeyTable: string;
  emptyTableValue: any;
  requiredFields?: string[];
  callback?: Callback;
  setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean) => void;
  setFieldError: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}) => {
  const indexItemValue = valueItemTable.length;
  const lastItemValue = valueItemTable[valueItemTable.length - 1];

  const checkItemEmptyTable = valueItemTable.map((item) => {
    return requiredFields.some((field) => !item[`${field}`]);
  });

  // const checkItemEmptyTable = valueItemTable.map((item) => {
  //   return values(item).some((item1) => item1 === '' || item1 === null);
  // });

  if (
    checkItemEmptyTable.includes(true) &&
    indexItemValue > 1 &&
    !checkItemEmptyTable[checkItemEmptyTable.length - 1]
  ) {
    return;
  }

  // call back function to handle add row from outside
  if (callback) {
    callback();
  }

  if (checkItemEmptyTable[checkItemEmptyTable.length - 1]) {
    Object.entries(lastItemValue).forEach(([key, value]) => {
      if (isEmpty(value)) {
        // Value is empty setFieldError and setFieldTouched
        setFieldError(`${prefixKeyTable}[${indexItemValue - 1}].${key}`, true);
        setFieldTouched(`${prefixKeyTable}[${indexItemValue - 1}].${key}`, true);
      }
    });

    return;
  }

  setFieldValue(prefixKeyTable, [...valueItemTable, emptyTableValue]);
  return;
};
