import { Stack, Typography } from '@mui/material';
import { cloneElement, useRef } from 'react';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { Button } from 'src/components/common';
import { hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { DIALOG_TYPES } from 'src/redux/dialog/type';
import { IRootState } from 'src/redux/store';
import { isEmpty } from 'src/validations';
import './styles.scss';

const isLocal = /\d{4}/.test(window.location.port) || /local/.test(window.location.pathname);

const PrintComponentButton: React.FC<Props> = ({
  content,
  printButton,
  isTestLocal = false,
  docName,
  onShowDialog,
  onHideDialog,
  isDialogVisible,
  renderIf = true,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = (e: {
    preventDefault(): unknown;
    stopPropagation: () => void;
    target: { dispatchEvent: (arg0: MouseEvent) => void };
  }) => {
    if (isTestLocal && isLocal && isEmpty(isDialogVisible)) {
      e.stopPropagation();
      e.preventDefault();
      onShowDialog({
        type: DIALOG_TYPES.CONTENT_DIALOG,
        data: {
          content: (
            <Stack>
              <Stack mb={2} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h3">Print Preview</Typography>
                <Stack direction="row">
                  <Button variant="link-danger" onClick={() => onHideDialog()} className="mr-16">
                    Close Dialog
                  </Button>
                  <Button
                    onClick={() => {
                      const retriggeredEvent = new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                      });
                      onHideDialog();
                      e.target.dispatchEvent(retriggeredEvent);
                    }}
                  >
                    Print
                  </Button>
                </Stack>
              </Stack>
              <Stack sx={{ pointerEvents: 'none' }} my={2}>
                {content}
              </Stack>
            </Stack>
          ),
          hideTitle: true,
          maxWidth: 'lg',
          hideCloseButton: true,
        },
      });
    }
  };

  if (!renderIf) return null;

  return (
    <Stack>
      <ReactToPrint
        trigger={() => (
          <Stack>
            {cloneElement(printButton, {
              onClick: handleClick,
            })}
          </Stack>
        )}
        documentTitle={`${docName}.pdf`}
        content={() => ref.current}
        onBeforeGetContent={() => {
          ref.current.style.overflow = 'hidden';
          ref.current.style.maxHeight = 'none';
          ref.current.style.opacity = '1';
        }}
        onBeforePrint={() => {
          ref.current.style.overflow = 'hidden';
          ref.current.style.maxHeight = '10px';
          ref.current.style.opacity = '0';
        }}
      />
      <Stack
        ref={ref}
        sx={{
          position: 'absolute',
          zIndex: -1000,
          pointerEvents: 'none',
          opacity: 0,
          overflow: 'hidden',
          maxHeight: 10,
          paddingTop: 3,
        }}
      >
        {content}
      </Stack>
    </Stack>
  );
};
type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    content: React.ReactElement;
    printButton: React.ReactElement;
    docName?: string;
    isTestLocal?: boolean;
    renderIf?: boolean;
  };

const mapStateToProps = (state: IRootState) => ({
  isDialogVisible: state.dialog.isVisible,
});

const mapDispatchToProps = {
  onShowDialog: showDialog,
  onHideDialog: hideDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintComponentButton);
