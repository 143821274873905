import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { CXRResult } from './types';

export function useCreateCXRResult(
  options?: UseMutationOptions<{ cxrResult: CXRResult }, Error, CXRResult>
) {
  const { mutate: createCXRResult, isLoading } = useMutation<
    { cxrResult: CXRResult },
    Error,
    CXRResult
  >({
    mutationFn: (payload: CXRResult) => createResponseWrapper(apiClient.createCXRResult, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createCXRResult,
    isLoading,
  };
}
