import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../../apiClient';
import { responseWrapper } from '../../helpers';
import { GeneralInformationFormTypeResponse, PatientGeneralInformationFormValues } from './types';

export function useEditGeneralInformationForm(
  options?: UseMutationOptions<
    GeneralInformationFormTypeResponse,
    Error,
    PatientGeneralInformationFormValues
  >
) {
  const {
    mutate: editGeneralInformationForm,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<GeneralInformationFormTypeResponse, Error, PatientGeneralInformationFormValues>({
    mutationFn: (payload: PatientGeneralInformationFormValues) =>
      responseWrapper(apiClient.updateGeneralInformationForm, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    editGeneralInformationForm,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
