// import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { ConnectedRouter } from 'connected-react-router';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import 'dayjs/locale/en';
import 'dayjs/locale/vi';
import utc from 'dayjs/plugin/utc';
import { QueryClient, QueryClientProvider } from 'react-query';
import appConfig from './appConfig';
import MainAppNavigator from './containers';
import createStore from './redux/store';
import './scss/styles.scss';
import * as workbox from './workbox';

import { ThemeProvider } from '@mui/material/styles';
import { ArcElement, Chart, Legend, Tooltip } from 'chart.js';
import dayjs from 'dayjs';
import { Suspense } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ONE_HOUR } from './appConfig/constants';
import { theme } from './appConfig/muiTheme';
import { LoadingCommon } from './components/common';

dayjs.extend(utc);

const { store, history } = createStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: ONE_HOUR,
    },
  },
});

Amplify.configure(appConfig.AWS_CONFIG);

Chart.register(ArcElement, Tooltip, Legend);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ProSidebarProvider>
          <Suspense fallback={<LoadingCommon />}>
            <ConnectedRouter history={history}>
              <Router>
                <Route component={MainAppNavigator} />
              </Router>
            </ConnectedRouter>
          </Suspense>
          <ReactQueryDevtools initialIsOpen={false} />
        </ProSidebarProvider>
      </QueryClientProvider>
    </Provider>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

workbox.register();
// updateVnLocale();
