import { UseQueryOptions } from 'react-query';

export enum API_QUERIES {
  PROFILE = '/me',
  CONTENT = '/content',
  ZIP_CODE = '/zip-code',
  USER_ID = '/user-id',
  PERMISSIONS = '/permissions',
  API_VERSION = '/api-version',

  // File
  _DECODE_FILE_URL = '/decode-file-url',
  _HEAD_FILE_URL = '/head-file-url',
  // Users Management
  USERS = '/users',
  USER = '/users/:id',
  SEARCH_USER = 'users/search',
  SEARCH_PROJECTS = 'projects/search',

  //Patient
  PATIENT_LISTING = '/patient',

  // Treatment Record
  TREATMENT = '/treatment/:id',

  // Patient Chart
  PATIENT_CHART_LISTING = '/patient-charts',
  PATIENT_CHART_BY_ID = '/patient-chart/:id',

  // ReUse Dialyzers Form
  REUSE_DIALYZERS_FORM = '/reuse-dialyzers-form',
  REUSE_DIALYZERS_FORM_HISTORIES = '/reuse-dialyzers-form-histories',

  // Attending Nephrologist Form
  ATTENDING_NEPHROLOGIST = '/attending-nephrologist',
  ATTENDING_NEPHROLOGIST_OPTIONS = '/attending-nephrologist-options',

  // Nutritional Assessment Form
  NUTRITIONAL_ASSESSMENT_FORM = '/nutritional-assessment-form',
  NUTRITIONAL_ASSESSMENT_HISTORIES = '/nutritional-assessment-form-histories',

  // Patient Education Form
  PATIENT_EDUCATION_FORM = '/patient-education-form',
  PATIENT_EDUCATION_FORM_HISTORIES = '/patient-education-form-histories',

  // Medical and Physical
  MEDICAL_AND_PHYSICAL_FORM = '/medical-and-physical-form',

  // General Information Form
  GENERAL_INFORMATION_FORM = '/general-information-form',
  // Continuous Flow Sheet
  CONTINUOUS_FLOW_SHEET = '/continuous-flow-sheet',

  // Hepatitis Screening Results
  HEPATITIS_SCREENING_RESULTS = 'hepatitis-screening-results',
  // Patients
  PATIENTS = '/patients',

  // Physician order forms
  PHYSICIAN_ORDER_FORMS = '/physician-order-forms',
  PHYSICIAN_ORDER_FORM_HISTORIES = '/physician-order-form-histories',

  // Standing order forms
  STANDING_ORDER_FORMS = '/standing-order-forms',
  STANDING_ORDER_FORMS_HISTORY = '/standing-order-forms-histories',

  // Hemodialysis Treatment forms
  HEMODIALYSIS_TREATMENT = '/hemodialysis-treatments',
  HEMODIALYSIS_TREATMENT_HISTORIES = '/hemodialysis-treatment-histories',

  //clinical Abstract
  CLINICAL_ABSTRACT_FORMS = '/clinical-abstract-forms',
  CLINICAL_ABSTRACT_FORMS_HISTORY = '/clinical-abstract-forms-histories',

  // Medication Record forms
  MEDICATION_RECORD = '/medication-order-forms',
  MEDICATION_RECORD_HISTORIES = '/medication-order-form-histories',

  // Advance Directives Form
  ADVANCE_DIRECTIVES_FORM = '/advance-directives-form',
  ADVANCE_DIRECTIVES_FORM_HISTORIES = '/advance-directives-form-histories',
  ADVANCE_DIRECTIVES_FORM_HISTORY_DETAIL = '/advance-directives-form-history-detail',

  //Monthly Laboratory Result
  MONTHLY_LABORATORY_RESULT = 'monthly-laboratory-result',
  MONTHLY_LABORATORY_RESULT_HISTORY = 'monthly-laboratory-result-histories',

  //Problem List
  PROBLEM_LIST = 'problem-list',
  PROBLEM_LIST_HISTORY = 'problem-list-histories',

  //CXR Result
  CXR_RESULT = 'cxr-result',
  CXR_RESULT_HISTORY = 'cxr-result-histories',

  // Treatment Sheet Form
  TREATMENT_SHEET_FORM = '/treatment-sheet-forms',
  TREATMENT_SHEET_FORM_HISTORIES = '/treatment-sheet-form-histories',

  // Vaccination Record
  VACCINATION_RECORD = '/vaccination-record',
  VACCINATION_RECORD_HISTORIES = '/vaccination-record-histories',

  // Nurses
  NURSES_LIST = 'nurses-list',
  NURSE_BY_ID = '/nurses/:id',
  NURSE_OPTIONS = 'nurse-options',

  // Appointments
  APPOINTMENT_LISTING = '/appointments',
  APPOINTMENT_DETAIL = '/appointments/:id',
  APPOINTMENT_INIT = '/appointments-init',
  WAIT_LIST = '/wait-list',
  WAIT_LIST_CONFIRMABLE = '/wait-list-confirmable',
  AVAILABLE_DATE = '/available-date',

  // Treatment Schedule
  TREATMENT_SCHEDULES = '/treatment-schedules',
  TREATMENT_SCHEDULE = '/treatment-schedule',

  // Notification
  NOTIFICATIONS = '/notifications',
  NOTIFICATIONS_NEW_QUANTITY = '/notifications/new-quantity',

  // Treatment History
  ACTIVITY_LOG = '/treatments/activity-log',
  PRODUCTS = '/products',
  PACKAGES = '/packages',
  PACKAGE_ITEMS = '/packages/items',
  REQUISITION_DETAIL = '/treatment-history/requisition',
  CONFIRMATION_DETAIL = '/treatment-history/confirmation-detail',
  OFFICIAL_RECEIPT_DETAIL = '/treatment-history/official-receipt',

  // Treatment Record
  CHARGE_SLIP = '/treatment-record/charge-slip',
  CHARGE_SLIP_DETAIL = '/treatment-record/charge-slip/:id',

  // Treatment Statement Of Account
  STATEMENT_OF_ACCOUNT = '/statement-of-account',

  // Treatment Record
  TREATMENTS_RECORD_PATIENTS = '/treatments-record/patients',

  // Refunds
  REFUNDS = '/refund-records',
  REFUNDS_RECORDS_TAB = '/refund-records-tab',
  REFUND_REQUEST_FORM = '/request-form',
  INCIDENT_REPORT_DETAIL = '/refunds/incident-report',

  // Guarantee Letter
  GUARANTEE_LETTER = '/guarantee-letter',

  // Export
  EXPORT_REQUISITION = '/export-requisition',
  EXPORT_CHARGE_SLIP = '/export-charge-slip',
  EXPORT_STATEMENT_OF_ACCOUNT = '/export-statement-of-account',
  EXPORT_INCIDENT_REPORT = '/export-incident-report',
  EXPORT_REFUND_REPORT = '/export-refund-report',
}

export type QueryOptions<T> = Omit<UseQueryOptions, 'QueryKey'> & { QueryKey: T };
