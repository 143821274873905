import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { AttendingNephrologistType, AttendingNephrologistTypeResponse } from './types';

export function useCreateAttendingNephrologist(
  options?: UseMutationOptions<AttendingNephrologistTypeResponse, Error, AttendingNephrologistType>
) {
  const { mutate: createAttendingNephrologist, isLoading } = useMutation<
    AttendingNephrologistTypeResponse,
    Error,
    AttendingNephrologistType
  >({
    mutationFn: (payload: AttendingNephrologistTypeResponse) =>
      createResponseWrapper(apiClient.createAttendingNephrologist, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createAttendingNephrologist,
    isLoading,
  };
}
