/* eslint-disabled react-hooks/exhaustive-deps */
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import appConfig from 'src/appConfig';
import { COMMON_TYPE } from 'src/appConfig/constants';
import { FileUploadType, useGetDownloadFileUrl, useUploadFile } from 'src/queries';
import { Toastify } from 'src/services';
import { Text, View } from '..';
import FileUploadedItem from './fileUploadedItem';
import './styles.scss';

const FileUpload: React.FC<Props> = ({
  className,
  onChange,
  innerRef,
  errorMessage,
  acceptFileType,
  uploadType = FileUploadType.Signatures,
  value = [],
  disabled,
  hideUploadBox,
  hideDelete,
  hideActions,
}) => {
  const timerRef = useRef(null);
  const isLoadingRef = useRef(false);

  const [avoidGetDownload, setAvoidGetDownload] = useState(false);
  const [avoidOnChange, setAvoidOnChange] = useState(true);

  const [uploadedFile, setUploadedFile] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState(false);

  const [rejectFiles, setRejectFiles] = useState<FileRejection[]>([]);

  const {
    downloadUrl: uploadedUrl,
    getPresignedUploadUrl,
    loading,
  } = useUploadFile({
    type: uploadType,
  });

  const { downloadUrl, onGetDecodeUrl, isLoading } = useGetDownloadFileUrl();

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (!disabled) {
        acceptedFiles.forEach((file: File) => {
          setAvoidOnChange(false);
          setAvoidGetDownload(true);
          setUploadedFile((prev) => [...prev, { name: file.name, size: file.size }]);
        });
        uploadAllFiles(acceptedFiles);
        setRejectFiles(fileRejections);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled]
  );

  const uploadAllFiles = (files: File[]) => {
    const allFiles = [...files];
    if (isLoadingRef.current) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => uploadAllFiles(allFiles), 500);
      return;
    }

    if (allFiles.length) {
      setIsLoadingState(true);
      isLoadingRef.current = true;
      getPresignedUploadUrl(allFiles[0]);
      allFiles.shift();
      return uploadAllFiles(allFiles);
    }

    if (!allFiles.length) {
      clearTimeout(timerRef.current);
      setIsLoadingState(false);
      return;
    }
  };

  const downloadAllFiles = (files) => {
    const allFiles = [...files];
    if (isLoadingRef.current) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => downloadAllFiles(allFiles), 1000);
      return;
    }

    if (allFiles.length) {
      const { url } = allFiles[0];
      isLoadingRef.current = true;
      setIsLoadingState(true);
      onGetDecodeUrl({ filePath: url });
      allFiles.shift();
      return downloadAllFiles(allFiles);
    }

    if (!allFiles.length) {
      clearTimeout(timerRef.current);
      isLoadingRef.current = false;
      setIsLoadingState(false);
      return;
    }
  };

  useEffect(() => {
    const index = uploadedFile?.findIndex((file) => file.url === downloadUrl);
    if (index === -1 && downloadUrl) {
      const newUploadedFile = [...uploadedFile];
      const position = newUploadedFile.findIndex((item) => !item.url);
      if (position !== -1) {
        const item = { ...newUploadedFile[position as number], url: downloadUrl };
        newUploadedFile.splice(position, 1, item);
        setUploadedFile(newUploadedFile);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadUrl, uploadedFile]);

  useEffect(() => {
    const validFiles = value?.filter((item) => item.name && item.url);
    if (validFiles?.length && !avoidGetDownload) {
      setUploadedFile(
        validFiles.map((item) => ({
          name: item.name,
        }))
      );
      setAvoidGetDownload(true);
      downloadAllFiles(validFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, avoidGetDownload]);

  useEffect(() => {
    if (!value.length && uploadedFile.length) {
      setUploadedFile([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    isLoadingRef.current = loading || isLoading;
  }, [loading, isLoading]);

  useEffect(() => {
    const index = uploadedFile?.findIndex((file) => file.url === uploadedUrl);
    if (index === -1 && uploadedUrl) {
      const newUploadedFile = [...uploadedFile];
      const position = newUploadedFile.findIndex((item) => !item.url);
      if (position !== -1) {
        const item = { ...newUploadedFile[position as number], url: uploadedUrl };
        newUploadedFile.splice(position, 1, item);
        setUploadedFile(newUploadedFile);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedUrl, uploadedFile]);

  // List MIME can be found here:
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptFileType ? acceptFileType : COMMON_TYPE,
    maxSize: appConfig.MAXIMUM_FILE_SIZE,
    multiple: true,
    disabled: disabled || isLoadingState,
  });

  useEffect(() => {
    if (rejectFiles.length > 0) {
      rejectFiles.forEach((file) => {
        const error = file.errors[0];
        Toastify.error(error.message.split(',').join(', '));
      });
    }
  }, [rejectFiles]);

  useEffect(() => {
    if (!isLoadingState && !avoidOnChange) {
      onChange && onChange(uploadedFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingState, uploadedFile, avoidOnChange]);

  const handleDeleteFile = (index) => {
    const newUploadedFile = [...uploadedFile];
    newUploadedFile.splice(index, 1);
    setUploadedFile(newUploadedFile);
    onChange && onChange(newUploadedFile);
  };

  const renderFileUploaded = useCallback(() => {
    const index = uploadedFile.findIndex((file) => !file.url);

    return uploadedFile.map((file, idx) => {
      const isUploading = isLoadingState && idx === uploadedFile.length - 1;
      if (file.url || idx === index) {
        return (
          <FileUploadedItem
            key={`file-upload-${idx}`}
            file={file}
            index={idx}
            onDelete={handleDeleteFile}
            hideActions={isLoadingState || hideActions}
            hideDelete={hideDelete}
            isUploading={isUploading}
            isExternalLink={true}
          />
        );
      }
      return <></>;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile, isLoadingState]);

  return (
    <View className={cn(className, 'cmp-file-upload')}>
      <View
        renderIf={!hideUploadBox}
        {...getRootProps({
          className: cn(
            'cmp-file-upload__body mb-8',
            { 'cmp-file-upload__error': !!errorMessage },
            {
              'cmp-file-upload__disabled': disabled || isLoadingState,
            }
          ),
        })}
      >
        <input
          data-testid="upload-input"
          {...getInputProps()}
          disabled={disabled}
          multiple
          {...(innerRef && {
            ref: innerRef,
          })}
        />
        <View isRowWrap align="center">
          <Text className="cmp-file-upload__body__label fw-medium">
            <span>
              Drop file here or
              <span
                className={cn('cmp-file-upload__body__label__browse', {
                  'cmp-file-upload__body__label__disabled': disabled || isLoadingState,
                })}
              >
                {` Browse`}
              </span>
            </span>
          </Text>
        </View>
      </View>

      <View renderIf={!!errorMessage}>
        <Text className="has-text-danger">{errorMessage}</Text>
      </View>

      {renderFileUploaded()}
    </View>
  );
};

type Props = {
  className?: string;
  innerRef?: any;
  numberAllow?: number;
  onChange?: (...args: any[]) => void;
  errorMessage?: string;
  acceptFileType?: Accept;
  isHideIcon?: boolean;
  message?: string;
  maxSize?: number;
  multiple?: boolean;
  uploadType?: FileUploadType;
  value?: Array<{ name: string; size?: number; url?: string }>;
  disabled?: boolean;
  hideUploadBox?: boolean;
  hideDelete?: boolean;
  hideActions?: boolean;
};

export default FileUpload;
