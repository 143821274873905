import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../../apiClient';
import { responseWrapper } from '../../helpers';
import { ClinicalAbstract } from './types';

export function useUpdateClinicalAbstract(
  options?: UseMutationOptions<ClinicalAbstract, Error, ClinicalAbstract>
) {
  const {
    mutate: onUpdateClinicalAbstract,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<ClinicalAbstract, Error, ClinicalAbstract>({
    mutationFn: (payload: ClinicalAbstract) =>
      responseWrapper(apiClient.updateClinicalAbstract, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onUpdateClinicalAbstract,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
