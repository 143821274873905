import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { DCPIIncidentReportFormValue } from './types';

export function useGetIncidentReportDetail(
  options?: UseQueryOptions<
    ApiResponseType<DCPIIncidentReportFormValue>,
    Error,
    DCPIIncidentReportFormValue
  > & {
    id: string;
  }
) {
  const {
    data: incidentReportData,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetIncidentReportDetail,
  } = useQuery<ApiResponseType<DCPIIncidentReportFormValue>, Error, DCPIIncidentReportFormValue>(
    [API_QUERIES.INCIDENT_REPORT_DETAIL, { id: options?.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<DCPIIncidentReportFormValue>>(
          apiClient.getIncidentReport,
          params
        );
      },
      select: getResponseData,
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateIncidentReport = () =>
    queryClient.invalidateQueries([API_QUERIES.INCIDENT_REPORT_DETAIL, { id: options?.id }]);

  return {
    incidentReportData,
    error,
    isError,
    isLoading,
    onGetIncidentReportDetail,
    handleInvalidateIncidentReport,
  };
}
