import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toData } from './helpers';
import { TreatmentSheet } from './types';

export function useGetTreatmentSheetHistory(
  options?: UseQueryOptions<
    ApiResponseType<{ treatmentSheetHistory: TreatmentSheet }>,
    Error,
    TreatmentSheet
  > & {
    id: TreatmentSheet['uuid'];
  }
) {
  const {
    data: treatmentSheetHistory,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTreatmentSheetHistory,
  } = useQuery<ApiResponseType<{ treatmentSheetHistory: TreatmentSheet }>, Error, TreatmentSheet>(
    [API_QUERIES.TREATMENT_SHEET_FORM, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ treatmentSheetHistory: TreatmentSheet }>>(
          apiClient.getTreatmentSheetHistory,
          params
        );
      },
      select: ({ data }) => toData(data?.treatmentSheetHistory),
      enabled: !!options.id,
      ...options,
    }
  );

  return {
    treatmentSheetHistory,
    error,
    isError,
    isLoading,
    onGetTreatmentSheetHistory,
  };
}
