import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';

export function useUpdateHepatitisScreeningResults(
  options?: UseMutationOptions<ApiResponseType<any>, Error, any>
) {
  const {
    mutate: updateHepatitisScreeningResults,
    data,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<any>, Error, any>({
    mutationFn: (payload: any) =>
      responseWrapper(apiClient.updateHepatitisScreeningResults, [payload]),
    ...options,
  });

  return {
    updateHepatitisScreeningResults,
    data,
    isLoading,
    isSuccess,
  };
}
