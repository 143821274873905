import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { TreatmentRecordFormValue, TreatmentRecordTypeResponse } from './types';

export function useCreateTreatmentRecord(
  options?: UseMutationOptions<
    ApiResponseType<TreatmentRecordTypeResponse>,
    Error,
    TreatmentRecordFormValue
  >
) {
  const {
    mutate: createTreatmentRecordForm,
    data,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<TreatmentRecordTypeResponse>, Error, TreatmentRecordFormValue>({
    mutationFn: (payload: TreatmentRecordFormValue) =>
      responseWrapper(apiClient.createTreatmentRecord, [payload]),
    ...options,
  });

  return {
    createTreatmentRecordForm,
    data,
    isLoading,
    isSuccess,
  };
}
