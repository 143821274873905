import React from 'react';
import AlertMUI, { AlertColor, AlertProps } from '@mui/material/Alert';
import './styles.scss';
import { IoInformationCircle } from 'react-icons/io5';
import { COLOR_CODE } from 'src/appConfig/constants';

const FormAlert: React.FC<Props> = ({ children, type }) => {
  const handleIconColor = () => {
    switch (type) {
      case 'success':
        return COLOR_CODE.SUCCESS;
      case 'info':
        return COLOR_CODE.PRIMARY_500_BASE;
      case 'warning':
        return COLOR_CODE.WARNING;
      case 'error':
        return COLOR_CODE.DANGER;
      default:
        return COLOR_CODE.PRIMARY_500_BASE;
    }
  };

  return (
    <AlertMUI
      severity={type ? type : 'info'}
      icon={<IoInformationCircle color={handleIconColor()} fontSize={30} />}
    >
      {children}
    </AlertMUI>
  );
};

type Props = {
  children: React.ReactNode;
  type?: AlertColor;
} & AlertProps;

export default FormAlert;
