import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'src/hooks';
import { TableParams } from 'src/redux/types';
import { getFullName } from 'src/utils';
import { isEmpty } from 'src/validations';
import { NursesList } from '.';
import { SelectOption } from '..';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';

const search = {
  take: 10,
  skip: 0,
};

const transferPatientsOptions = (patientItem: NursesList[]) => {
  return patientItem.map((item: NursesList) => ({
    label: getFullName({
      firstName: item.firstName,
      lastName: item.lastName,
      middleName: item.middleName,
    }),
    value: item.uuid,
  }));
};

export function useGetNurseOption(
  options?: UseInfiniteQueryOptions<PaginationResponseType<NursesList>, Error>
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const [params, setParams] = useState<TableParams>(null);
  const debounceSearch = useDebounce(inputSearch);

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getNoneEligibleOptions,
    fetchNextPage,
  } = useInfiniteQuery<PaginationResponseType<NursesList>, Error>(
    [API_QUERIES.NURSE_OPTIONS, 'options', debounceSearch, { type: 'lazy' }],
    (props): Promise<PaginationResponseType<NursesList>> => {
      const { pageParam = search } = props;

      return responseWrapper<PaginationResponseType<NursesList>>(apiClient.getNurses, [
        { ...pageParam, ...params, search: inputSearch },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: !!params,
      ...options,
    }
  );

  const nurseOptions: SelectOption[] = useMemo(() => {
    if (isEmpty(data?.pages)) return [];
    return data.pages.reduce(
      (state, page, _pageIdx) => [...state, ...transferPatientsOptions(page.data)],
      []
    );
  }, [data]);

  const hasNext = useMemo(() => {
    if (isEmpty(data?.pages)) return null;
    return true;
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidatePatients = () => queryClient.invalidateQueries(API_QUERIES.NURSE_OPTIONS);

  return {
    nurseOptions,
    error,
    hasNext,
    isError,
    loading: isFetching,
    setInputSearch,
    getNoneEligibleOptions,
    fetchNextPage,
    setParams,
    handleInvalidatePatients,
  };
}
