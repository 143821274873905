import { CXRResultKey, MonthlyLaboratoryResultKey } from './keys';

export interface MonthlyLaboratoryResult {
  [MonthlyLaboratoryResultKey.ID]?: string;
  [MonthlyLaboratoryResultKey.NameOfPatient]?: string;
  [MonthlyLaboratoryResultKey.PatientChartCode]: string;
  [MonthlyLaboratoryResultKey.AttendingNephrologist]?: string;
  [MonthlyLaboratoryResultKey.HemodialysisAccess]?: string;
  [MonthlyLaboratoryResultKey.Diagnosis]?: string;
  [MonthlyLaboratoryResultKey.MonthAndYear]?: string;
  [MonthlyLaboratoryResultKey.Test]?: any[];
  [MonthlyLaboratoryResultKey.DocumentUrls]?: any[];
  [MonthlyLaboratoryResultKey.UpdatedBy]?: string;
  [MonthlyLaboratoryResultKey.UpdatedAt]?: string;
  [MonthlyLaboratoryResultKey.DateTime]?: string;
  [MonthlyLaboratoryResultKey.HbsAg]?: string;
  [MonthlyLaboratoryResultKey.AntiHbs]?: string;
  [MonthlyLaboratoryResultKey.AntiHcv]?: string;

  [MonthlyLaboratoryResultKey.Iron]?: string;
  [MonthlyLaboratoryResultKey.Ferritin]?: string;
  [MonthlyLaboratoryResultKey.Tibc]?: string;
  [MonthlyLaboratoryResultKey.Tsat]?: string;
}

export interface Test {
  [MonthlyLaboratoryResultKey.TestName]: string;
  [MonthlyLaboratoryResultKey.TestResult]: string;
}

export interface CXRResult {
  [CXRResultKey.ID]?: string;
  [CXRResultKey.NameOfPatient]?: string;
  [CXRResultKey.PatientChartCode]: string;
  [CXRResultKey.AttendingNephrologist]?: string;
  [CXRResultKey.HemodialysisAccess]?: string;
  [CXRResultKey.Diagnosis]?: string;
  [CXRResultKey.MonthAndYear]?: string;
  [CXRResultKey.CoMorbidities]?: string;
  [CXRResultKey.DocumentUrls]?: any[];
  [CXRResultKey.UpdatedAt]?: string;
  [CXRResultKey.DateTime]?: string;
  [CXRResultKey.UpdatedBy]?: string;
  [CXRResultKey.XRayReport]?: any[];
}

export interface XRayReport {
  xRayReportName: string;
  xRayReportResult: string;
}

export enum TestNameOptionsResults {
  Hemoglobin = 'hemoglobin',
  Hematocrit = 'hematocrit',
  WBC = 'wbc',
  RBC = 'rbc',
  PMN = 'pmn',
  PlateletCount = 'plateletCount',
  Creatinine = 'creatinine',
  PreHDBUN = 'preHDBUN',
  PostHDBUN = 'postHDBUN',
  KtV = 'ktv',
  URR = 'urr',
  Uric_Acid = 'uric acid',
  Sodium = 'sodium',
  Potassium = 'potassium',
  Calcium = 'calcium',
  Phosphorus = 'phosphorus',
  Albumin = 'albumin',
  FBS = 'fbs',
  Triglycerides = 'triglycerides',
  LDL = 'ldl',
  HDL = 'hdl',
  SGPT = 'sgpt',
  IPTH = 'ipth',
}
