import { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { Typography } from '@mui/material';
import { COLOR_CODE } from 'src/appConfig/constants';
import { View } from 'src/components/common';
import { PhysicianOrderFormikProps } from 'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartForms/DialysisPrescription/PhysicianOrderForm/helpers';
import { ContinuousFlowSheetFormikProps } from 'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartForms/HemodialysisMonitoring/ContinuousFlowSheet/types';
import { HemodialysisTreatmentFormikProps } from 'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartForms/InformedConsent/HemodialysisTreatmentContainer/helpers';
import { ReUseDialyzersFormikProps } from 'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartForms/InformedConsent/ReUseDialyzersForm/helpers';
import { HepatitisScreeningResultsFormikProps } from 'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartForms/LaboratoryFlowSheet/HepatitisScreeningResults/types';
import { VaccinationRecordFormikProps } from 'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartForms/LaboratoryFlowSheet/VaccinationRecord/types';
import { PatientGeneralInformationFormikProps } from 'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartForms/PatientDataForm/GeneralInformationForm/types';
import { UpsertMedicalAndPhysicalFormikProps } from 'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartForms/PatientDataForm/MedicalHistoryAndPhysicalExamination/types';
import { NutritionalAssessmentFormikProps } from 'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartForms/ProgressNote/NutritionalAssessment/helpers';
import { PatientEducationFormikProps } from 'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartForms/ProgressNote/PatientEducation/helpers';
import { handleNavigateBackToTab } from 'src/containers/PatientChartManagement/PatientChartsContainer/constants';
import { PATIENT_CHART_FORM_NAME } from 'src/containers/shared';
import { hideAllDialog, hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { DIALOG_TYPES } from 'src/redux/dialog/type';
import { FormAction, setFormAction } from 'src/redux/form/formSlice';
import { IRootState } from 'src/redux/rootReducer';
import { handleScrollToTopError } from 'src/utils/handlerUtils';
import { isEmpty } from 'src/validations';
import { AdvanceDirectivesFormTypeFormikProps } from '../InformedConsent/AdvanceDirectivesForm/types';
import BreadcrumbsPatientChart from './breadcrumbs';
import HeaderPatientChartForm from './header';
import './styles.scss';

const FormHeader = <
  T extends
    | UpsertMedicalAndPhysicalFormikProps
    | PhysicianOrderFormikProps
    | HemodialysisTreatmentFormikProps
    | PatientGeneralInformationFormikProps
    | ReUseDialyzersFormikProps
    | ContinuousFlowSheetFormikProps
    | AdvanceDirectivesFormTypeFormikProps
    | HepatitisScreeningResultsFormikProps
    | NutritionalAssessmentFormikProps
    | PatientEducationFormikProps
    | VaccinationRecordFormikProps
>({
  formikProps,
  onShowDialog,
  onHideDialog,
  onHideAllDialog,
  isSubmitting,
  onSetFormAction,
  isHideBreadcrumbs = false,
  isHideExport = false,
}: Props<T>) => {
  const location = useLocation();
  const { id, formCode } = useParams<{ id: string; formCode: PATIENT_CHART_FORM_NAME }>();
  const { dirty: isFormDirty, handleSubmit, handleReset, errors, touched, values } = formikProps;

  const isTreatmentHistoryForm = useMemo(
    () => location.pathname.includes('treatment-history'),
    [location]
  );

  const _handleScrollToTopError = useCallback(() => {
    handleScrollToTopError(errors);
  }, [errors]);

  const handleDiscardButton = () => {
    if (isFormDirty && !isEmpty(touched)) {
      onShowDialog({
        type: DIALOG_TYPES.YESNO_DIALOG,
        data: {
          title: `Discard`,
          content: `Changes will not be saved. Are you sure to discard?`,
          okText: 'Discard',
          cancelText: 'Cancel',
          onOk: async () => {
            await onSetFormAction(FormAction.DISCARD);

            if (isTreatmentHistoryForm) {
              window.close();
            } else {
              onHideDialog();
              setTimeout(() => {
                handleNavigateBackToTab({ id, formCode });
                handleReset();
              }, 50);
            }
          },
          onCancel: () => {
            onHideAllDialog();
          },
        },
      });
    } else {
      if (isTreatmentHistoryForm) {
        window.close();
      } else {
        handleNavigateBackToTab({ id, formCode });
      }
    }
  };

  const handleSaveFormButton = () => {
    handleSubmit();
    _handleScrollToTopError();
  };

  return (
    <View className="form-navbar" fullWidth>
      <View className="form-top" fullWidth>
        <Typography color={COLOR_CODE.PRIMARY_00} variant="h3" fontWeight={500}>
          Patient Chart {values?.patientChartCode}
        </Typography>
      </View>
      <View className="form-header" fullWidth>
        {!isHideBreadcrumbs && (
          <BreadcrumbsPatientChart id={id} formCode={formCode as PATIENT_CHART_FORM_NAME} />
        )}

        <HeaderPatientChartForm
          formikProps={formikProps}
          formCode={formCode as PATIENT_CHART_FORM_NAME}
          onSaveButton={handleSaveFormButton}
          onDiscardButton={handleDiscardButton}
          isSubmitting={isSubmitting}
          isHideExport={isHideExport}
        />
      </View>
    </View>
  );
};

type Props<
  T extends
    | UpsertMedicalAndPhysicalFormikProps
    | PhysicianOrderFormikProps
    | HemodialysisTreatmentFormikProps
    | PatientGeneralInformationFormikProps
    | ReUseDialyzersFormikProps
    | ContinuousFlowSheetFormikProps
    | AdvanceDirectivesFormTypeFormikProps
    | HepatitisScreeningResultsFormikProps
    | NutritionalAssessmentFormikProps
    | PatientEducationFormikProps
    | VaccinationRecordFormikProps
> = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    formikProps: T extends UpsertMedicalAndPhysicalFormikProps
      ? UpsertMedicalAndPhysicalFormikProps
      : T extends PhysicianOrderFormikProps
      ? PhysicianOrderFormikProps
      : T extends HemodialysisTreatmentFormikProps
      ? HemodialysisTreatmentFormikProps
      : T extends PatientGeneralInformationFormikProps
      ? PatientGeneralInformationFormikProps
      : T extends ReUseDialyzersFormikProps
      ? ReUseDialyzersFormikProps
      : T extends ContinuousFlowSheetFormikProps
      ? ContinuousFlowSheetFormikProps
      : T extends AdvanceDirectivesFormTypeFormikProps
      ? AdvanceDirectivesFormTypeFormikProps
      : T extends HepatitisScreeningResultsFormikProps
      ? HepatitisScreeningResultsFormikProps
      : T extends NutritionalAssessmentFormikProps
      ? NutritionalAssessmentFormikProps
      : T extends PatientEducationFormikProps
      ? PatientEducationFormikProps
      : T extends VaccinationRecordFormikProps
      ? VaccinationRecordFormikProps
      : unknown;
    isSubmitting: boolean;
    isHideBreadcrumbs?: boolean;
    isHideExport?: boolean;
  };

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {
  onShowDialog: showDialog,
  onHideDialog: hideDialog,
  onHideAllDialog: hideAllDialog,
  onSetFormAction: setFormAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormHeader);
