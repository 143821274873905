import { getFullName } from 'src/utils';

export const toData = (data) => {
  const newData = { ...data };
  if (newData?.patientChart) {
    newData.patientChart.patient.patientName = getFullName(newData?.patientChart?.patient);
    newData.patientChart.patient.patientId = newData?.patientChart?.patientId;
  }
  return newData;
};
