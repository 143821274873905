import { Breadcrumbs, Typography } from '@mui/material';
import { FC, memo, useMemo } from 'react';
import { GrNext } from 'react-icons/gr';
import TypographyLink from 'src/components/TypographyLink';
import { getRootFormTab } from './helpers';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Link } from 'src/components/common';
import {
  getNameFormValue,
  handleNavigateBackToTab,
} from 'src/containers/PatientChartManagement/PatientChartsContainer/constants';
import { PATIENT_CHART_FORM_NAME } from 'src/containers/shared';
import { setFormAction } from 'src/redux/form/formSlice';

const BreadcrumbsPatientChart: FC<Props> = ({ formCode, id }) => {
  const location = useLocation();

  const isTreatmentHistoryForm = useMemo(
    () => location.pathname.includes('treatment-history'),
    [location]
  );

  const dispatch = useDispatch();

  const handleNavigateForm = () => {
    dispatch(setFormAction(null));
    handleNavigateBackToTab({ id, formCode });
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<GrNext fontSize="small" />}>
      {isTreatmentHistoryForm ? (
        <Typography>Dialysis Prescription</Typography>
      ) : (
        <Link onClick={handleNavigateForm}>
          <TypographyLink>{getRootFormTab(formCode)}</TypographyLink>
        </Link>
      )}

      <Typography variant="body2">{getNameFormValue(formCode)}</Typography>
    </Breadcrumbs>
  );
};

type Props = {
  formCode: PATIENT_CHART_FORM_NAME;
  id: string;
};

export default memo(BreadcrumbsPatientChart);
