import { Auth, Hub } from 'aws-amplify';
import { History } from 'history';
import { useEffect } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import { useComponentDidMount } from 'src/hooks';
import { MyProfile, useLogout, useProfile } from 'src/queries';
import { setAuthenticated, setMyPermissions, setProfile } from 'src/redux/auth/authSlice';
import { setShowNavbar } from 'src/redux/common/commonSlice';
import { IRootState } from 'src/redux/rootReducer';
import { DelegationKeyService, ErrorService, Navigator, TokenService } from 'src/services';

const AuthContainer: React.FC<Props> = ({
  history,
  isAuthenticated,
  onSetAuth,
  onSetProfile,
  isWelcomeScreen,
  onSetShowNavbar,
  onSetMyPermissions,
}) => {
  const { logout } = useLogout();
  const { toggleSidebar } = useProSidebar();

  const handleSetAuthenticated = (data: MyProfile) => {
    onSetProfile(data);
    onSetAuth(true);
    const [firstRole] = data?.roles;
    onSetMyPermissions([firstRole?.role?.name]);
  };

  // const { getMyPermissions } = useMyPermissions({
  //   onSuccess: async (data) => {
  //     onSetMyPermissions(data);
  //     const localPermissions = await PermissionsService.getPermissions();
  //     onSetMyPermissions(data);
  //     const isDiffPermissions =
  //       isEmpty(localPermissions) ||
  //       data.length !== localPermissions.length ||
  //       localPermissions.some((item, idx) => item !== data[idx]);
  //     if (isDiffPermissions) {
  //       const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
  //       const refreshToken = cognitoUser.getSignInUserSession().getRefreshToken();
  //       return cognitoUser.refreshSession(refreshToken, () => {
  //         getMyProfile();
  //       });
  //     }
  //     return getMyProfile();
  //   },
  // });

  const { getMyProfile } = useProfile({
    onSuccess(data) {
      if (data) {
        handleSetAuthenticated(data);
      }
    },
    onError(err) {
      ErrorService.handler(err);
      logout();
    },
  });

  useEffect(() => {
    Hub.listen('auth', authLogin);
    // 1.call this first when mount because history listen fire when route changed
    authenticate();
    return () => {
      Hub.remove('auth', authLogin);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useComponentDidMount(async () => {
    try {
      await TokenService.getToken();
      authenticate();
    } catch (error) {
      clearAuth();
    }
  });

  const authLogin = (res: { payload: { event: string; data?: any } }) => {
    const { payload } = res;
    const { event } = payload;
    switch (event) {
      case 'signIn':
        authenticate();
        break;
      case 'signOut':
        TokenService.clearToken();
        clearAuth();
        break;
      case 'signIn_failure':
        console.log('signin error', payload?.data?.message);
        break;
      default:
        break;
    }
  };

  const clearAuth = () => {
    onSetAuth(false);
    Navigator.navigate(PATHS.signIn);
    toggleSidebar(false);
    onSetShowNavbar(false);
  };

  const authenticate = () => {
    if (!isAuthenticated) {
      // 2. Get current user
      Auth.currentAuthenticatedUser()
        .then((user) => {
          // const userAttributes = user.attributes;
          // getMyPermissions();
          getMyProfile();
          onSetAuth(true);
          toggleSidebar(true);
          onSetShowNavbar(true);
        })
        .catch(() => {
          clearAuth();
        });
    }
  };

  window.addEventListener('beforeunload', () => {
    DelegationKeyService.clearDelegationKey();
  });

  return null;
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & { history: History };

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isWelcomeScreen: state.auth.isWelcomeScreen,
});

const mapDispatchToProps = {
  onSetAuth: setAuthenticated,
  onSetProfile: setProfile,
  onSetShowNavbar: setShowNavbar,
  onSetMyPermissions: setMyPermissions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthContainer));
