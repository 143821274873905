import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../../helpers';
import { API_QUERIES } from '../../keys';
import { AdvanceDirectivesFormDetailResponse } from './types';

export function useGetAdvanceDirectivesFormById(
  options?: UseQueryOptions<
    ApiResponseType<AdvanceDirectivesFormDetailResponse>,
    Error,
    AdvanceDirectivesFormDetailResponse
  > & {
    uuid: string;
  }
) {
  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetAdvanceDirectivesFormById,
  } = useQuery<
    ApiResponseType<AdvanceDirectivesFormDetailResponse>,
    Error,
    AdvanceDirectivesFormDetailResponse
  >([API_QUERIES.ADVANCE_DIRECTIVES_FORM, { uuid: options.uuid }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<AdvanceDirectivesFormDetailResponse>>(
        apiClient.getAdvanceDirectivesForm,
        params
      );
    },
    select: getResponseData,
    enabled: !!options?.uuid,
    ...options,
  });

  const { advanceDirective } = data || {};

  const queryClient = useQueryClient();
  const handleInvalidateAdvanceDirectivesForm = () =>
    queryClient.invalidateQueries([API_QUERIES.ADVANCE_DIRECTIVES_FORM, { uuid: options?.uuid }]);

  return {
    advanceDirective,
    error,
    isError,
    isLoading,
    onGetAdvanceDirectivesFormById,
    handleInvalidateAdvanceDirectivesForm,
  };
}
