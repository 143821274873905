import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { AppointmentFormValueResponse } from './types';

export function useGetAppointmentById(
  options?: UseQueryOptions<
    ApiResponseType<AppointmentFormValueResponse>,
    Error,
    AppointmentFormValueResponse
  > & {
    uuid: string;
  }
) {
  const {
    data: appointmentFormDetail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetAppointmentById,
  } = useQuery<ApiResponseType<AppointmentFormValueResponse>, Error, AppointmentFormValueResponse>(
    [API_QUERIES.APPOINTMENT_DETAIL, { uuid: options.uuid }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<AppointmentFormValueResponse>>(
          apiClient.getAppointmentFormDetail,
          params
        );
      },
      select: getResponseData,
      enabled: false,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateContinuousFlowSheetDetail = () =>
    queryClient.invalidateQueries([API_QUERIES.APPOINTMENT_DETAIL, { uuid: options.uuid }]);

  return {
    appointmentFormDetail,
    error,
    isError,
    isLoading,
    onGetAppointmentById,
    handleInvalidateContinuousFlowSheetDetail,
  };
}
