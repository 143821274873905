import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { AdvanceDirectivesFormTypeHistoryResponse } from './types';

export function useGetAdvanceDirectivesHistory(
  options?: UseQueryOptions<
    ApiResponseType<AdvanceDirectivesFormTypeHistoryResponse>,
    Error,
    AdvanceDirectivesFormTypeHistoryResponse
  > & {
    uuid: string;
  }
) {
  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetAdvanceDirectivesHistoryDetail,
  } = useQuery<
    ApiResponseType<AdvanceDirectivesFormTypeHistoryResponse>,
    Error,
    AdvanceDirectivesFormTypeHistoryResponse
  >([API_QUERIES.ADVANCE_DIRECTIVES_FORM_HISTORY_DETAIL, { uuid: options?.uuid }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<AdvanceDirectivesFormTypeHistoryResponse>>(
        apiClient.getAdvanceDirectivesHistoryDetail,
        params
      );
    },

    select: getResponseData,
    enabled: !!options.uuid,
    ...options,
  });
  const { advanceDirectiveHistory } = data || {};

  const queryClient = useQueryClient();
  const handleInvalidateAdvanceDirectivesHistory = () =>
    queryClient.invalidateQueries([API_QUERIES.ADVANCE_DIRECTIVES_FORM_HISTORY_DETAIL]);

  return {
    advanceDirectiveHistory,
    error,
    isError,
    isLoading,
    onGetAdvanceDirectivesHistoryDetail,
    handleInvalidateAdvanceDirectivesHistory,
  };
}
