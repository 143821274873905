import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { authResponseWrapper } from 'src/queries/helpers';
import { DCPIIncidentReportFormValue } from './types';

export function usePostIncidentReport(
  options?: UseMutationOptions<any, Error, DCPIIncidentReportFormValue>
) {
  const {
    data: incidentReport,
    mutate: postIncidentReport,
    isSuccess,
    isLoading,
  } = useMutation<any, Error, DCPIIncidentReportFormValue>({
    mutationFn: (payload: DCPIIncidentReportFormValue) =>
      authResponseWrapper<any>(apiClient.postIncidentReport, [payload]),
    ...options,
  });
  return {
    incidentReport,
    postIncidentReport,
    isSuccess,
    isLoading,
  };
}
