import {
  TextareaAutosize as MuiTextareaAutosize,
  TextareaAutosizeProps,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import React, { RefObject, useRef } from 'react';
import { getRandomId } from 'src/utils';
import '../TextArea/styles.scss';
import View from '../View';

const TextareaViewAutoSize: React.FC<CustomTextareaAutosizeProps> = ({
  label,
  className,

  ...props
}) => {
  const id = useRef<string>(`text-area-${getRandomId()}`);

  return (
    <View>
      <Typography variant="h5">{label}</Typography>
      <MuiTextareaAutosize
        id={id.current}
        style={{ border: 'none' }}
        className={cn(className, `cmp-text-area__resize--none`)}
        {...props}
      />
    </View>
  );
};

export type CustomTextareaAutosizeProps = TextareaAutosizeProps & {
  inputRef?: RefObject<HTMLTextAreaElement>;
  iconName?: string;
  label?: string | React.ReactNode;
};

export default TextareaViewAutoSize;
