export enum PATIENT_ITEM_KEY {
  UUID = 'uuid',
  NAME = 'name',
  PATIENT_ID = 'patientId',
  EMAIL = 'email',
  AN = 'attendingNephrologist',
  DOB = 'dob',
  PHONE = 'phone',
  PAYMENT_METHOD = 'paymentMethod',
  STATUS = 'isStatus',
  FIRST_NAME = 'firstName',
  CREATED_AT = 'createdAt',
}

export enum PATIENT_KEY_VALUE {
  PHONE_NUMBER = 'phoneNumber',
  PAYMENT_METHOD = 'paymentMethod',
  PATIENT_ID = 'patientId',
  SEARCH = 'search',
  STATUS = 'status',
}
