import { useQuery, UseQueryOptions } from 'react-query';

import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { AppointmentContent } from './types';

export function useGetAppointmentContent(
  options?: UseQueryOptions<ApiResponseType<AppointmentContent>, Error, AppointmentContent>
) {
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetContent,
  } = useQuery<ApiResponseType<AppointmentContent>, Error, AppointmentContent>(
    [API_QUERIES.APPOINTMENT_INIT],
    {
      queryFn: () =>
        responseWrapper<ApiResponseType<AppointmentContent>>(apiClient.getAppointmentContent),
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: getResponseData,
      ...options,
    }
  );

  const { serviceCategories = [], timeSlots = [] } = data ?? {};

  return {
    data,
    error,
    isError,
    isFetching,
    onGetContent,
    serviceCategories,
    timeSlots,
  };
}
