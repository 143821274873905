import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { RefundRecordsTabResponse } from './types';

export function useGetRefundRecords(
  options?: UseQueryOptions<
    ApiResponseType<RefundRecordsTabResponse>,
    Error,
    RefundRecordsTabResponse
  > & {
    id: string;
  }
) {
  const {
    data: refundRecordsTabDetail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetRefundRecordsTabDetail,
  } = useQuery<ApiResponseType<RefundRecordsTabResponse>, Error, RefundRecordsTabResponse>(
    [API_QUERIES.REFUNDS_RECORDS_TAB, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<RefundRecordsTabResponse>>(
          apiClient.getRefundRecordsTabDetail,
          params
        );
      },
      select: getResponseData,
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateRefundRecordsTabDetail = () =>
    queryClient.invalidateQueries([API_QUERIES.REFUNDS_RECORDS_TAB, { id: options.id }]);

  return {
    refundRecordsTabDetail,
    error,
    isError,
    isLoading,
    onGetRefundRecordsTabDetail,
    handleInvalidateRefundRecordsTabDetail,
  };
}
