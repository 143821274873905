import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toMedicationRecord } from './helpers';
import { MedicationRecord } from './types';

export function useGetMedicationRecordHistory(
  options?: UseQueryOptions<
    ApiResponseType<{ medicationRecordHistory: MedicationRecord }>,
    Error,
    MedicationRecord
  > & {
    id: MedicationRecord['uuid'];
  }
) {
  const {
    data: medicationRecordHistory,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetMedicationRecordHistory,
  } = useQuery<
    ApiResponseType<{ medicationRecordHistory: MedicationRecord }>,
    Error,
    MedicationRecord
  >([API_QUERIES.MEDICATION_RECORD, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ medicationRecordHistory: MedicationRecord }>>(
        apiClient.getMedicationRecordHistory,
        params
      );
    },
    select: ({ data }) => toMedicationRecord(data.medicationRecordHistory),
    enabled: !!options.id,
    ...options,
  });

  return {
    medicationRecordHistory,
    error,
    isError,
    isLoading,
    onGetMedicationRecordHistory,
  };
}
