export enum RefundListingKeyValue {
  ID = 'id',
  PATIENT_NAME = 'patientName',
  PATIENT_ID = 'patientId',
  SEQUENCE_NUMBER = 'sequenceNumber',
  OFFICIAL_RECEIPT_NUMBER = 'officialReceiptNumber',
  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdBy',
  REFUND_REQUEST_STATUS = 'refundRequestStatus',
  INCIDENT_REPORT_STATUS = 'incidentReportStatus',
  STATUS = 'status',
}
