export enum ProblemListKey {
  ID = 'uuid',
  PatientChartCode = 'patientChartCode',
  NameOfPatient = 'patientName',
  Age = 'age',
  Gender = 'sex',
  Diagnosis = 'diagnosis',
  AttendingPhysician = 'attendingPhysician',
  Date = 'date',

  ProblemList = 'problemListInfo',
  DocumentUrls = 'documentUrls',
  UpdatedBy = 'updatedBy',
  DateTime = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export enum ProblemListTableKeyValue {
  ACTIVE_PROBLEMS = 'activeProblems',
  MANAGEMENT = 'management',
  DATE_RESOLVED = 'dateResolved',
}
