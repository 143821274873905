import { UseMutationOptions, useMutation } from 'react-query';
import { handleShowErrorMsg } from 'src/utils/handlerUtils';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { NursePayload } from './types';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function useEditNurse(options?: UseMutationOptions<any, Error, NursePayload>) {
  const {
    mutate: onEditNurse,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, NursePayload>({
    mutationFn: (payload: NursePayload) => responseWrapper(apiClient.editNurse, [payload]),
    onError: (error) => handleShowErrorMsg(error),
    ...options,
  });

  return {
    onEditNurse,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
