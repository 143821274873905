import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TableParams } from 'src/redux/types';
import { MedicationRecord } from './types';

export function useGetMedicationRecordHistories(
  options?: UseQueryOptions<PaginationResponseType<MedicationRecord>, Error> & {
    id: MedicationRecord['uuid'];
  }
) {
  const [params, setParams] = useState<TableParams>({});

  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetMedicationRecordHistories,
  } = useQuery<PaginationResponseType<MedicationRecord>, Error>(
    [API_QUERIES.MEDICATION_RECORD_HISTORIES, { ...params, id: options.id }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        return responseWrapper<PaginationResponseType<MedicationRecord>>(
          apiClient.getMedicationRecordHistories,
          [params]
        );
      },
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateMedicationRecordHistories = () =>
    queryClient.invalidateQueries([API_QUERIES.MEDICATION_RECORD_HISTORIES, { id: options.id }]);

  const { data: medicationRecordHistories, totalRecords } = data || {};

  return {
    medicationRecordHistories,
    error,
    isError,
    isLoading,
    totalRecords,
    setParams,
    onGetMedicationRecordHistories,
    handleInvalidateMedicationRecordHistories,
  };
}
