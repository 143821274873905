import background from 'src/assets/images/background.svg';
import backgroundDiamond from 'src/assets/images/background_diamond.png';
import backgroundDiamondFooter from 'src/assets/images/background_diamond_footer.png';
import backgroundDiamondSmall from 'src/assets/images/background_diamond_small.png';
import defaultAvatar from 'src/assets/images/default-avatar.svg';
import defaultUser from 'src/assets/images/default-user.png';
import documentView from 'src/assets/images/document-view.png';
import empty from 'src/assets/images/empty.png';
import dialysisLogo from 'src/assets/images/icons/DialysisLogo.svg';
import backgroundLogin from 'src/assets/images/icons/Login.png';
import iconCircleMinus from 'src/assets/images/icons/ic-circle-minus.png';
import iconCirclePlus from 'src/assets/images/icons/ic-circle-plus.png';
import iconDashboard from 'src/assets/images/icons/ic-dashboard.svg';
import iconDelete from 'src/assets/images/icons/ic-delete.png';
import iconEdit from 'src/assets/images/icons/ic-edit.png';
import iconRefunds from 'src/assets/images/icons/ic-refund.svg';
import iconAppointment from 'src/assets/images/icons/ic_appointment.svg';
import iconAttendingNephrologist from 'src/assets/images/icons/ic_attending_nephrologist.svg';
import iconChart from 'src/assets/images/icons/ic_chart.svg';
import iconComputer from 'src/assets/images/icons/ic_computer.png';
import iconNurses from 'src/assets/images/icons/ic_nurses.svg';
import iconPeople from 'src/assets/images/icons/ic_people.svg';
import iconSchedule from 'src/assets/images/icons/ic_schedule.svg';
import iconTemplates from 'src/assets/images/icons/ic_templates.svg';
import datahouseLogo from 'src/assets/images/logos/datahouseLogo.svg';
import logo from 'src/assets/images/logos/logo.svg';
import logoAppCollapsed from 'src/assets/images/logos/logoAppCollapsed.svg';

export const IMAGES = {
  logo,
  background,
  backgroundDiamond,
  backgroundDiamondSmall,
  backgroundDiamondFooter,
  defaultUser,
  defaultAvatar,
  iconComputer,
  documentView,
  empty,
  iconEdit,
  iconCirclePlus,
  iconCircleMinus,
  iconDelete,
  iconChart,
  iconPeople,
  iconSchedule,
  iconAppointment,
  iconNurses,
  iconAttendingNephrologist,
  datahouseLogo,
  logoAppCollapsed,
  dialysisLogo,
  backgroundLogin,
  iconDashboard,
  iconRefunds,
  iconTemplates,
};
