import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { isEmpty } from 'src/validations';
import { TableParams } from '../../redux/types';
import apiClient from '../apiClient';
import { NotificationResponse } from './types';

const search = {
  take: 10,
  skip: 0,
};

export function useNotifications(
  options?: UseInfiniteQueryOptions<ApiResponseType<NotificationResponse[]>, Error>
) {
  const [params, setParams] = useState<TableParams>({ isUnRead: false });

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getNotifications,
    fetchNextPage,
  } = useInfiniteQuery<ApiResponseType<NotificationResponse[]>, Error>(
    [API_QUERIES.NOTIFICATIONS, search, params],
    (props): Promise<ApiResponseType<NotificationResponse[]>> => {
      const { pageParam = search } = props;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<ApiResponseType<NotificationResponse[]>>(apiClient.getNotifications, [
        { ...pageParam, ...params },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      // refetchInterval: 15000, // 15 * 1000 mls,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateNotifications = () => {
    queryClient.invalidateQueries([
      API_QUERIES.NOTIFICATIONS,
      search,
      { ...params, isUnRead: true },
    ]);
    queryClient.invalidateQueries([
      API_QUERIES.NOTIFICATIONS,
      search,
      { ...params, isUnRead: false },
    ]);
  };

  const notifications: NotificationResponse[] = useMemo(() => {
    if (isEmpty(data?.pages)) return [];
    return data.pages.reduce((state, page, _pageIdx) => {
      return [...state, ...page.data];
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    notifications,
    error,
    isError,
    loading: isFetching,
    setParams,
    getNotifications,
    fetchNextPage,
    handleInvalidateNotifications,
  };
}
