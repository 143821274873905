import apisauce from 'apisauce';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import appConfig from 'src/appConfig';
import {
  AddNewAppointmentFormValue,
  AdvanceDirectivesFormValues,
  AttendingNephrologistType,
  CXRResult,
  ClinicalAbstract,
  ContinuousFlowSheetFormValue,
  DCPIIncidentReportFormValue,
  GetAvailableDates,
  HemodialysisTreatment,
  HepatitisScreeningResultsFormValue,
  NutritionalAssessmentFormType,
  PatientEducationFormType,
  PatientFormMedicalAndPhysicalFormValue,
  PatientForms,
  PatientGeneralInformationFormValues,
  PhysicianOrder,
  ProblemList,
  ProfilePayload,
  ReUseDialyzersFormType,
  RefundRequestFormValue,
  StandingOrder,
  VaccinationRecordFormValueDetailResponse,
  VaccinationRecordFormValuePayload,
  WaitListConfirm,
} from 'src/queries';
import { ApprovalFormValue } from 'src/queries/ApprovalContainer/types';
import { GetPresignedPayload, UploadFilePayload } from 'src/queries/File/types';
import { NursePayload } from 'src/queries/NurseForm/types';
import { MedicationRecordPayload } from 'src/queries/PatientChartForms/MedicationRecord';
import { TreatmentSheet } from 'src/queries/PatientChartForms/TreatmentSheetForm';
import { GetPresignedMedicalAndPhysicalPayload } from 'src/queries/PatientChartForms/types';
import {
  AppointmentEditPayload,
  DeleteTreatmentSchedulePayload,
  TreatmentPayload,
} from 'src/queries/PatientSchedule';
import { NewRefundRequestValue } from 'src/queries/RefundsContainer/NewRefundRequest/types';
import { ChargeSlipPayload, RequisitionFormValue } from 'src/queries/TreatmentHistory';
import { OfficialReceiptValue } from 'src/queries/TreatmentHistory/OfficialReceiptForms';
import { AddTreatmentPatients } from 'src/queries/TreatmentHistory/PatientChart';
import { GuaranteeLetterPayload, SOAFormValuePayload } from 'src/queries/TreatmentHistory/SOAForms';
import {
  ChangePasswordPayload,
  CompleteNewPasswordPayload,
  ConfirmPasswordPayload,
  ConfirmSignInPayload,
  ConfirmSignUpPayload,
  ForgotPasswordPayload,
  ResendSignUpPayload,
  SignInPayload,
  SignUpPayload,
  SubmitForgotPasswordPayload,
} from 'src/queries/UAM/types';
import { AddUserPayload, User } from 'src/queries/Users/types';
import { TableParams } from 'src/redux/types';
import { newCancelToken, stringify } from 'src/utils';
import { TokenService } from '.';

axios.defaults.withCredentials = true;

const create = (baseURL = appConfig.API_URL) => {
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 0,
      Accept: 'application/json',
    },
    timeout: appConfig.CONNECTION_TIMEOUT,
  });

  api.axiosInstance.interceptors.request.use((config) =>
    TokenService.getToken()
      .then((token) => {
        config.headers.Authorization = `Bearer ${token}`;
        return Promise.resolve(config);
      })
      .catch(() => Promise.resolve(config))
  );
  const getRoot = () => api.get('');

  // ====================== Auth ======================
  const signIn = (body: SignInPayload) => Auth.signIn(body.username, body.password);

  const signUp = (body: SignUpPayload) => {
    const params = {
      username: body.username,
      password: body.password,
    };

    const attributes = {
      email: body.email,
      given_name: body.firstName,
      family_name: body.lastName,
      middle_name: body.middleName,
      birthdate: body.dateOfBirth,
      'custom:ssn': body.socialSecurityNumber,
      'custom:user_type': 'CLAIMANT',
    };

    return Auth.signUp({ ...params, attributes });
  };
  const confirmSignUp = (body: ConfirmSignUpPayload) =>
    Auth.confirmSignUp(body.username, body.code);
  const signOut = () => Auth.signOut();

  const forgotPassword = (body: ForgotPasswordPayload) => Auth.forgotPassword(body.username);

  const submitForgotPassword = (body: SubmitForgotPasswordPayload) =>
    Auth.forgotPasswordSubmit(body.email, body.token, body.password);

  const changePassword = (body: ChangePasswordPayload) =>
    Auth.changePassword(body.user, body.currentPassword, body.newPassword);

  const confirmSignIn = (body: ConfirmSignInPayload) =>
    Auth.sendCustomChallengeAnswer(body.user, body.code);

  const confirmPassword = (password: ConfirmPasswordPayload) => {
    return Auth.currentAuthenticatedUser().then((user) =>
      Auth.signIn({
        username: user.username,
        password: password.password,
      })
    );
  };

  const completeNewPassword = (body: CompleteNewPasswordPayload) =>
    Auth.completeNewPassword(body.user, body.password, body.requiredAttributes);
  const resendSignUp = (body: ResendSignUpPayload) => Auth.resendSignUp(body.username);
  // ====================== Profile ======================
  const getUserId = (params: { username: string }) => {
    const username = { username: params.username };
    const queryString = stringify(username);
    return api.get(`/account-svc/v1/users/user-id?${queryString}`, {}, newCancelToken());
  };

  // ====================== Profile ======================
  const getMyProfile = () => api.get('/account-svc/v1/me', {}, newCancelToken());

  const updateUserAvatar = (body: { avatarUrl: string }) =>
    api.patch(`/me/avatar`, body, newCancelToken());

  const updateMyProfile = (body: ProfilePayload) =>
    api.put(`/account-svc/v1/me`, body, newCancelToken());

  // ====================== Content ======================
  const getContents = () => api.get('/account-svc/v1/contents', {}, newCancelToken());

  // ======================= Version =======================

  const getVersion = () => api.get('/api-svc/v1/version', {}, newCancelToken());

  // ====================== File ======================
  const getPresignedUserServiceUrl = (params: GetPresignedPayload) => {
    return api.get('/file-svc/v1/presigned-upload-url', params, newCancelToken());
  };

  const uploadFile = (body: UploadFilePayload) =>
    axios.put(body.url, body.fileData, {
      headers: { 'Content-Type': body.contentType },
      withCredentials: false,
    });

  const getDecodeUserServiceUrl = (params: { filePath: string }) =>
    api.get('/api-svc/v1/files/presigned-download-url', params, newCancelToken());

  const uploadFileWithProgress = (body: UploadFilePayload) =>
    axios.put(body.url, body.fileData, {
      onUploadProgress: (progress) => {
        const { loaded, total } = progress;
        const percentageProgress = Math.floor((loaded / total) * 100);
        body.setProgress(percentageProgress);
      },
    });

  // ====================== General Information Form ======================
  const getGeneralInformationForm = (params: TableParams) =>
    api.get(`/api-svc/v1/patient-general-info/${params.uuid}`, {}, newCancelToken());

  const createGeneralInformationForm = (payload: PatientGeneralInformationFormValues) => {
    return api.post('/api-svc/v1/patient-general-info', { ...payload }, newCancelToken());
  };

  const updateGeneralInformationForm = (payload: PatientGeneralInformationFormValues) => {
    const id = payload?.uuid;
    return api.put(`/api-svc/v1/patient-general-info/${id}`, { ...payload }, newCancelToken());
  };

  // ====================== System Accounts ======================

  const searchUserAccounts = (params: { search: string }) => {
    const queryString = stringify(params);
    return api.get(`/users/search?${queryString}`, {}, newCancelToken());
  };
  const searchUserAccountsAxios = (params: { search: string; skip: number; take: number }) => {
    const queryString = stringify(params);
    return api.get(`${appConfig.API_URL}/users/search?${queryString}`, {}, newCancelToken());
  };
  const searchUserAccountsByOrderAxios = (params: {
    search: string;
    skip: number;
    take: number;
    order: string;
  }) => {
    const queryString = stringify(params);
    return api.get(`${appConfig.API_URL}/users/search?${queryString}`, {}, newCancelToken());
  };

  // ====================== System Accounts ======================
  const getMyPermissions = () => api.get('/account-svc/v1/permissions/me', {}, newCancelToken());

  // ====================== Users Management ======================
  const getAllUsers = (params: TableParams) => api.get(`account-svc/v1/users?${stringify(params)}`);

  const getUser = (params: { id: User['id'] }) => {
    return api.get(`/account-svc/v1/users/${params.id}`, {}, newCancelToken());
  };

  const createUser = (payload: AddUserPayload) => {
    return api.post(
      `/account-svc/v1/users`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const deleteUser = (userId: User['id']) => {
    return api.delete(`/account-svc/v1/users/${userId}`, {}, newCancelToken());
  };

  const searchUsers = (params: TableParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/users/search/delegates?${queryString}`, {}, newCancelToken());
  };

  const searchProjects = (params: TableParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/projects?${queryString}`, {}, newCancelToken());
  };

  // ============== Upload File =============================
  const getPresignedDownloadFileUrl = (params) => {
    return api.get('/api-svc/v1/files/presigned-download-url', params, newCancelToken());
  };

  const getPresignedUploadFile = (params) => {
    return api.get('/api-svc/v1/files/presigned-upload-url', params, newCancelToken());
  };

  // ==============  Patient Chart  =============
  const getPatientChartsListing = (params: TableParams) => {
    const queryString = stringify(params);
    return api.get(`api-svc/v1/patient-charts?${queryString}`, {}, newCancelToken());
  };

  const getPatientChartById = (params: { id: string }) => {
    return api.get(`api-svc/v1/patient-charts/${params.id}`, {}, newCancelToken());
  };

  // ============ Patient Form ===========

  const getPatientFormsUploadFilePresignedDownloadUrl = (
    params: GetPresignedMedicalAndPhysicalPayload
  ) => {
    return api.get(`api-svc/v1/files/presigned-upload-url`, params, newCancelToken());
  };

  // TODO: add type
  const getPatientFormsDownloadUrl = (params: any) => {
    return api.get(`api-svc/v1/files/presigned-download-url`, params, newCancelToken());
  };

  const deletePatientChart = (params: { id: string }) => {
    return api.delete(`api-svc/v1/patient-charts/${params.id}`, {}, newCancelToken());
  };

  // ==============  ReUse Dialyzers Form  =============
  const getReUseDialyzersForm = (params: TableParams) =>
    api.get(`/api-svc/v1/reuse-of-dialyzers-forms/${params.id}`, {}, newCancelToken());

  const createReUseDialyzersForm = (body: ReUseDialyzersFormType) => {
    return api.post('/api-svc/v1/reuse-of-dialyzers-forms', { ...body }, newCancelToken());
  };

  const updateReUseDialyzersForm = (body: ReUseDialyzersFormType) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/reuse-of-dialyzers-forms/${id}`, body, newCancelToken());
  };

  const getReUseDialyzersHistories = (params: TableParams) =>
    api.get(
      `/api-svc/v1/reuse-of-dialyzers-forms/${params.id}/histories?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getReUseDialyzersHistory = (params: TableParams) =>
    api.get(`/api-svc/v1/reuse-of-dialyzers-forms/histories/${params.id}`, {}, newCancelToken());

  // ==============  Nutritional Assessment Form  =============
  const getNutritionalAssessmentForm = (params: TableParams) =>
    api.get(`/api-svc/v1/nutritional-assessment-forms/${params.id}`, {}, newCancelToken());

  const createNutritionalAssessmentForm = (body: NutritionalAssessmentFormType) => {
    return api.post('/api-svc/v1/nutritional-assessment-forms', { ...body }, newCancelToken());
  };

  const updateNutritionalAssessmentForm = (body: NutritionalAssessmentFormType) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/nutritional-assessment-forms/${id}`, body, newCancelToken());
  };

  const getNutritionalAssessmentHistories = (params: TableParams) =>
    api.get(
      `/api-svc/v1/nutritional-assessment-forms/${params.id}/histories?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getNutritionalAssessmentHistory = (params: TableParams) =>
    api.get(
      `/api-svc/v1/nutritional-assessment-forms/histories/${params.id}`,
      {},
      newCancelToken()
    );

  // ==============  Patient Education Form  =============
  const getPatientEducationForm = (params: TableParams) =>
    api.get(`/api-svc/v1/patient-education-forms/${params.id}`, {}, newCancelToken());

  const createPatientEducationForm = (body: PatientEducationFormType) => {
    return api.post('/api-svc/v1/patient-education-forms', { ...body }, newCancelToken());
  };

  const updatePatientEducationForm = (body: PatientEducationFormType) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/patient-education-forms/${id}`, body, newCancelToken());
  };

  const getPatientEducationHistories = (params: TableParams) =>
    api.get(
      `/api-svc/v1/patient-education-forms/${params.id}/histories?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getPatientEducationHistory = (params: TableParams) =>
    api.get(`/api-svc/v1/patient-education-forms/histories/${params.id}`, {}, newCancelToken());

  // ========== Medical and Physical ===========
  const getMedicalAndPhysicalDetail = (param: { uuid: string }) => {
    return api.get(
      `/api-svc/v1/medical-history-and-physical-examination-forms/${param.uuid}`,
      {},
      newCancelToken()
    );
  };

  const createMedicalAndPhysical = (payload: PatientFormMedicalAndPhysicalFormValue) => {
    return api.post(
      `/api-svc/v1/medical-history-and-physical-examination-forms`,
      { ...payload },
      newCancelToken()
    );
  };

  const updateMedicalAndPhysical = (payload: PatientFormMedicalAndPhysicalFormValue) => {
    return api.put(
      `/api-svc/v1/medical-history-and-physical-examination-forms/${payload.uuid}`,
      { ...payload },
      newCancelToken()
    );
  };

  // ============= Continuous Flow Sheet ========
  const createContinuousFlowSheet = (payload: ContinuousFlowSheetFormValue) => {
    return api.post(`/api-svc/v1/continuous-flow-sheets-forms`, { ...payload }, newCancelToken());
  };

  const updateContinuousFlowSheet = (payload: ContinuousFlowSheetFormValue) => {
    return api.put(
      `/api-svc/v1/continuous-flow-sheets-forms/${payload.uuid}`,
      { ...payload },
      newCancelToken()
    );
  };

  const getContinuousFlowSheetDetail = (params: { uuid: string; dateFilter }) => {
    const queryString = stringify(params.dateFilter);
    return api.get(
      `/api-svc/v1/continuous-flow-sheets-forms/${params.uuid}?${queryString}`,
      {},
      newCancelToken()
    );
  };

  // ==========  Hepatitis Screening Results  ===========
  const createHepatitisScreeningResults = (payload: HepatitisScreeningResultsFormValue) => {
    return api.post(
      `/api-svc/v1/hepatitis-screening-result-forms`,
      { ...payload },
      newCancelToken()
    );
  };

  const updateHepatitisScreeningResults = (payload: ContinuousFlowSheetFormValue) => {
    return api.put(
      `/api-svc/v1/hepatitis-screening-result-forms/${payload.uuid}`,
      { ...payload },
      newCancelToken()
    );
  };

  const getHepatitisScreeningResultsDetail = (param: { uuid: string }) => {
    return api.get(
      `/api-svc/v1/hepatitis-screening-result-forms/${param.uuid}`,
      {},
      newCancelToken()
    );
  };

  const getHepatitisScreeningResultsHistory = (params: TableParams) => {
    return api.get(
      `/api-svc/v1/hepatitis-screening-result-forms/histories/${params.uuid}`,
      {},
      newCancelToken()
    );
  };

  const getHepatitisScreeningResultsHistories = (params: TableParams) => {
    const queryString = stringify(params, ['uuid']);
    return api.get(
      `/api-svc/v1/hepatitis-screening-result-forms/${params.uuid}/histories?${queryString}}`,
      {},
      newCancelToken()
    );
  };

  // ========== Patients ==========
  const getPatientOptions = (params: TableParams) => {
    const queryString = stringify(params);
    return api.get(`api-svc/v1/patients?${queryString}`, newCancelToken());
  };

  const addPatients = (payload: { patientId: string }) => {
    return api.post(`api-svc/v1/patient-charts`, payload, newCancelToken());
  };

  const getPatient = (params) => {
    return api.get(`/api-svc/v1/patients/${params?.id}`, {}, newCancelToken());
  };

  const updatePatient = (body: PatientForms) => {
    const id = body.id;
    return api.put(`/api-svc/v1/patients/${id}`, body, newCancelToken());
  };

  // ============= Patients Record ==========
  const getTreatmentRecord = (params: TableParams) => {
    return api.get(
      `api-svc/v1/treatments/patientId=${params.id}?${stringify(params)}`,
      newCancelToken()
    );
  };
  const createTreatmentRecord = (payload: { patientId: string }) => {
    return api.post(`api-svc/v1/treatments`, payload, newCancelToken());
  };

  // ========== ClinicalAbstract ==========
  const createClinicalAbstract = (payload: { patientId: string }) => {
    return api.post(`api-svc/v1/clinical-abstract-forms`, payload, newCancelToken());
  };

  const updateClinicalAbstract = (body: ClinicalAbstract) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/clinical-abstract-forms/${id}`, body, newCancelToken());
  };

  const getClinicalAbstract = (params: TableParams) =>
    api.get(`/api-svc/v1/clinical-abstract-forms/${params?.uuid}`, {}, newCancelToken());

  const getAllClinicalAbstractHistory = (uuid: string, params) => {
    return api.get(
      `/api-svc/v1/clinical-abstract-forms/${uuid}/histories?${stringify(params)}`,
      {},
      newCancelToken()
    );
  };

  const getClinicalAbstractHistory = (params: TableParams) => {
    return api.get(
      `/api-svc/v1/clinical-abstract-forms/histories/${params?.uuid}`,
      {},
      newCancelToken()
    );
  };

  // ========== Physician Order Forms ==========
  const getPhysicianOrder = (params: TableParams) =>
    api.get(`/api-svc/v1/physician-order-forms/${params.id}`, {}, newCancelToken());

  const createPhysicianOrder = (body: PhysicianOrder) => {
    return api.post('/api-svc/v1/physician-order-forms', body, newCancelToken());
  };

  const editPhysicianOrder = (body: PhysicianOrder) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/physician-order-forms/${id}`, body, newCancelToken());
  };

  const getPhysicianOrderHistories = (params: TableParams) =>
    api.get(
      `/api-svc/v1/physician-order-forms/${params.id}/histories?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getPhysicianOrderHistory = (params: TableParams) =>
    api.get(`/api-svc/v1/physician-order-forms/histories/${params.id}`, {}, newCancelToken());

  // ========== Standing Order ==========

  const getStandingOrder = (params: TableParams) =>
    api.get(`/api-svc/v1/standing-order-forms/${params.id}`, {}, newCancelToken());

  const createStandingOrder = (body: StandingOrder) => {
    return api.post('/api-svc/v1/standing-order-forms', body, newCancelToken());
  };

  const editStandingOrder = (body: StandingOrder) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/standing-order-forms/${id}`, body, newCancelToken());
  };

  const getStandingOrderHistories = (id: string, params) =>
    api.get(
      `/api-svc/v1/standing-order-forms/${id}/histories?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getStandingOrderHistory = (params: TableParams) =>
    api.get(`/api-svc/v1/standing-order-forms/histories/${params.id}`, {}, newCancelToken());

  // ========== Monthly Laboratory Result ==========

  const getMonthlyLaboratoryResult = (params: TableParams) =>
    api.get(`/api-svc/v1/monthly-laboratory-result-forms/${params.id}`, {}, newCancelToken());

  const createMonthlyLaboratoryResult = (body: StandingOrder) => {
    return api.post('/api-svc/v1/monthly-laboratory-result-forms', body, newCancelToken());
  };

  const editMonthlyLaboratoryResult = (body: StandingOrder) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/monthly-laboratory-result-forms/${id}`, body, newCancelToken());
  };

  const getMonthlyLaboratoryResultHistories = (id: string, params) =>
    api.get(
      `/api-svc/v1/monthly-laboratory-result-forms/${id}/histories?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getMonthlyLaboratoryResultHistory = (params: TableParams) =>
    api.get(
      `/api-svc/v1/monthly-laboratory-result-forms/histories/${params.id}`,
      {},
      newCancelToken()
    );

  // ========== Problem List ==========

  const getProblemList = (params: TableParams) =>
    api.get(`/api-svc/v1/problem-list-forms/${params.id}`, {}, newCancelToken());

  const createProblemList = (body: ProblemList) => {
    return api.post('/api-svc/v1/problem-list-forms', body, newCancelToken());
  };

  const editProblemList = (body: ProblemList) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/problem-list-forms/${id}`, body, newCancelToken());
  };

  const getProblemListHistories = (id: string, params) =>
    api.get(
      `/api-svc/v1/problem-list-forms/${id}/histories?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getProblemListHistory = (params: TableParams) =>
    api.get(`/api-svc/v1/problem-list-forms/histories/${params.id}`, {}, newCancelToken());

  // ========== CXR Result ==========

  const getCXRResult = (params: TableParams) =>
    api.get(`/api-svc/v1/cxr-result-forms/${params.id}`, {}, newCancelToken());

  const createCXRResult = (body: CXRResult) => {
    return api.post('/api-svc/v1/cxr-result-forms', body, newCancelToken());
  };

  const editCXRResult = (body: CXRResult) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/cxr-result-forms/${id}`, body, newCancelToken());
  };

  const getCXRResultHistories = (id: string, params) =>
    api.get(
      `/api-svc/v1/cxr-result-forms/${id}/histories?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getCXRResultHistory = (params: TableParams) =>
    api.get(`/api-svc/v1/cxr-result-forms/histories/${params.id}`, {}, newCancelToken());

  // ========== Hemodialysis Treatment Form ==========
  const getHemodialysisTreatment = (params: TableParams) =>
    api.get(`/api-svc/v1/hemodialysis-treatments/${params.id}`, {}, newCancelToken());

  const createHemodialysisTreatment = (body: HemodialysisTreatment) => {
    return api.post('/api-svc/v1/hemodialysis-treatments', body, newCancelToken());
  };

  const editHemodialysisTreatment = (body: HemodialysisTreatment) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/hemodialysis-treatments/${id}`, body, newCancelToken());
  };

  const getHemodialysisTreatmentHistories = (params: TableParams) =>
    api.get(
      `/api-svc/v1/hemodialysis-treatment/${params.id}/histories?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getHemodialysisTreatmentHistory = (params: TableParams) =>
    api.get(`/api-svc/v1/hemodialysis-treatment/histories/${params.id}`, {}, newCancelToken());

  // ========== Medication Record Forms ==========
  const getMedicationRecord = (params: TableParams) =>
    api.get(`/api-svc/v1/medication-order-forms/${params.id}`, {}, newCancelToken());

  const createMedicationRecord = (body: MedicationRecordPayload) => {
    return api.post('/api-svc/v1/medication-order-forms', body, newCancelToken());
  };

  const editMedicationRecord = (body: MedicationRecordPayload) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/medication-order-forms/${id}`, body, newCancelToken());
  };

  const getMedicationRecordHistories = (params: TableParams) =>
    api.get(
      `/api-svc/v1/medication-order-forms/${params.id}/histories?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getMedicationRecordHistory = (params: TableParams) =>
    api.get(`/api-svc/v1/medication-order-forms/histories/${params.id}`, {}, newCancelToken());

  // ==============  Advance Directives Form  =============
  const getAdvanceDirectivesForm = (params: { uuid: string }) => {
    return api.get(`/api-svc/v1/advance-directive-forms/${params.uuid}`, {}, newCancelToken());
  };

  const createAdvanceDirectivesForm = (body: AdvanceDirectivesFormValues) => {
    return api.post(`/api-svc/v1/advance-directive-forms`, { ...body }, newCancelToken());
  };

  const updateAdvanceDirectivesForm = (body: AdvanceDirectivesFormValues) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/advance-directive-forms/${id}`, body, newCancelToken());
  };

  const getAdvanceDirectivesHistories = (params: TableParams) => {
    const queryString = stringify(params, ['uuid']);
    return api.get(
      `/api-svc/v1/advance-directive-forms/${params.uuid}/histories?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getAdvanceDirectivesHistoryDetail = (params: { uuid: string }) => {
    return api.get(
      `/api-svc/v1/advance-directive-forms/histories/${params.uuid}`,
      {},
      newCancelToken()
    );
  };

  // ========== Treatment Sheet ==========
  const getTreatmentSheet = (params: TableParams) =>
    api.get(`/api-svc/v1/treatment-sheet-forms/${params.id}`, {}, newCancelToken());

  const createTreatmentSheet = (body: TreatmentSheet) => {
    return api.post('/api-svc/v1/treatment-sheet-forms', body, newCancelToken());
  };

  const editTreatmentSheet = (body: TreatmentSheet) => {
    const id = body.uuid;
    return api.put(`/api-svc/v1/treatment-sheet-forms/${id}`, body, newCancelToken());
  };

  const getTreatmentSheetHistories = (params: TableParams) =>
    api.get(
      `/api-svc/v1/treatment-sheet-forms/${params.id}/histories?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getTreatmentSheetHistory = (params: TableParams) =>
    api.get(`/api-svc/v1/treatment-sheet-forms/histories/${params.id}`, {}, newCancelToken());

  // ========== Vaccination Record ========
  const createVaccinationRecord = (payload: VaccinationRecordFormValuePayload) => {
    return api.post(`/api-svc/v1/vaccination-records`, { ...payload }, newCancelToken());
  };

  const updateVaccinationRecord = (payload: VaccinationRecordFormValueDetailResponse) => {
    return api.put(
      `/api-svc/v1/vaccination-records/${payload.uuid}`,
      { ...payload },
      newCancelToken()
    );
  };

  const getVaccinationRecordDetail = (params: { uuid: string }) => {
    return api.get(`/api-svc/v1/vaccination-records/${params.uuid}`, {}, newCancelToken());
  };

  const getVaccinationRecordHistory = (params: TableParams) => {
    return api.get(
      `/api-svc/v1/historical-vaccination-records/${params.uuid}`,
      {},
      newCancelToken()
    );
  };

  const getVaccinationRecordHistories = (params: TableParams) => {
    const queryString = stringify(params, ['uuid']);
    return api.get(
      `/api-svc/v1/vaccination-records/${params.uuid}/histories?${queryString}}`,
      {},
      newCancelToken()
    );
  };

  // ========================= Nurses===========================
  const getNurses = (params) => {
    return api.get(`/api-svc/v1/nurses?${stringify(params)}`, {}, newCancelToken());
  };

  const createNurse = (body: NursePayload) => {
    return api.post('/api-svc/v1/nurses', body, newCancelToken());
  };

  // TODO: Tuyen Tran update type
  const editNurse = (body: any) => {
    const { uuid: nurseId, ...payload } = body;
    return api.put(`/api-svc/v1/nurses/${nurseId}`, { ...payload }, newCancelToken());
  };

  const getNurseById = (params: { id: string }) => {
    return api.get(`/api-svc/v1/nurses/${params.id}`, {}, newCancelToken());
  };

  // ========== Appointments ========
  const getAllAppointmentListing = (params: TableParams) => {
    const queryString = stringify(params);
    return api.get(`/api-svc/v1/appointments?${queryString}`, {}, newCancelToken());
  };

  const createAppointmentForm = (payload: AddNewAppointmentFormValue) => {
    return api.post(`api-svc/v1/appointments`, { ...payload }, newCancelToken());
  };

  const getAppointmentFormDetail = (params: { uuid: string }) => {
    return api.get(`/api-svc/v1/appointments/${params.uuid}`, {}, newCancelToken());
  };

  const getAppointmentContent = () => api.get('api-svc/v1/initial-appointments', newCancelToken());

  const updateAppointments = (payload: AddNewAppointmentFormValue) => {
    return api.put(`/api-svc/v1/appointments/${payload.id}`, { ...payload }, newCancelToken());
  };

  const getWaitLists = (params: TableParams) =>
    api.get(`/api-svc/v1/appointments/waitlists?${stringify(params)}`, {}, newCancelToken());

  const updateAppointmentConfirmable = (payload: WaitListConfirm) =>
    api.put(`/api-svc/v1/appointments/${payload.id}/confirmable`, { ...payload }, newCancelToken());

  const getAvailableDates = (params: GetAvailableDates) => {
    return api.get(
      `/api-svc/v1/initial-appointments/time-slots/${params.timeSlotId}/available-dates/${params.date}`,
      {},
      newCancelToken()
    );
  };

  // ==============  Attending Nephrologist Form  =============
  const getAllAttendingNephrologists = (params) => {
    return api.get(`/api-svc/v1/doctors?${stringify(params)}`, {}, newCancelToken());
  };

  const getAttendingNephrologist = (params) =>
    api.get(`/api-svc/v1/doctors/${params.id}`, {}, newCancelToken());

  const createAttendingNephrologist = (body: AttendingNephrologistType) => {
    return api.post(`/api-svc/v1/doctors`, { ...body }, newCancelToken());
  };

  const updateAttendingNephrologist = (body: AttendingNephrologistType) => {
    const id = body.id;
    return api.put(`/api-svc/v1/doctors/${id}`, body, newCancelToken());
  };
  // ================= Patient Schedule ======================
  const getTreatmentSchedules = (params: TableParams) => {
    const queryString = stringify(params);
    return api.get(`/api-svc/v1/treatment-schedules?${queryString}`, {}, newCancelToken());
  };

  const createTreatmentSchedules = (payload: TreatmentPayload) => {
    return api.post(`api-svc/v1/treatment-schedules`, { ...payload }, newCancelToken());
  };

  const getTreatmentScheduleDetail = (params: { id: string; date: string }) => {
    return api.get(
      `/api-svc/v1/treatment-schedules/${params.id}/sequences?date=${params.date}`,
      {},
      newCancelToken()
    );
  };

  const updateTreatmentSchedulesSequences = (body: AppointmentEditPayload) => {
    const { treatmentId, ...payload } = body || {};
    return api.put(
      `/api-svc/v1/treatment-schedules/${treatmentId}/sequences`,
      { ...payload },
      newCancelToken()
    );
  };

  const updateTreatmentSchedules = (body: TreatmentPayload) => {
    const { treatmentId, ...payload } = body || {};
    return api.put(
      `/api-svc/v1/treatment-schedules/${treatmentId}`,
      { ...payload },
      newCancelToken()
    );
  };

  const deleteTreatmentScheduleSequences = (body: DeleteTreatmentSchedulePayload) => {
    const { treatmentId } = body;
    return api.delete(`/api-svc/v1/treatment-schedules/${treatmentId}`, {}, newCancelToken());
  };

  const deleteThisAppointment = (body: DeleteTreatmentSchedulePayload) => {
    const { treatmentId, selectedDate } = body;
    return api.delete(
      `/api-svc/v1/treatment-schedules/${treatmentId}/sequences/${selectedDate}`,
      {},
      newCancelToken()
    );
  };

  // =================== notification =========================
  const getNotifications = () => api.get('api-svc/v1/notification', {}, newCancelToken());

  // =================== Treatment History =========================
  const getProducts = (params) => {
    const queryString = stringify(params);
    return api.get(`api-svc/v1/products?${queryString}`, {}, newCancelToken());
  };
  const getPackages = (params) => {
    const queryString = stringify(params);
    return api.get(`api-svc/v1/packages?${queryString}`, {}, newCancelToken());
  };

  const getPackageItems = (id: string) =>
    api.get(`api-svc/v1/packages/${id}/package-items`, {}, newCancelToken());

  //=================== Requisition =========================
  const getRequisition = (params: { id: string }) =>
    api.get(`api-svc/v1/treatments/${params.id}/requisition`, {}, newCancelToken());

  const postRequisitionForm = (payload: RequisitionFormValue) =>
    api.put(
      `api-svc/v1/treatments/${payload.treatmentId}/requisition`,
      { ...payload },
      newCancelToken()
    );

  const getRequisitionExport = (params: { refundId: string }) => {
    return api.get(
      `api-svc/v1/treatments/${params.refundId}/requisition/export`,
      {},
      newCancelToken()
    );
  };

  // ============ Treatment Record =============
  // ============ Patient Char ============
  const getTreatmentsPatientCharts = (params: { patientId: string; treatmentId: string }) => {
    return api.get(
      `api-svc/v1/treatments/get-patient-chart/${params.treatmentId}/${params.patientId}`,
      {},
      newCancelToken()
    );
  };

  const addTreatmentsPatientCharts = (payload: AddTreatmentPatients) => {
    return api.post(
      `api-svc/v1/treatments/${payload.treatmentId}/${payload.patientId}`,
      { ...payload },
      newCancelToken()
    );
  };
  const getActivityLog = () => api.get('api-svc/v1/treatments/activity-log', {}, newCancelToken());

  // ================== Treatment Record =======================

  // ================== Charge Slip =================
  const getTreatmentRecordChargeSlip = (params: { treatmentId: string }) => {
    return api.get(
      `/api-svc/v1/treatments/${params.treatmentId}/charge-slip`,
      {},
      newCancelToken()
    );
  };

  const updateTreatmentsChargeSlip = (payload: ChargeSlipPayload) => {
    const { treatmentId, ...payloadFormValues } = payload || {};
    return api.put(
      `/api-svc/v1/treatments/${treatmentId}/charge-slip`,
      { ...payloadFormValues },
      newCancelToken()
    );
  };

  // get refund export
  const getChargeSlipExport = (params: { treatmentId: string }) => {
    return api.get(
      `api-svc/v1/treatments/${params.treatmentId}/charge-slip/export`,
      {},
      newCancelToken()
    );
  };

  // ================== Official Receipt ==================
  const getOfficialReceipt = (params: { id: string }) =>
    api.get(`api-svc/v1/treatments/${params.id}/official-receipt`, {}, newCancelToken());

  const postOfficialReceipt = (payload: OfficialReceiptValue) =>
    api.put(
      `api-svc/v1/treatments/${payload.treatmentId}/official-receipt`,
      { ...payload },
      newCancelToken()
    );
  // ================ Refunds =================
  const getRefundRecordsTabDetail = (payload: { id: string }) => {
    return api.get(`api-svc/v1/refund-records/${payload.id}`, {}, newCancelToken());
  };

  const getRefundRecordsListing = (params: TableParams) => {
    const queryString = stringify(params);
    return api.get(`api-svc/v1/refund-records?${queryString}`, {}, newCancelToken());
  };

  const postNewRefundRequest = (payload: NewRefundRequestValue) =>
    api.post(`api-svc/v1/refund-records`, { ...payload }, newCancelToken());

  const createRequestForm = (payload: RefundRequestFormValue) => {
    const refundId = payload?.refundId;
    return api.post(
      `api-svc/v1/refunds/${refundId}/request-form`,
      { ...payload },
      newCancelToken()
    );
  };

  const editRequestForm = (payload: RefundRequestFormValue) => {
    const refundId = payload?.refundId;
    return api.put(`api-svc/v1/refunds/${refundId}/request-form`, { ...payload }, newCancelToken());
  };

  const getRequestFormDetail = (payload: { id: string }) => {
    return api.get(`api-svc/v1/refunds/${payload.id}/request-form`, {}, newCancelToken());
  };

  const getIncidentReport = (payload: { id: string }) => {
    return api.get(`api-svc/v1/refunds/${payload.id}/incident-report`, {}, newCancelToken());
  };

  const postIncidentReport = (payload: DCPIIncidentReportFormValue) =>
    api.post(
      `api-svc/v1/refunds/${payload.refundId}/incident-report`,
      { ...payload },
      newCancelToken()
    );
  const updateIncidentReport = (payload: DCPIIncidentReportFormValue) =>
    api.put(
      `api-svc/v1/refunds/${payload.refundId}/incident-report`,
      { ...payload },
      newCancelToken()
    );

  // get incident export
  const getIncidentExport = (params: { refundId: string }) => {
    return api.get(
      `api-svc/v1/refunds/${params.refundId}/incident-report/export`,
      {},
      newCancelToken()
    );
  };

  // get refund export
  const getRefundExport = (params: { refundId: string }) => {
    return api.get(
      `api-svc/v1/refunds/${params.refundId}/refund-request/export`,
      {},
      newCancelToken()
    );
  };

  // =========================== SOA ===========================================
  const getTreatmentStatementOfAccount = (param: { id: string }) => {
    return api.get(`/api-svc/v1/treatments/${param.id}/statement-of-account`, {}, newCancelToken());
  };

  const updateTreatmentStatementOfAccount = (payload: SOAFormValuePayload) => {
    return api.put(
      `/api-svc/v1/treatments/${payload.treatmentId}/statement-of-account`,
      { ...payload },
      newCancelToken()
    );
  };

  // get refund export
  const getTreatmentStatementOfAccountExport = (params: { treatmentId: string }) => {
    return api.get(
      `api-svc/v1/treatments/${params.treatmentId}/statement-of-account/export`,
      {},
      newCancelToken()
    );
  };

  // ===================== Guarantee Letters ===========================

  const createGuaranteeLetter = (payload: GuaranteeLetterPayload) =>
    api.post(`/api-svc/v1/treatments/guarantee-letters`, { ...payload }, newCancelToken());

  const getGuaranteeLetter = (params: { id: string }) =>
    api.get(`/api-svc/v1/treatments/guarantee-letters/${params.id}`, {}, newCancelToken());

  // ==================== Approval ==================
  const createApprovalForm = (payload: ApprovalFormValue) => {
    return api.post(`/api-svc/v1/approval`, { ...payload }, newCancelToken());
  };

  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  //
  // Notice we're not returning back the `api` created in step 1. That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    getRoot,
    // ====================== Auth ======================
    // getPermission,
    getMyProfile,
    confirmSignIn,
    signIn,
    signUp,
    resendSignUp,
    confirmSignUp,
    signOut,
    forgotPassword,
    submitForgotPassword,
    changePassword,
    // setPreferredMfa,
    completeNewPassword,

    // ====================== File ======================
    getPresignedUserServiceUrl,
    uploadFile,
    uploadFileWithProgress,
    getDecodeUserServiceUrl,

    // ====================== Content ======================
    getContents,

    //  =================== Api Version ===================
    getVersion,

    // ====================== Users ======================
    getUserId,

    // ===================== Patient Chart Listing ==============
    getPatientChartsListing,

    // Patient Chart
    getPatientChartById,

    // ====================  Patient Chart Forms ============
    getPatientFormsUploadFilePresignedDownloadUrl,
    getPatientFormsDownloadUrl,
    deletePatientChart,

    // ====================== ReUse Dialyzers Form ======================
    getReUseDialyzersForm,
    createReUseDialyzersForm,
    updateReUseDialyzersForm,
    getReUseDialyzersHistories,
    getReUseDialyzersHistory,

    // ====================== Nutritional Assessment Form ======================
    getNutritionalAssessmentForm,
    createNutritionalAssessmentForm,
    updateNutritionalAssessmentForm,
    getNutritionalAssessmentHistories,
    getNutritionalAssessmentHistory,

    // ====================== Patient Education Form ======================
    getPatientEducationForm,
    createPatientEducationForm,
    updatePatientEducationForm,
    getPatientEducationHistories,
    getPatientEducationHistory,

    // =================== Medical and Physical Form =============
    getMedicalAndPhysicalDetail,
    createMedicalAndPhysical,
    updateMedicalAndPhysical,

    // =========== Continuous Flow Sheet ========
    createContinuousFlowSheet,
    updateContinuousFlowSheet,
    getContinuousFlowSheetDetail,

    // ====== Hepatitis Screening Results ======
    createHepatitisScreeningResults,
    updateHepatitisScreeningResults,
    getHepatitisScreeningResultsDetail,
    getHepatitisScreeningResultsHistory,
    getHepatitisScreeningResultsHistories,

    // ============= Patients ==========
    getPatientOptions,
    addPatients,
    getPatient,
    updatePatient,

    // ============= Patients Record ==========
    getTreatmentRecord,
    createTreatmentRecord,

    // ====================== Profile ======================
    // updateMyProfile,
    updateUserAvatar,
    updateMyProfile,
    confirmPassword,

    // ====================== System Accounts ======================
    searchUserAccounts,
    searchUserAccountsAxios,
    searchUserAccountsByOrderAxios,

    // ====================== System Accounts ======================
    getMyPermissions,

    // ====================== System Accounts ======================
    getPresignedDownloadFileUrl,
    getPresignedUploadFile,

    // ====================== Users Management ======================
    getAllUsers,
    getUser,
    createUser,
    deleteUser,
    searchUsers,
    searchProjects,

    // ========== ClinicalAbstract ==========
    createClinicalAbstract,
    updateClinicalAbstract,
    getClinicalAbstract,
    getAllClinicalAbstractHistory,
    getClinicalAbstractHistory,

    // ====================== Physician Order Form ======================
    getPhysicianOrder,
    createPhysicianOrder,
    editPhysicianOrder,
    getPhysicianOrderHistories,
    getPhysicianOrderHistory,

    // ====================== Standing Order Form ======================
    getStandingOrder,
    createStandingOrder,
    editStandingOrder,
    getStandingOrderHistories,
    getStandingOrderHistory,

    // ====================== Monthly Laboratory Result ======================
    getMonthlyLaboratoryResult,
    createMonthlyLaboratoryResult,
    editMonthlyLaboratoryResult,
    getMonthlyLaboratoryResultHistories,
    getMonthlyLaboratoryResultHistory,

    // ====================== Problem List ======================
    getProblemList,
    createProblemList,
    editProblemList,
    getProblemListHistories,
    getProblemListHistory,

    // ====================== CXR Result ======================
    getCXRResult,
    createCXRResult,
    editCXRResult,
    getCXRResultHistories,
    getCXRResultHistory,

    // ====================== Physician Order Form ======================
    getHemodialysisTreatment,
    createHemodialysisTreatment,
    editHemodialysisTreatment,
    getHemodialysisTreatmentHistories,
    getHemodialysisTreatmentHistory,

    // ====================== General Information Form======================
    getGeneralInformationForm,
    createGeneralInformationForm,
    updateGeneralInformationForm,

    // ====================== Medication Record Form ======================
    getMedicationRecord,
    createMedicationRecord,
    editMedicationRecord,
    getMedicationRecordHistories,
    getMedicationRecordHistory,

    // ====================== Advance Directives Form ======================
    getAdvanceDirectivesForm,
    createAdvanceDirectivesForm,
    updateAdvanceDirectivesForm,
    getAdvanceDirectivesHistoryDetail,
    getAdvanceDirectivesHistories,

    // ====================== Treatment Sheet ======================
    getTreatmentSheet,
    createTreatmentSheet,
    editTreatmentSheet,
    getTreatmentSheetHistory,
    getTreatmentSheetHistories,

    // ============= Vaccination Record ===========
    createVaccinationRecord,
    updateVaccinationRecord,
    getVaccinationRecordDetail,
    getVaccinationRecordHistory,
    getVaccinationRecordHistories,

    // ============= Nurses ===========
    getNurses,
    createNurse,
    editNurse,
    getNurseById,

    // ============= Appointments ===========
    getAllAppointmentListing,
    createAppointmentForm,
    getAppointmentFormDetail,
    getAppointmentContent,
    updateAppointments,
    getWaitLists,
    updateAppointmentConfirmable,
    getAvailableDates,

    // ============= Attending Nephrologist Form ===========
    getAllAttendingNephrologists,
    getAttendingNephrologist,
    createAttendingNephrologist,
    updateAttendingNephrologist,
    // ============== Patient Schedule =================
    createTreatmentSchedules,
    getTreatmentSchedules,
    getTreatmentScheduleDetail,
    updateTreatmentSchedulesSequences,
    updateTreatmentSchedules,
    deleteThisAppointment,
    deleteTreatmentScheduleSequences,

    // ============= Notification ================
    getNotifications,

    // ============= Treatment History ================
    getProducts,
    getPackages,
    getPackageItems,

    //=============== Requisition ==============
    getRequisition,
    postRequisitionForm,
    getActivityLog,
    getRequisitionExport,

    // ===============  Treatment Record ==============
    // =============== Charge Slip =============
    getTreatmentRecordChargeSlip,
    updateTreatmentsChargeSlip,
    getChargeSlipExport,
    // ============ Treatment Record =========
    getTreatmentsPatientCharts,
    addTreatmentsPatientCharts,

    // =============== Official Receipt ==============
    getOfficialReceipt,
    postOfficialReceipt,
    //============ Refunds =========
    getRefundRecordsTabDetail,
    getRefundRecordsListing,
    postNewRefundRequest,
    // Request Form
    getRequestFormDetail,
    editRequestForm,
    createRequestForm,

    getIncidentReport,
    postIncidentReport,
    updateIncidentReport,

    getRefundExport,
    getIncidentExport,

    // ================ Statement of Treatment =================
    getTreatmentStatementOfAccount,
    updateTreatmentStatementOfAccount,
    getTreatmentStatementOfAccountExport,
    // ================ Guarantee Letter ==================
    createGuaranteeLetter,
    getGuaranteeLetter,

    // =============== Approval ===========
    createApprovalForm,
  };
};

export type Apis = ReturnType<typeof create>;

export default {
  create,
};
