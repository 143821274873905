import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toCXRResult } from './helpers';
import { CXRResult } from './types';

export function useGetCXRResultHistory(
  options?: UseQueryOptions<ApiResponseType<{ cxrResultHistory: CXRResult }>, Error, CXRResult> & {
    id: CXRResult['uuid'];
  }
) {
  const {
    data: cxrResultHistory,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetCXRResultHistory,
  } = useQuery<ApiResponseType<{ cxrResultHistory: CXRResult }>, Error, CXRResult>(
    [API_QUERIES.CXR_RESULT_HISTORY, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ cxrResultHistory: CXRResult }>>(
          apiClient.getCXRResultHistory,
          params
        );
      },
      select: ({ data }) => toCXRResult(data?.cxrResultHistory),
      enabled: !!options.id,
      ...options,
    }
  );

  return {
    cxrResultHistory,
    error,
    isError,
    isLoading,
    onGetCXRResultHistory,
  };
}
