export enum NutritionalAssessmentFormKey {
  Id = 'id',
  UUID = 'uuid',
  PatientChartCode = 'patientChartCode',
  PatientChartId = 'patientChartId',
  Date = 'createdFormDate',
  Name = 'patientName',
  PatientChart = 'patientChart',
  Diagnosis = 'diagnosis',
  Height = 'height',
  ActualWeight = 'actualWeight',
  BodyMassIndex = 'bodyMassIndex',
  IdealBodyWeight = 'idealBodyWeight',
  CoMorbidIllnesses = 'coMorbidIllnesses',
  Diabetic = 'isDiabetic',
  Assessment = 'assessement',
  DietPrescription = 'dietPrescription',
  ProgressNotesDate = 'inputDetailDate',
  ProgressNotesWeight = 'weight',
  ProgressNotesAssessment = 'note',
  UploadDocument = 'documentUrls',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  PatientCode = 'patientCode',
}
