import { StatusType } from '../PatientForms';
import { AttendingNephrologistKey } from './keys';

export interface AttendingNephrologistType {
  [AttendingNephrologistKey.Id]?: string;
  [AttendingNephrologistKey.UUID]?: string;
  [AttendingNephrologistKey.NephrologistNumber]?: string;
  [AttendingNephrologistKey.Name]?: string;
  [AttendingNephrologistKey.DateOfBirth]?: Date;
  [AttendingNephrologistKey.Email]?: string;
  [AttendingNephrologistKey.PRCID]?: string;
  [AttendingNephrologistKey.PhoneNumber]?: string;

  [AttendingNephrologistKey.Prefix]?: string;
  [AttendingNephrologistKey.FirstName]?: string;
  [AttendingNephrologistKey.MiddleName]?: string;
  [AttendingNephrologistKey.LastName]?: string;
  [AttendingNephrologistKey.Suffix]?: string;
  [AttendingNephrologistKey.EmergencyContactPhone]?: number;
  [AttendingNephrologistKey.Address]?: number;
  [AttendingNephrologistKey.Status]?: StatusType;
  [AttendingNephrologistKey.Sex]?: string;
  [AttendingNephrologistKey.DoctorCode]?: string;
}

export interface AttendingNephrologistTypeResponse extends AttendingNephrologistType {
  doctor: AttendingNephrologistType;
}

export enum StatusFilterType {
  All = 0,
  Active = 1,
  Inactive = 2,
}

export const statusFilterOptions = [
  {
    label: 'All',
    value: StatusFilterType.All,
  },
  {
    label: 'Active',
    value: StatusFilterType.Active,
  },
  {
    label: 'Inactive',
    value: StatusFilterType.Inactive,
  },
];
