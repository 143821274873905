import { Backdrop } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Image, LoadingCommon } from 'src/components/common';
import PDFView from 'src/components/common/PDFView';
import { useComponentWillUnmount, useDownloadFile } from 'src/hooks';
import { useGetPresignedDownloadUrl } from 'src/queries/File/useGetPresignedDownloadUrl';
import { showLightBox } from 'src/redux/lightbox/lightboxSlice';
import { IRootState } from 'src/redux/store';
import { ErrorService, Toastify } from 'src/services';
import { isURLImage } from 'src/utils';
import { isEmpty } from 'src/validations';

const LightboxContainer: React.FC<Props> = ({ file, onShowLightbox, printDocId }) => {
  const [openImage, setOpenImage] = useState<string>('');
  const [openPdf, setOpenPdf] = useState<string>('');

  const download = useDownloadFile();
  const { name, url: fileUrl, isExternalLink } = file || {};

  const handleOpenUrl = useCallback(
    (url: string) => {
      const isPdf = url.includes('.pdf');
      if (isPdf) setOpenPdf(url);
      else if (isURLImage(url)) setOpenImage(url);
      else download(url, name);
    },
    [download, name]
  );

  const { isLoading, onGetDecodeUrl } = useGetPresignedDownloadUrl({
    onDecodeUrlChange(decodedUrl: string) {
      handleOpenUrl(decodedUrl);
    },
    onError(error) {
      ErrorService.handler(error);
    },
  });

  useEffect(() => {
    if (!isEmpty(file)) {
      if (fileUrl) {
        if (isExternalLink) {
          handleOpenUrl(fileUrl);
        } else onGetDecodeUrl({ filePath: fileUrl });
      } else Toastify.error('File Not Found!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useComponentWillUnmount(() => {
    closeLightbox();
  });

  const closeLightbox = () => {
    setOpenImage('');
    setOpenPdf('');
    onShowLightbox(null);
  };

  if (printDocId) {
    return (
      <Backdrop open sx={{ zIndex: 99999, backgroundColor: 'rgba(0, 0, 0, 0.88)' }}>
        <LoadingCommon size="normal" variant="white" />
      </Backdrop>
    );
  }
  if (isEmpty(file)) return null;

  return (
    <>
      <Backdrop open={isLoading} sx={{ zIndex: 99999 }}>
        <LoadingCommon size="normal" />
      </Backdrop>
      {openPdf && (
        <PDFView
          url={openPdf}
          title={name}
          isVisible={!isEmpty(openPdf)}
          onClose={closeLightbox}
          allowDownload={false}
        />
      )}
      <Backdrop
        open={!!openImage}
        sx={{ zIndex: 99999 }}
        onClick={closeLightbox}
        transitionDuration={500}
      >
        <Image
          src={openImage}
          alt={name}
          style={{ maxHeight: 'calc(100vh - 200px)', maxWidth: 'calc(100vw - 200px)' }}
        />
      </Backdrop>
    </>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => ({
  file: state.lightbox.file,
  printDocId: state.lightbox.printDocId,
});

const mapDispatchToProps = {
  onShowLightbox: showLightBox,
};

export default connect(mapStateToProps, mapDispatchToProps)(LightboxContainer);
