import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { InitialAppointmentResponse } from './types';

export function useGetAppointmentsInitialAppointment(
  options?: UseQueryOptions<
    ApiResponseType<InitialAppointmentResponse>,
    Error,
    InitialAppointmentResponse
  >
) {
  const {
    data,
    error,
    isFetching,
    refetch: onGetAllNurses,
  } = useQuery<ApiResponseType<InitialAppointmentResponse>, Error, InitialAppointmentResponse>(
    [API_QUERIES.APPOINTMENT_INIT],
    {
      queryFn: () => {
        return responseWrapper<ApiResponseType<InitialAppointmentResponse>>(
          apiClient.getAppointmentContent
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      select: getResponseData,
      keepPreviousData: true,
      enabled: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllNurses = () =>
    queryClient.invalidateQueries(API_QUERIES.APPOINTMENT_INIT);

  const { serviceCategories, timeSlots } = data || {};

  return {
    serviceCategories,
    timeSlots,
    error,
    isFetching,
    onGetAllNurses,
    handleInvalidateAllNurses,
  };
}
