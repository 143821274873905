import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toMonthlyLaboratoryResult } from './helpers';
import { MonthlyLaboratoryResult } from './types';

export function useGetMonthlyLaboratoryResult(
  options?: UseQueryOptions<
    ApiResponseType<{ monthlyLaboratoryResult: MonthlyLaboratoryResult }>,
    Error,
    MonthlyLaboratoryResult
  > & {
    id: MonthlyLaboratoryResult['uuid'];
  }
) {
  const {
    data: monthlyLaboratoryResult,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetMonthlyLaboratoryResult,
  } = useQuery<
    ApiResponseType<{ monthlyLaboratoryResult: MonthlyLaboratoryResult }>,
    Error,
    MonthlyLaboratoryResult
  >([API_QUERIES.MONTHLY_LABORATORY_RESULT, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ monthlyLaboratoryResult: MonthlyLaboratoryResult }>>(
        apiClient.getMonthlyLaboratoryResult,
        params
      );
    },
    select: ({ data }) => toMonthlyLaboratoryResult(data?.monthlyLaboratoryResult),
    enabled: !!options.id,
    ...options,
  });

  return {
    monthlyLaboratoryResult,
    error,
    isError,
    isLoading,
    onGetMonthlyLaboratoryResult,
  };
}
