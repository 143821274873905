import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../../helpers';
import { API_QUERIES } from '../../keys';
import { GeneralInformationFormTypeResponse } from './types';

export function useGetGeneralInformationForm(
  options?: UseQueryOptions<
    ApiResponseType<GeneralInformationFormTypeResponse>,
    Error,
    GeneralInformationFormTypeResponse
  > & {
    uuid: string;
  }
) {
  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetGeneralInformationFormById,
  } = useQuery<
    ApiResponseType<GeneralInformationFormTypeResponse>,
    Error,
    GeneralInformationFormTypeResponse
  >([API_QUERIES.GENERAL_INFORMATION_FORM, { uuid: options.uuid }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<GeneralInformationFormTypeResponse>>(
        apiClient.getGeneralInformationForm,
        params
      );
    },
    select: getResponseData,
    enabled: !!options.uuid,
    ...options,
  });

  const { PatientGeneralInformationForm } = data || {};

  const queryClient = useQueryClient();

  const handleInvalidateGeneralInformationForm = () =>
    queryClient.invalidateQueries([API_QUERIES.GENERAL_INFORMATION_FORM, { uuid: options.uuid }]);

  return {
    PatientGeneralInformationForm,
    error,
    isError,
    isLoading,
    onGetGeneralInformationFormById,
    handleInvalidateGeneralInformationForm,
  };
}
