export enum MedicationRecordKey {
  Id = 'uuid',
  PatientCode = 'patientCode',
  PatientChartCode = 'patientChartCode',
  AttendingNephrologist = 'attendingNephrologist',
  PatientName = 'patientName',
  PatientChartId = 'patientChartId',
  HemodialysisAccess = 'hemodialysisAccess',
  Diagnosis = 'diagnosis',
  Month = 'selectMonth',
  MedicationList = 'medicationList',
  Frequency = 'frequency',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  Date = 'date',
  Physician = 'physician',
  MedicalTeam = 'medicalTeam',
  DocumentUrls = 'documentUrls',
}

export enum MedicationItemKey {
  Name = 'name',
  Dosage = 'dosage',
  Frequency = 'frequency',
}

export enum HemodialysisAccess {
  PermanentCatheter = 'PermanentCatheter',
  TempCatheter = 'TempCatheter',
  PermanentAccessAVF = 'PermanentAccessAVF',
}
