import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { ContinuousFlowSheetFormValue, ContinuousFlowSheetResponse } from './types';

export function useCreateContinuousFlowSheet(
  options?: UseMutationOptions<
    ApiResponseType<ContinuousFlowSheetResponse>,
    Error,
    ContinuousFlowSheetFormValue
  >
) {
  const {
    mutate: createContinuousFlowSheet,
    data,
    isLoading,
    isSuccess,
  } = useMutation<
    ApiResponseType<ContinuousFlowSheetResponse>,
    Error,
    ContinuousFlowSheetFormValue
  >({
    mutationFn: (payload: ContinuousFlowSheetFormValue) =>
      responseWrapper(apiClient.createContinuousFlowSheet, [payload]),
    ...options,
  });

  return {
    createContinuousFlowSheet,
    data,
    isLoading,
    isSuccess,
  };
}
