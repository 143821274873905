import React, { ReactNode, ReactSVGElement, useEffect, useState } from 'react';
import cn from 'classnames';
import './styles.scss';
import { View, Icon, Text } from 'src/components/common';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { COLOR_CODE } from 'src/appConfig/constants';

const Accordion: React.FC<Props> = ({
  title,
  className,
  children,
  isExpanded = true,
  subTitle,
  onToggle,
  collapsible = true,
  ...props
}) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const handleToggle = () => {
    if (!collapsible) return;
    if (!!onToggle) {
      onToggle(!expanded);
    } else {
      setExpanded(!expanded);
    }
  };

  return (
    <View
      className={cn(
        'cmp-accordion-item',
        {
          'cmp-accordion-item--collapsed': expanded === false,
          'cmp-accordion-item--expanded': expanded === true,
        },
        className
      )}
    >
      <motion.header initial={false} onClick={handleToggle}>
        <View
          isRow
          justify="space-between"
          align="center"
          className={cn(
            'cmp-accordion-item__header',
            { 'cmp-accordion-item__header--expanded': expanded === true },
            { 'cmp-accordion-item__header--collapsed': expanded === false }
          )}
        >
          <View isRow align="center">
            <Icon className="mr-16">
              {expanded ? (
                <KeyboardArrowUp style={{ color: COLOR_CODE.PRIMARY_500_BASE }} />
              ) : (
                <KeyboardArrowDown style={{ color: COLOR_CODE.PRIMARY_500_BASE }} />
              )}
            </Icon>

            {typeof title === 'string' ? (
              <Text className="fw-bold" size={18}>
                {title}
              </Text>
            ) : (
              title
            )}
            {/* <Text size={14} className="ml-16">
              Click here to {expanded ? 'Collapse' : 'Expand'}
            </Text> */}
          </View>
          <View isRow justify="flex-end" align="center">
            {subTitle && <View className="mr-16">{subTitle}</View>}
          </View>
        </View>
      </motion.header>
      <AnimatePresence initial={false}>
        <motion.section
          key="content"
          initial={isExpanded ? 'open' : 'collapsed'}
          animate={expanded ? 'open' : 'collapsed'}
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { overflow: 'hidden', height: 0 },
          }}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          {...props}
        >
          <View className={cn('cmp-accordion-item__body', className)}>{children}</View>
        </motion.section>
      </AnimatePresence>
    </View>
  );
};

type Props = MotionProps & {
  title: string | ReactNode;
  children: ReactNode;
  subTitle?: string | ReactNode;
  className?: string;
  isExpanded?: boolean;
  customIcon?: ReactSVGElement;
  onToggle?: (value: boolean) => void;
  collapsible?: boolean;
};

export default Accordion;
