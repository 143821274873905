import { AppBar, Stack, Toolbar, Typography } from '@mui/material';
import { Location } from 'history';
import React, { useCallback } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import { COLOR_CODE } from 'src/appConfig/constants';
import { MenuType, SidebarMenu as menu } from 'src/appConfig/sidebar';
import { IRootState } from 'src/redux/store';
import NavAvatar from './NavAvatar';
import './styles.scss';

const Navbar: React.FC<Props> = ({ location }) => {
  const { showNavbar, showSidebar } = useSelector((state: IRootState) => state.common);
  const { toggleSidebar, collapsed } = useProSidebar();
  const sidebarWidth = toggleSidebar ? (showSidebar && collapsed ? 80 : 288) : 0;

  const title = (menu: MenuType[]) => {
    return menu.map((item) => {
      if (location.pathname.includes(item.href)) return item.subTitle ? item.subTitle : item.title;
      else if (item.subMenu) return title(item.subMenu);
      else return null;
    });
  };

  const markViewedNotifications = useCallback(() => {
    // onMarkViewedNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!showNavbar) return null;

  return (
    <AppBar
      variant="elevation"
      position="fixed"
      sx={{
        width: `calc(100% - ${sidebarWidth}px)`,
        backgroundColor: COLOR_CODE.BACKGROUND,
        boxShadow: 'none',
        minHeight: '95px',
        justifyContent: 'center',
        zIndex: '105',
        borderBottom: `2px solid ${COLOR_CODE.NAVBAR}`,
      }}
    >
      <Toolbar variant="regular" className="ctn-navbar__toolbar">
        <Stack width={'100%'} flexDirection={'row'} justifyContent={'space-between'}>
          <Stack flexDirection={'row'} alignItems="center">
            <Typography
              variant="h3"
              className="ctn-navbar__text"
              sx={{ marginLeft: 1, fontweight: 'bold' }}
            >
              {title(menu)}
            </Typography>
          </Stack>
          <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            {/* <NavSearch /> */}
            {/* <Badge badgeContent={1} color="error" style={{ marginRight: 16 }}>
              <Notification
                yPosition="bottom"
                xPosition="right"
                label={
                  <FaBell
                    size={22}
                    cursor="pointer"
                    color="#686868"
                    onClick={markViewedNotifications}
                  />
                }
              />
            </Badge> */}
            <NavAvatar pathname={location.pathname} />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

type Props = { location: Location };

export default Navbar;
