import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toHemodialysisTreatment } from './helpers';
import { HemodialysisTreatment } from './types';

export function useGetHemodialysisTreatment(
  options?: UseQueryOptions<
    ApiResponseType<{ informedHemodialysisTreatment: HemodialysisTreatment }>,
    Error,
    HemodialysisTreatment
  > & {
    id: HemodialysisTreatment['uuid'];
  }
) {
  const {
    data: hemodialysisTreatment,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetHemodialysisTreatment,
  } = useQuery<
    ApiResponseType<{ informedHemodialysisTreatment: HemodialysisTreatment }>,
    Error,
    HemodialysisTreatment
  >([API_QUERIES.HEMODIALYSIS_TREATMENT, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<
        ApiResponseType<{ informedHemodialysisTreatment: HemodialysisTreatment }>
      >(apiClient.getHemodialysisTreatment, params);
    },
    select: (data) => toHemodialysisTreatment(data.data.informedHemodialysisTreatment),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateHemodialysisTreatment = () =>
    queryClient.invalidateQueries([API_QUERIES.HEMODIALYSIS_TREATMENT, { id: options.id }]);

  return {
    hemodialysisTreatment,
    error,
    isError,
    isLoading,
    onGetHemodialysisTreatment,
    handleInvalidateHemodialysisTreatment,
  };
}
