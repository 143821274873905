import { Location } from 'history';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'; // Updated import
import { Callback } from 'src/redux/types';
import urljoin from 'url-join';
import useBlocker from './useBlocker';

export const BY_PASS_PARAM_KEY = 'byPass';

// TODO: need refactor use that later

function useCallbackPrompt(
  blockCondition: (_nextLocation: Location, _currentLocation: Location) => boolean, // Updated types
  blockCallback: Callback
): {
  confirmNavigation: Callback;
  cancelNavigation: Callback;
} {
  const history = useHistory();
  const location = useLocation(); // Use useLocation directly
  const [lastLocation, setLastLocation] = useState<Location>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [blocking, setBlocking] = useState(false);

  const cancelNavigation = useCallback(() => {
    setLastLocation(null);
    setBlocking(false);
  }, []);

  // handle blocking when the user clicks on another route, the prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation: Location) => {
      const { search } = nextLocation;
      const isByPass = search.includes(BY_PASS_PARAM_KEY);
      // in the if condition, we are checking if the next location and current location are equal or not
      setLastLocation(nextLocation);
      if (!blocking) {
        if (!isByPass && !confirmedNavigation && blockCondition(nextLocation, location)) {
          setBlocking(true);
          blockCallback(nextLocation);
          return false;
        }
        confirmNavigation();
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation, location, blocking]
  );

  const confirmNavigation = useCallback(() => {
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      const { search } = lastLocation;
      const query = new URLSearchParams(search);
      if (query.has(BY_PASS_PARAM_KEY)) {
        query.delete(BY_PASS_PARAM_KEY);
        history.push(urljoin(lastLocation.pathname, search));
      } else history.push(lastLocation);
      // Clean-up state on confirmed navigation
      setConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, true);

  return { confirmNavigation, cancelNavigation };
}

export default useCallbackPrompt;
