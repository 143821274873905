import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { StandingOrder } from './types';

export function useEditStandingOrder(
  options?: UseMutationOptions<StandingOrder, Error, StandingOrder>
) {
  const {
    mutate: editStandingOrder,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<StandingOrder, Error, StandingOrder>({
    mutationFn: (payload: StandingOrder) => responseWrapper(apiClient.editStandingOrder, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editStandingOrder,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
