import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { HepatitisScreeningResultHistoryRes } from './types';

export function useGetHepatitisScreeningResultsHistory(
  options?: UseQueryOptions<
    ApiResponseType<HepatitisScreeningResultHistoryRes>,
    Error,
    HepatitisScreeningResultHistoryRes
  > & {
    uuid: string;
  }
) {
  const {
    data: hepatitisScreeningResultsHistory,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetHepatitisScreeningResultsHistory,
  } = useQuery<
    ApiResponseType<HepatitisScreeningResultHistoryRes>,
    Error,
    HepatitisScreeningResultHistoryRes
  >([API_QUERIES.HEPATITIS_SCREENING_RESULTS, { uuid: options.uuid }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<HepatitisScreeningResultHistoryRes>>(
        apiClient.getHepatitisScreeningResultsHistory,
        params
      );
    },
    select: getResponseData,
    enabled: !!options.uuid,
    ...options,
  });

  return {
    hepatitisScreeningResultsHistory,
    error,
    isError,
    isLoading,
    onGetHepatitisScreeningResultsHistory,
  };
}
