import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { ApprovalFormValue, ApprovalFormValueResponse } from './types';

export function useCreateApprovalForm(
  options?: UseMutationOptions<ApiResponseType<ApprovalFormValueResponse>, Error, ApprovalFormValue>
) {
  const {
    mutate: createApprovalForm,
    data,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<ApprovalFormValueResponse>, Error, ApprovalFormValue>({
    mutationFn: (payload: ApprovalFormValue) =>
      responseWrapper(apiClient.createApprovalForm, [payload]),
    ...options,
  });

  return {
    createApprovalForm,
    data,
    isLoading,
    isSuccess,
  };
}
