import {
  Pagination,
  TableCell,
  TableFooter,
  TableRow,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import cn from 'classnames';
import React, { useMemo } from 'react';
import appConfig from 'src/appConfig';
import { COLOR_CODE } from 'src/appConfig/constants';
import { View } from 'src/components/common';
import Text from '../Text';
import SelectRowsPerPage from './SelectRowsPerPage';
import './styles.scss';

const clsPrefix = 'custom-footer-table';
const CustomFooterRender: React.FC<Props> = ({
  count,
  page,
  rowsPerPage,
  addRowButton,
  theme,
  recordName = 'items',
  changePage,
  changeRowsPerPage,
}) => {
  const isTabletScreen = useMediaQuery('(max-width:840px)');

  const range = useMemo(() => {
    const end = (page + 1) * rowsPerPage;
    const start = end - (rowsPerPage - 1);
    if (count < end) return `${start}-${count}`;
    else return `${start}-${end}`;
  }, [count, page, rowsPerPage]);

  const handleChangeRowsPerPage = React.useCallback(
    (value: number) => {
      changeRowsPerPage(value);
      changePage(0);
    },
    [changePage, changeRowsPerPage]
  );

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    const skip = value > 0 ? value - 1 : value;
    changePage(skip);
  };

  return (
    <ThemeProvider theme={theme}>
      <TableFooter>
        <TableRow>
          <TableCell>
            {addRowButton}
            <View
              isRowWrap={!isTabletScreen}
              align="center"
              justify="space-between"
              className={cn(`${clsPrefix}`, {
                'px-2': !isTabletScreen,
                'py-2': isTabletScreen,
              })}
            >
              <View
                isRowWrap={isTabletScreen}
                fullWidth
                {...(isTabletScreen && {
                  align: 'center',
                  justify: 'center',
                })}
              >
                {count ? (
                  <Text size={14} className="fw-medium text-color-grey-600">
                    Showing {range} of {count} {recordName}
                  </Text>
                ) : (
                  <div />
                )}
              </View>
              <View
                isRow
                align="center"
                fullWidth
                {...(!isTabletScreen && {
                  justify: 'flex-end',
                })}
              >
                <View
                  isRow
                  align="center"
                  {...(isTabletScreen && {
                    fullWidth: true,
                    justify: 'center',
                  })}
                >
                  <Text size={14} className="fw-medium text-color-grey-600">
                    Rows per page:
                  </Text>
                  <SelectRowsPerPage
                    options={appConfig.ROWS_PER_PAGE_OPTIONS}
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                  />
                </View>
                <View
                  {...(isTabletScreen && {
                    fullWidth: true,
                    justify: 'center',
                  })}
                  style={{ marginTop: '4px' }}
                >
                  <Pagination
                    count={count > 0 ? Math.ceil(count / rowsPerPage) : 1}
                    page={page + 1}
                    shape="rounded"
                    size="small"
                    variant="outlined"
                    onChange={handleChangePage}
                    classes={{
                      root: `${clsPrefix}-pagination`,
                    }}
                    sx={{
                      button: {
                        backgroundColor: COLOR_CODE.GREY_100,
                        border: 'none',
                        '&.Mui-selected': {
                          color: COLOR_CODE.WHITE,
                          backgroundColor: COLOR_CODE.PRIMARY,
                        },
                      },
                    }}
                  />
                </View>
              </View>
            </View>
          </TableCell>
        </TableRow>
      </TableFooter>
    </ThemeProvider>
  );
};

type Props = {
  count: number;
  page: number;
  rowsPerPage: number;
  addRowButton?: React.ReactElement;
  theme: any;
  recordName?: string;
  changeRowsPerPage: (page: string | number) => void;
  changePage: (newPage: number) => void;
};

export default CustomFooterRender;
