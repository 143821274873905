import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseNoBody } from '../helpers';
import { UploadFilePayload } from './types';

export function useUploadFileToS3(options?: UseMutationOptions<UploadFilePayload, Error, any>) {
  const {
    mutate: uploadFile,
    isSuccess,
    isLoading,
  } = useMutation<UploadFilePayload, Error, any>({
    mutationFn: (payload: UploadFilePayload) => {
      return responseNoBody<any>(apiClient.uploadFile, [payload]);
    },
    onError(error, variables, context) {
      ErrorService.handler(error);
    },
    ...options,
  });

  return {
    loading: isLoading,
    isSuccess,
    uploadFile,
  };
}
