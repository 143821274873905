import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { toData } from './helpers';
import { PatientChartDetail } from './types';

export function useGetPatientChartById(
  options?: UseQueryOptions<ApiResponseType<PatientChartDetail>, Error, PatientChartDetail> & {
    id: string;
  }
) {
  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetPatientChartDetail,
  } = useQuery<ApiResponseType<PatientChartDetail>, Error, PatientChartDetail>(
    [API_QUERIES.PATIENT_CHART_BY_ID, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<PatientChartDetail>>(
          apiClient.getPatientChartById,
          params
        );
      },
      select: (data) => toData(data?.data),
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidatePatientChartDetail = () =>
    queryClient.invalidateQueries(API_QUERIES.PATIENT_CHART_BY_ID);

  return {
    patientChartDetail: data?.patientChart,
    error,
    isError,
    isLoading,
    onGetPatientChartDetail,
    handleInvalidatePatientChartDetail,
  };
}
