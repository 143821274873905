import { Stack } from '@mui/material';
import { FC, lazy, useMemo } from 'react';
import { IoMdDownload } from 'react-icons/io';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PrintComponentButton from 'src/components/PrintComponentButton';
import { Button, Text, View } from 'src/components/common';
import { getNameFormValue } from 'src/containers/PatientChartManagement/PatientChartsContainer/constants';
import { PATIENT_CHART_FORM_NAME } from 'src/containers/shared';
import { useGetPatientChartById } from 'src/queries';
import { FormAction, setFormAction } from 'src/redux/form/formSlice';
import { IRootState } from 'src/redux/rootReducer';
import { Callback } from 'src/redux/types';
import { handleShowErrorMsg } from 'src/utils/handlerUtils';
import './styles.scss';

const MedicalHistoryExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/MedicalHistoryExportVersion'
    )
);

const CXRResultExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/CXRResultExportVersion'
    )
);

const PatientEducationExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/PatientEducationExportVersion'
    )
);

const NutritionalAssessmentExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/NutritionalAssessmentExportVersion'
    )
);

const ProblemListExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/ProblemListExportVersion'
    )
);

const ClinicalAbstractExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/ClinicalAbstractExportVersion'
    )
);

const MonthlyLaboratoryResultExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/MonthlyLaboratoryResultExport'
    )
);

const GeneralInformationExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/GeneralInformationExportVersion'
    )
);

const ReUseDialyzerExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/ReUseDialyzerExportVersion'
    )
);

const AdvanceDirectiveExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/AdvanceDirectiveExportVersion'
    )
);

const HemodialysisTreatmentExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/HemodialysisTreatmentExportVersion'
    )
);

const HepatitisScreeningResultsExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/HepatitisScreeningResultsExportVersion'
    )
);

const VaccinationRecordExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/VaccinationRecordExportVersion'
    )
);

const MedicationRecordExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/MedicationRecordExportVersion'
    )
);

const PhysicianOrderExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/PhysicalOrderExportVersion'
    )
);

const StandingOrderExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/StandingOrderVersionExport'
    )
);

const TreatmentSheetExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/TreatmentSheetsExportVersion'
    )
);

const ContinuousFlowSheetExportFile = lazy(
  () =>
    import(
      'src/containers/PatientChartManagement/PatientChartsContainer/PatientChartExport/ContinuousFlowSheetExportVersion'
    )
);

const HeaderPatientChartForm: FC<Props> = ({
  formCode,
  formikProps,
  onDiscardButton,
  onSaveButton,
  isHideExport,
  isSubmitting,
  onSetFormAction,
}) => {
  const { id, historyId } = useParams<{
    id: string;
    historyId: string;
  }>();
  const { values } = formikProps;

  const { patientChartDetail } = useGetPatientChartById({
    id: id,
    onError: (error) => {
      handleShowErrorMsg(error);
    },
  });

  const { patientChartCode } = patientChartDetail || {};

  const formRenderExportFile = useMemo(() => {
    const patientCodeId = patientChartCode;
    switch (formCode) {
      // PATIENT DATA FORM
      case PATIENT_CHART_FORM_NAME.GENERAL_INFORMATION:
        return <GeneralInformationExportFile data={values} id={patientCodeId} />;
      case PATIENT_CHART_FORM_NAME.MEDICAL_HISTORY_AND_PHYSICAL_EXAMINATION:
        return <MedicalHistoryExportFile data={values} id={patientCodeId} />;

      // INFORMED CONSENT
      case PATIENT_CHART_FORM_NAME.HEMODIALYSIS_TREATMENT:
        return <HemodialysisTreatmentExportFile data={values} id={patientCodeId} />;
      case PATIENT_CHART_FORM_NAME.RE_USE_OF_DIALYZERS:
        return <ReUseDialyzerExportFile data={values} id={patientCodeId} />;
      case PATIENT_CHART_FORM_NAME.ADVANCE_DIRECTIVES:
        return <AdvanceDirectiveExportFile data={values} id={patientCodeId} />;

      // DIALYSIS PRESCRIPTION
      case PATIENT_CHART_FORM_NAME.STANDING_ORDER:
        return <StandingOrderExportFile data={values} id={patientCodeId} />;
      case PATIENT_CHART_FORM_NAME.PHYSICIAN_ORDER:
        return <PhysicianOrderExportFile data={values} id={patientCodeId} />;
      case PATIENT_CHART_FORM_NAME.CLINICAL_ABSTRACT:
        return <ClinicalAbstractExportFile data={values} id={patientCodeId} />;

      // HEMODIALYSIS MONITORING
      case PATIENT_CHART_FORM_NAME.TREATMENT_SHEET:
        return <TreatmentSheetExportFile data={values} id={patientCodeId} />;
      case PATIENT_CHART_FORM_NAME.CONTINUOUS_FLOW_SHEET:
        return <ContinuousFlowSheetExportFile data={values} id={patientCodeId} />;

      // MEDICATION RECORD
      case PATIENT_CHART_FORM_NAME.MEDICATION_RECORD:
        return <MedicationRecordExportFile data={values} id={patientCodeId} />;

      // LABORATORY FLOW
      case PATIENT_CHART_FORM_NAME.MONTHLY_LABORATORY_RESULT:
        return <MonthlyLaboratoryResultExportFile data={values} id={patientCodeId} />;
      case PATIENT_CHART_FORM_NAME.CXR_RESULT:
        return <CXRResultExportFile data={values} id={patientCodeId} />;
      case PATIENT_CHART_FORM_NAME.HEPATITIS_SCREENING_RESULTS:
        return <HepatitisScreeningResultsExportFile data={values} id={patientCodeId} />;
      case PATIENT_CHART_FORM_NAME.VACCINATION_RECORD:
        return <VaccinationRecordExportFile data={values} id={patientCodeId} />;

      // PROGRESS NOTE
      case PATIENT_CHART_FORM_NAME.PROBLEM_LIST:
        return <ProblemListExportFile data={values} id={patientCodeId} />;
      case PATIENT_CHART_FORM_NAME.NUTRITIONAL_ASSESSMENT:
        return <NutritionalAssessmentExportFile data={values} id={patientCodeId} />;
      case PATIENT_CHART_FORM_NAME.PATIENT_EDUCATION:
        return <PatientEducationExportFile data={values} id={patientCodeId} />;
    }
  }, [formCode, patientChartCode, values]);

  const handleClickBtnSave = () => {
    onSetFormAction(FormAction.SAVE);
    onSaveButton();
  };

  return (
    <Stack
      sx={{ mt: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
    >
      <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text size={32} className="fw-bold">
          {getNameFormValue(formCode)}
        </Text>

        {!historyId && (
          <PrintComponentButton
            printButton={
              <Button className="export-btn px-24 ml-12">
                <View isRow align="center">
                  <IoMdDownload /> <Text>Export</Text>
                </View>
              </Button>
            }
            docName={`Form-${formCode}`}
            content={formRenderExportFile}
            // UNCOMMENT IN DEV MODE
            // isTestLocal
          />
        )}
      </Stack>

      {!historyId && (
        <View isRow align="center">
          <Button variant="outline" onClick={onDiscardButton}>
            Discard
          </Button>
          <Button onClick={handleClickBtnSave} style={{ marginLeft: 6 }} isLoading={isSubmitting}>
            Save
          </Button>
        </View>
      )}
    </Stack>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    formikProps: any;
    formCode: PATIENT_CHART_FORM_NAME;
    onDiscardButton: Callback;
    onSaveButton: Callback;
    isSubmitting?: boolean;
    isHideExport: boolean;
  };

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {
  onSetFormAction: setFormAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPatientChartForm);
